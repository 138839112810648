<template>
  <div class="doctor-rating">
    <el-form class="customize-search-form search-box" inline>
      <el-form-item>
        <el-input
          v-model="search.gradeTitle"
          v-debounce="[onSearch, 'input']"
          prefix-icon="el-icon-search"
          class="input"
          clearable
          placeholder="请输入分级名称"
          @clear="onSearch"
        />
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="search.productTagIds"
          clearable
          placeholder="所有产品"
          @change="onSearch"
        >
          <el-option
            v-for="item in productTagList"
            :key="item.productId"
            :label="item.productName"
            :value="item.productId"
          />
        </el-select>
      </el-form-item>
      <el-form-item class="last-box">
        <el-button
          v-if="$store.state.user.userInfo.isTopOrg"
          class="create"
          icon="el-icon-plus"
          type="primary"
          @click="onEditOrCheck()"
        >
          创建分级
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      v-loading="loading"
      class="customize-el-table"
      :data="tableData"
    >
      <el-table-column prop="gradeTitle" label="客户分级名称" />
      <el-table-column prop="gradeProductNames" align="center" label="分级产品">
        <template slot-scope="scoped">
          {{ Array.isArray(scoped.row.gradeProductNames) && scoped.row.gradeProductNames.join('，') }}
        </template>
      </el-table-column>
      <el-table-column prop="peopleCoverCount" align="center" label="覆盖人数" />
      <el-table-column
        prop="updateAt"
        width="140"
        align="center"
        label="更新时间"
      >
        <template slot-scope="scoped">
          {{ scoped.row.updateAt && $moment(scoped.row.updateAt).format('YYYY-MM-DD  HH:mm:ss') }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="createdAt"
        width="140"
        label="创建时间"
      >
        <template slot-scope="scoped">
          {{ scoped.row.createdAt && $moment(scoped.row.createdAt).format('YYYY-MM-DD  HH:mm:ss') }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="reviewDataCount"
        width="120"
        label="待更新审核数据"
      >
        <template slot-scope="scoped">
          <el-button
            type="text"
            @click="$router.push({
              name: 'GradingList',
              query: {
                clientGradeId: scoped.row.clientGradeId
              }
            })"
          >
            {{ scoped.row.reviewDataCount }}条
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
        prop="address"
        width="120"
        align="center"
        label="操作"
      >
        <template slot-scope="scoped">
          <el-button
            type="text"
            @click="$router.push({
              name: 'DoctorRatingEdit',
              query: {
                type: 'check',
                clientGradeId: scoped.row.clientGradeId
              }
            })"
          >
            查看
          </el-button>
          <!-- <el-button
            v-if="$store.state.user.userInfo.isTopOrg"
            type="text"
            style="color: #ff4921;"
            @click="onDelRaging(scoped.row)"
          >
            删除
          </el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="pagination.pageNo"
      :total="pagination.total"
      :page-sizes="pagination.sizes"
      :page-size="pagination.pageSize"
      :layout="pagination.layout"
      :hide-on-single-page="false"
      class="customize-el-pagination"
      background
      @size-change="onSizeChange"
      @current-change="onCurrentChange"
    />
  </div>
</template>

<script>
import paginationMixin from '@/mixins/pagination'
export default {
  name: 'DoctorRating',
  mixins: [paginationMixin],
  data() {
    return {
      search: {
        gradeTitle: '',
        productTagIds: []
      },
      tableData: [],
      productTagList: [],
      loading: false
    }
  },
  activated() {
    this.getProductTagList()
  },
  mounted() {
    this.getProductTagList()
    this.getDataList()

    this.$EventBus.$on('refresh-rating', this.onSearch)
  },
  beforeDestroy() {
    this.$EventBus.$off('refresh-rating', this.onSearch)
  },
  methods: {
    // 获取数据
    async getDataList() {
      try {
        const params = this.getParams(this.search)
        this.loading = true
        // TODO: 接口地址需要修改
        const {data: {data, totalCount}} = await this.$axios.get(this.$API.selectClientGradeList, { params })
        this.pagination.total = totalCount
        this.tableData = data
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    // 获取产品标签
    async getProductTagList() {
      try {
        const params = {
          tagGroupId: 'e887acc95f1ed1747f3158cd090cbdb6',
          clientGradeId: ''
        }
        const {data} = await this.$axios.get(this.$API.productTagList, {params})
        this.productTagList = data
      } catch (error) {
        console.log(error)
      }
    },
    // 删除
    async onDelRaging(row) {
      try {
        await this.$MessageDialog({
          message:
            '<div style="margin: 30px 0px;">请确认是否删除该分级信息，一经删除将不可恢复该分级数据。</div>',
          title: '操作提示',
          closeOnClickModal: true
        })
        // TODO:接口地址需要修改
        await this.$axios.get(this.$API.deleteClientGrade, {
          params: { clientGradeId: row.clientGradeId }
        })
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
        this.getDataList()
      } catch (error) {
        if (error.code) {
          this.$message.error(error.message)
        }
      }
    },
    onSearch() {
      this.pagination.pageNo = 1
      this.getDataList()
    },
    // 编辑查看分级
    async onEditOrCheck(id, type) {
      const query = {
        id: id,
        type: type
      }
      this.$router.push({
        name: 'RatingEditOrCheck',
        query
      })
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.doctor-rating {
  .search-box {
    .input {
      width: 160px;
      ::v-deep .el-input__inner {
        border-radius: 30px;
      }
    }
  }
}
</style>
