<template>
  <page-main class="customize-page-main">
    <el-tabs v-model="activeTab">
      <template v-for="item in tabList">
        <el-tab-pane
          :key="item.key"
          :label="item.name"
          :name="item.key"
          :disabled="item.disabled"
        />
      </template>
    </el-tabs>
    <DoctorChildList v-if="activeTab === 'doctorList'" />
    <DoctorChildHospital v-if="activeTab === 'hospitalList'" />
    <DoctorRating v-if="activeTab === 'doctorLevel'" />
    <DoctorChildSpeaker v-if="activeTab === 'speakerList'" />
    <DoctorSpeakerManage v-if="activeTab === 'speakerManage'" />
    <DoctorGradeQuestionnaireModal
      :visible="showLevelModal"
      :tag-name="selectedTagName"
      @onClose="onClose"
    />
  </page-main>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import DoctorChildList from './DoctorChildList.vue'
import DoctorChildHospital from './DoctorChildHospital.vue'
import DoctorChildSpeaker from './DoctorChildSpeaker.vue'
import DoctorGradeQuestionnaireModal from '@/bizComponents/DoctorGradeQuestionnaireModal'
import DoctorRating from './DoctorRating.vue'
import DoctorSpeakerManage from './DoctorSpeakerManage'
export default {
  name: 'Doctor',
  components: {
    DoctorChildList,
    DoctorChildHospital,
    DoctorChildSpeaker,
    DoctorSpeakerManage,
    DoctorRating,
    DoctorGradeQuestionnaireModal
  },
  data() {
    return {
      tagList: [],
      tagGroupId: 'e887acc95f1ed1747f3158cd090cbdb6', // 产品分类id
      activeTab: '', // message or download
      isOpenCol: false,
      selectedTagId: '',
      selectedTagName: '',
      showLevelModal: false, // 分级问卷弹窗
      isShowDoctorClassify: false
    }
  },
  computed: {
    ...mapGetters([
      'tagGroupList',
      'tagCoverLevelDoctor',
      'tagWillCoverLevelDoctor',
      'tagCoverDoctorChartData',
      'factoryCoverDoctorSummaryData',
      'isTopOrg'
    ]),
    productTagList() {
      const tagGroup =
        this.tagGroupList.filter(v => v.id === this.tagGroupId)[0] || {}
      return [...this.tagList].filter(v => v.groupId === tagGroup.id)
    },
    tabList() {
      return [
        { name: '客户管理', key: 'doctorList' },
        { name: '机构管理', key: 'hospitalList' },
        { name: '客户分级', key: 'doctorLevel' },
        { name: '播客管理', key: 'speakerManage' },
        { name: '签约管理', key: 'speakerList' }
      ].filter(item => item.key ? this.$auth(item.key) : true)
    }
  },
  watch: {
    productTagList(newList) {
      if (newList.length) {
        this.selectedTagId = newList[0].tagId
        this.selectedTagName = newList[0].tagName
        this.getTagCoverDoctorChartData(this.selectedTagId)
      }
    }
  },
  mounted() {
    this.activeTab = this.tabList[0]?.key
    this.getTagGroup()
    this.getTag()
    this.getDoctorSummary()
    if (this.productTagList.length) {
      this.selectedTagId = this.productTagList[0].tagId
      this.selectedTagName = this.productTagList[0].tagName
      this.getTagCoverDoctorChartData(this.selectedTagId)
    }
  },
  methods: {
    ...mapActions([
      'getTagCoverDoctorChartData',
      'getTagGroup',
      'getDoctorSummary'
    ]),
    async getTag() {
      const params = {
        tagGroupId: this.tagGroupId
      }
      const res = await this.$axios.get(this.$API.tagList, { params })
      this.tagList = res.data
    },
    onJumpLevelDoctor(state = 'already') {
      if (!this.selectedTagId) return this.$message.error('请先选择产品标签')
      this.$router.push(
        `/doctor/tag/${this.selectedTagId}?state=${state}&name=${this.selectedTagName}`
      )
    },
    onJumpSet() {
      this.$router.push('/doctor/certify/set')
    },
    onShowLevelModal() {
      this.showLevelModal = true
    },
    onClose() {
      this.showLevelModal = false
    },
    onOpenCol() {
      this.isOpenCol = !this.isOpenCol
    },
    onSelectTag(val, name) {
      this.selectedTagId = val
      this.selectedTagName = name
      this.getTagCoverDoctorChartData(this.selectedTagId)
    },
    onJumpPanGu() {
      this.$qxsDataPageJump(`/task/data?tagId=${this.selectedTagId}`)
      // window.location
    },
    onOpenDoctorClassify() {
      this.isShowDoctorClassify = !this.isShowDoctorClassify
    }
  }
}
</script>
<style lang="scss" scoped>
.font-title {
  font-size: 36px;
  padding-top: 3px;
  padding-bottom: 20px;
}
.row-t {
  height: 220px;
}
.doctor-title-logo {
  margin-right: 6px;
  height: 20px;
  flex-shrink: 0;
}
.tag-box {
  padding-top: 20px;
  padding-bottom: 20px;
}
.tag-over-box {
  overflow: hidden;
  max-height: 80px;
  transition: all 150ms;
}
.tag-over-box.active {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 200px;
}
.el-icon-caret-right {
  transition: all 150ms;
}
.el-icon-caret-right.active {
  transform: rotate(90deg);
}
.line-text {
  line-height: 30px;
}
.tag-margin {
  margin-bottom: 10px;
}
.card-margin {
  margin-bottom: 10px;
}
.doctor-count-box {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;
  min-width: 450px;
}
.lay-box {
  min-height: 500px;
}
.w-h {
  height: 300px;
}
.ic-image {
  height: 46px;
  width: 40px;
  margin-bottom: 15px;
  cursor: pointer;
}
.transform-rotate {
  transform: rotate(90deg);
  -ms-transform: rotate(90deg); /* IE 9 */
  -moz-transform: rotate(90deg); /* Firefox */
  -webkit-transform: rotate(90deg); /* Safari 和 Chrome */
  -o-transform: rotate(90deg); /* Opera */
}
.set-wrapper {
  position: absolute;
  top: 0;
  right: 0;
}
</style>
