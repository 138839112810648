<template>
  <div class="doctor-rating-edit">
    <page-main title back>
      <div class="custom-form">
        <div class="first-row row">
          <span class="label">分级名称：</span><span class="content">{{ baseGrade.gradeTitle }}</span>
        </div>
        <div class="row">
          <span class="label">分群维度：</span><span v-if="baseGrade.levelTypes" class="content">{{
            baseGrade.levelTypes.join("、")
          }}</span>
        </div>
        <div class="row">
          <span class="label">分级产品：</span><span v-if="baseGrade.gradeProductNames" class="content overfilow">{{
            baseGrade.gradeProductNames.join("、")
          }}</span>
        </div>
        <div class="last-row row">
          <div class="left">
            <div class="row">
              <span class="label">创建日期：</span><span class="content">{{
                $moment(baseGrade.createdAt).format("YYYY.MM.DD HH:mm:ss")
              }}</span>
            </div>
            <div class="row">
              <span class="label">近期更新时间：</span><span class="content">{{
                $moment(baseGrade.updatedAt).format("YYYY.MM.DD HH:mm:ss")
              }}</span>
            </div>
          </div>
          <div class="right">
            <el-button
              type="text"
              @click="
                $router.push({
                  name: 'RatingEditOrCheck',
                  query: {
                    type: 'check',
                    clientGradeId: search.clientGradeId,
                  },
                })
              "
            >
              查看规则
            </el-button>
            <template v-if="$store.state.user.userInfo.isTopOrg">
              <el-button
                type="text"
                @click="
                  $router.push({
                    name: 'RatingEditOrCheck',
                    query: {
                      type: 'edit',
                      clientGradeId: search.clientGradeId,
                    },
                  })
                "
              >
                编辑分级
              </el-button>
              <el-button type="text" @click="onUpdate">更新数据</el-button>
              <el-button
                type="text"
                style="color: #ff4921;"
                @click="onDelRaging"
              >
                删除分级
              </el-button>
            </template>
          </div>
        </div>
      </div>
      <div class="manage-tabs">
        <div class="tab-pane">分级更新时间</div>
        <div
          v-for="item in dateList"
          :key="item.date"
          :class="`tab-pane ${
            search.selectedDate === item.date ? 'is-active' : ''
          }`"
          @click.stop="
            () => {
              if (search.selectedDate == item.date) return;
              search.selectedDate = item.date;
              handleSelectedDateChange();
            }
          "
        >
          {{ item.title }}
          <span @click.stop="removeDate(item.date)">
            <svg-icon
              v-if="item.closable"
              class="del-icon"
              name="el-icon-close"
            />
          </span>
        </div>
        <div v-show="dateList.length < 4" class="tab-pane">
          添加时间对比 +
          <el-date-picker
            v-model="search.selectedDate"
            class="picker"
            type="date"
            :picker-options="pickerOptions"
            value-format="yyyy-MM-dd 23:59:59"
            placeholder="选择日期"
            @input="addDate"
          />
        </div>
      </div>
      <div class="analyze">
        <div class="left-info">
          <div class="row">
            <span class="title">总分级客户数</span>
            <p class="num">{{ statisticData.totalClientGradeNum || 0 }}</p>
          </div>
          <!-- <div class="row">
            <span class="title">更新分级客户数</span>
            <p class="num">4320</p>
          </div> -->
          <!-- <div class="row">
            <span class="title">新增分级客户数</span>
            <p class="num">4320</p>
          </div> -->
          <div class="row">
            <span class="title">待更新审核客户数</span>
            <el-link
              class="num"
              type="primary"
              @click="
                selectDate.includes('今天')
                  ? $router.push({
                    name: 'GradingList',
                    query: {
                      type: 'edit',
                      clientGradeId: search.clientGradeId,
                    },
                  })
                  : ''
              "
            >
              {{ statisticData.waitUpdateClientGradeNum || 0 }}
            </el-link>
          </div>
        </div>
        <div class="right-chart">
          <DoctorGradeChart
            :show-compared="!selectDate.includes('今天')"
            :options="gradeChartOption"
            :chart-data="statisticData.chartItemList"
            @change="dealChartHighLight"
          />
          <div>
            <el-button
              v-if="baseGrade.levelTypes && baseGrade.levelTypes.length > 2"
              type="text"
              @click="dialogVisible = true"
            >
              切换坐标
            </el-button>
          </div>
        </div>
      </div>
      <div>
        <div class="header">
          <p class="title">客户分级（{{ selectDate }}）</p>
          <div>
            <el-input
              v-model="search.doctorName"
              v-debounce="[onSearch, 'input']"
              prefix-icon="el-icon-search"
              class="input"
              clearable
              placeholder="搜索医生名称"
              @clear="onSearch"
            />
            <el-button
              type="primary"
              plain
              @click="downloadData"
            >
              下载数据
            </el-button>
          </div>
        </div>
        <el-table v-loading="loading" :data="tableData" style="width: 100%;">
          <el-table-column prop="doctorId" label="医生ID" />
          <el-table-column prop="doctorName" align="center" label="姓名" />
          <el-table-column prop="doctorTitle" align="center" label="职称" />
          <el-table-column
            prop="doctorDepartment"
            width="140"
            align="center"
            label="科室"
          />
          <el-table-column
            align="center"
            prop="doctorHospitalName"
            width="140"
            show-overflow-tooltip
            label="机构名称"
          />
          <el-table-column
            align="center"
            prop="provinceNameAndCityName"
            width="120"
            label="省市"
          />
          <el-table-column
            align="center"
            prop="doctorHospitalGradeName"
            label="机构等级"
          />
          <el-table-column
            align="center"
            prop="potentialScore"
            label="潜力值"
          />
          <el-table-column align="center" prop="supportScore" label="支持度" />
          <el-table-column prop="ideaScore" label="观念值" />
          <el-table-column
            align="center"
            width="150px"
            prop="clientGradeResult"
            label="分级结果"
          />
          <el-table-column
            align="center"
            width="100px"
            prop="representName"
            label="分级人"
          />
          <el-table-column
            prop="address"
            width="100"
            align="center"
            label="操作"
          >
            <template slot-scope="scoped">
              <template>
                <el-button
                  type="text"
                  :disabled="!(dateList[0].date === search.selectedDate)"
                  @click="onEdit(scoped.row)"
                >
                  编辑
                </el-button>
              </template>
            </template>
          </el-table-column>
        </el-table>
        <div class="desc">
          备注：您可以点击这里
          <el-link type="primary" @click="downloadTemplate">下载模板</el-link>
          ，填写完成后，点击
          <file-upload
            class="file-upload"
            :size="20"
            :max="1"
            :action="$API.importFromGradeExcel"
            :show-file-list="false"
            :ext="['xlsx', 'xls']"
            :auto-upload="false"
            :http-request="onUpload"
          >
            <el-link
              v-loading="uploadLoading"
              type="primary"
              @click="isShowPop = true"
            >
              批量上传
            </el-link>
          </file-upload>
          分级信息
        </div>

        <el-pagination
          :current-page="pagination.pageNo"
          :total="pagination.total"
          :page-sizes="pagination.sizes"
          :page-size="pagination.pageSize"
          :layout="pagination.layout"
          :hide-on-single-page="false"
          class="pagination"
          background
          @size-change="onSizeChange"
          @current-change="onCurrentChange"
        />
      </div>
    </page-main>
    <el-dialog
      title="切换坐标"
      :visible.sync="dialogVisible"
      width="30%"
      class="customize-el-dialog"
      @open="clientGradeLevelYType = search.clientGradeLevelYType"
    >
      <div class="dialog-content">
        <el-form label-suffix="：" inline>
          <el-form-item label="横坐标">
            <el-select :value="1" disabled>
              <el-option
                v-for="item in levelList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="纵坐标">
            <el-select v-model="clientGradeLevelYType">
              <template v-for="item in levelList">
                <el-option
                  v-if="item.value !== 1"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </template>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="() => {
            search.clientGradeLevelYType = clientGradeLevelYType
            handleSelectedDateChange()
            dialogVisible = false
          }"
        >确 定</el-button>
      </span>
    </el-dialog>

    <!-- 更新提示 -->
    <el-dialog
      class="customize-el-dialog upload-dialog"
      width="30%"
      :visible.sync="uploadDialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      title="提示"
    >
      <div class="content">
        上传信息{{
          uploadResult.successCount + uploadResult.failureCount
        }}条，导入成功{{ uploadResult.successCount }}条。<el-link
          style="color: #3d61e3;"
          @click="saveAs(uploadResult.errorExcelUrl)"
        >
          点击下载
        </el-link>导入失败分级信息表。
      </div>
      <span slot="footer">
        <el-button
          type="primary"
          @click="uploadDialogVisible = false"
        >我知道了</el-button>
      </span>
    </el-dialog>
    <EditRating v-model="showEditDialog" :edit-form="editForm" @on-success="getDataList" />
  </div>
</template>

<script>
import paginationMixin from '@/mixins/pagination'
import DoctorGradeChart from '@/bizComponents/DoctorGradeChart'
import EditRating from './components/EditRating'
import { saveAs } from 'file-saver'
export default {
  name: 'DoctorRatingEdit',
  components: {
    DoctorGradeChart,
    EditRating
  },
  mixins: [paginationMixin],
  data() {
    return {
      dateList: [],
      statisticData: {},
      search: {
        clientGradeId: '',
        doctorName: '',
        selectedDate: '',
        compareDate: '',
        potential: '',
        idea: '',
        support: '',
        clientGradeLevelYType: 2
      },
      loading: false,
      tableData: [],
      dialogVisible: false,
      levelList: [
        {
          value: 2,
          label: '支持度'
        },
        {
          value: 1,
          label: '潜力值'
        },
        {
          value: 3,
          label: '观念值'
        }
      ],
      baseGrade: {},
      pickerOptions: {
        disabledDate: time => {
          const now = Date.now()
          // 计算2年前的日期
          const twoYearsAgo = new Date()
          twoYearsAgo.setFullYear(twoYearsAgo.getFullYear() - 2)

          const createdAt = new Date(this.baseGrade.createdAt)
          // 减一天
          createdAt.setDate(createdAt.getDate() - 1)

          const beforeDisabledTime = (twoYearsAgo.getTime() - createdAt.getTime() > 0) ? twoYearsAgo.getTime() : createdAt.getTime()
          // 如果选择的时间在2年前或今天之后,都不可选
          if (time.getTime() < beforeDisabledTime || time.getTime() > now) {
            return true
          }

          return false
        }
      },
      uploadDialogVisible: false,
      uploadResult: {},
      uploadLoading: false,
      editForm: {},
      showEditDialog: false,
      clientGradeLevelYType: 2
    }
  },
  computed: {
    selectDate() {
      return (
        (this.dateList &&
          this.dateList.find(item => item.date === this.search.selectedDate)
            ?.title) ||
        ''
      )
    },
    gradeChartOption() {
      return {
        yAxis: {
          name: this.levelList.find(item => item.value === this.search.clientGradeLevelYType).label
        }
      }
    }
  },
  mounted() {
    this.dateList.push({
      title: `${this.$moment(new Date()).format('YYYY.MM.DD')}（今天）`,
      date: this.$moment(new Date()).format('YYYY-MM-DD 23:59:59'),
      closable: false
    })

    this.search.clientGradeId = this.$route.query.clientGradeId
    this.search.selectedDate = this.dateList[0].date
    this.search.compareDate = this.dateList[0].date

    this.handleSelectedDateChange()
    this.getBaseData()
  },
  methods: {
    saveAs,
    // 获取基本数据
    async getBaseData() {
      try {
        const res = await this.$axios.get(this.$API.getBaseClientGrade, {
          params: {
            clientGradeId: this.search.clientGradeId
          }
        })
        this.baseGrade = res.data
      } catch (error) {
        console.log('error: ', error)
      }
    },
    // 获取数据
    async getDataList() {
      try {
        const params = this.getParams(this.search)
        if (params.clientGradeLevelYType === 2) {
          delete params.idea
        } else {
          delete params.support
        }
        delete params.clientGradeLevelYType

        this.loading = true
        // TODO: 接口地址需要修改
        const {
          // eslint-disable-next-line no-unused-vars
          data: { data, totalCount }
        } = await this.$axios.post(
          this.$API.selectDoctorClientInfoList,
          params
        )
        this.pagination.total = totalCount
        this.tableData = data
      } catch (error) {
        console.log(error)
        this.tableData = []
      } finally {
        this.loading = false
      }
    },
    // 分级统计信息
    async getTagCoverDoctorChartData() {
      try {
        const params = { ...this.search }
        if (params.clientGradeLevelYType === 2) {
          delete params.idea
        } else {
          delete params.support
        }
        const res = await this.$axios.post(
          this.$API.clientGradeRecordStatistic,
          params
        )
        this.statisticData = res.data
      } catch (err) {
        console.log(err)
      }
    },
    // 下载模板
    async downloadTemplate() {
      try {
        const res = await this.$axios.get(this.$API.exportClientGradeTemplate, {
          params: {
            clientGradeId: this.search.clientGradeId
          }
        })
        this.$message.success(res.data)
      } catch (error) {
        this.$message.error(error.message)
      }
    },
    // 下载数据
    async downloadData() {
      try {
        const params = this.getParams(this.search)
        const res = await this.$axios.post(
          this.$API.clientGradeRecordDownLoad,
          params
        )
        this.$message.success(res.data)
      } catch (error) {
        this.$message.error(error.message)
      }
    },
    // 批量上传
    async onUpload(file) {
      try {
        this.uploadLoading = true
        const formData = new FormData()
        formData.append('file', file.file)
        formData.append('clientGradeId', this.search.clientGradeId)
        const res = await this.$axios.post(file.action, formData)
        this.uploadResult = res.data
        this.uploadDialogVisible = true
        this.getDataList()
        this.$EventBus.$emit('refresh-rating')
      } catch (error) {
        console.log('error: ', error)
      } finally {
        this.uploadLoading = false
      }
    },
    // 更新
    async onUpdate() {
      this.$router.push({
        name: 'GradingList',
        query: {
          clientGradeId: this.search.clientGradeId
        }
      })
    },
    // 删除
    async onDelRaging() {
      try {
        await this.$MessageDialog({
          message:
            '<div style="margin: 30px 0px;">请确认是否删除该分级信息，一经删除将不可恢复该分级数据。</div>',
          title: '操作提示',
          closeOnClickModal: true
        })
        // TODO:接口地址需要修改
        const res = await this.$axios.get(this.$API.deleteClientGrade, {
          params: { clientGradeId: this.search.clientGradeId }
        })
        if (res) {
          this.$EventBus.$emit('refresh-rating')
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          this.$router.back()
        }
      } catch (error) {
        if (error.code) {
          this.$message.error(error.message)
        }
      }
    },
    // 添加分级时间
    addDate(e) {
      this.dateList.push({
        title: this.$moment(e).format('YYYY.MM.DD'),
        date: e,
        closable: true
      })
      // 根据时间排序
      this.dateList.sort((a, b) => {
        return new Date(b.date).getTime() - new Date(a.date).getTime()
      })
      this.handleSelectedDateChange()
    },
    onEdit(row) {
      this.editForm = row
      this.showEditDialog = true
    },
    // 删除分级时间
    removeDate(date) {
      // 判断删除的是否是选中的
      const row = this.dateList.find(
        item => item.date === this.search.selectedDate
      )
      this.dateList = this.dateList.filter(item => item.date !== date)
      if (row || date === this.search.selectedDate) {
        this.search.selectedDate = this.dateList[0].date
        this.handleSelectedDateChange()
      }
    },
    // 处理图表高亮
    dealChartHighLight(row) {
      this.search.idea = row.idea
      this.search.potential = row.potential
      this.search.support = row.support
      this.handleSelectedDateChange()
    },
    handleSelectedDateChange() {
      this.getTagCoverDoctorChartData()
      this.onSearch()
    },
    onSearch() {
      this.pagination.pageNo = 1
      this.getDataList()
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.doctor-rating-edit {
  .custom-form {
    > div {
      padding: 5px 0;
      line-height: 14px;
      color: #5a5a5a;
    }
    .row {
      display: flex;
      align-items: center;
      &.first-row {
        color: #0f0f0f;
        font-weight: 600;
        line-height: 12px;
      }
      .overfilow {
        width: 140px;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      &.last-row {
        .left {
          display: flex;
          .row {
            margin-right: 50px;
          }
        }

        justify-content: space-between;
      }
    }
  }
  .manage-tabs {
    display: flex;
    border-bottom: 1px solid #eee;
    margin-bottom: 10px;
    .tab-pane {
      min-width: 110px;
      text-align: center;
      padding: 0 10px;
      height: 36px;
      line-height: 36px;
      border-top: 1px solid #eee;
      border-right: 1px solid #eee;
      background-color: #f6f7fb;
      user-select: none;
      &.is-active {
        position: relative;
        background-color: #fff;
        font-weight: 600;
        &::after {
          content: "";
          width: 100%;
          min-width: 50px;
          height: 3px;
          background-color: #3d61e3;
          position: absolute;
          bottom: -1px;
          left: 50%;
          transform: translate3d(-50%, 0, 0);
        }
      }
      &:not(.is-active, &:nth-first-child) {
        &:active {
          // 聚焦后的样式
          color: #5a5a5a;
          background-color: #ebecf0;
        }
      }
      &:first-child {
        background-color: transparent;
        font-weight: 600;
        color: #141414;
        border-top: none;
      }
      &:last-child {
        color: #787878;
        background-color: #f6f7fb;
        position: relative;
        ::v-deep .picker {
          width: 100%;
          left: 0;
          right: 0;
          height: 100%;
          opacity: 0;
          position: absolute;
          .el-input__inner {
            cursor: pointer;
            width: 100%;
            padding: 0;
          }
          .el-input__prefix,
          .el-input__suffix {
            display: none;
          }
        }
      }
      .del-icon {
        font-weight: 600;
        cursor: pointer;
        color: #b5b5b5;
        margin-left: 10px;
        &:hover {
          color: #787878;
          background-color: #f6f7fb;
          border-radius: 50%;
        }
        &:active {
          color: #5a5a5a;
          background-color: #ebecf0;
        }
      }
    }
  }
  .analyze {
    display: flex;
    justify-content: space-between;
    padding-bottom: 15px;
    position: relative;
    &::after {
      content: "";
      width: calc(100% + 20px);
      height: 1px;
      background-color: #eee;
      position: absolute;
      bottom: 0;
      left: -10px;
    }
    .left-info {
      flex: 2;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 100px 100px;
      .row {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #0f0f0f;
        .title {
          font-size: 12px;
          line-height: 12px;
        }
        .num {
          margin-top: 10px;
          font-size: 28px;
          font-weight: 600;
          // color: #0f0f0f;
          line-height: 28px;
        }
      }
    }
    .right-chart {
      flex: 6;
      display: flex;
    }
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
    .title {
      font-size: 12px;
      font-weight: 600;
      color: #141414;
    }
    .input {
      width: 160px;
      margin-right: 10px;
      ::v-deep .el-input__inner {
        border-radius: 30px;
      }
    }
  }
  .desc {
    padding: 10px;
    display: flex;
    align-items: center;
    vertical-align: bottom;
    ::v-deep .el-link {
      padding: 0;
      font-size: 12px;
    }
  }
}
.dialog-content {
  width: 260px;
  margin: 0 auto;
}
.upload-dialog {
  .content {
    padding: 30px;
    .el-link {
      vertical-align: bottom;
    }
  }
}
</style>
