<template>
  <el-dialog class="customize-el-dialog" title="风险校验" append-to-body v-bind="$attrs" width="40%" @closed="onCloseModel" :visible.sync="outerVisible">
    <div style="text-align: center">
      <UploadSheetToJson @onUpload="onUpload">
        <div class="upload-cover" :class="{ 'is-uploaded': isUploaded }">
          <div>
            <img :src="imageUrl" alt="" />
            <div>{{ isUploaded ? '上传成功' : '请上传Excel' }}</div>
          </div>
        </div>
      </UploadSheetToJson>
      <p class="label">
        <span>备注：上传需要比对核查的用户数据，根据用户手机号与平台数据做比对，请先点击</span>
        <el-button type="text" @click="saveAs('https://cdnfile.yaomaitong.net/excel/%E6%95%B0%E6%8D%AE%E6%A0%A1%E9%AA%8C%E6%A8%A1%E6%9D%BF.xlsx', '%E6%95%B0%E6%8D%AE%E6%A0%A1%E9%AA%8C%E6%A8%A1%E6%9D%BF.xlsx')">下载数据校验模板</el-button>
      </p>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="outerVisible = false">取 消</el-button>
      <el-button type="primary" :loading="loading" :disabled="!isUploaded" @click="onComparison">开始比对</el-button>
    </div>
  </el-dialog>
</template>

<script>
import UploadSheetToJson from '@/bizComponents/UploadSheetToJson'
import { doctorDataCheck } from '@/services/doctorService'
import { saveAs } from 'file-saver'
export default {
  name: 'DoctorDataCheckModel',
  components: { UploadSheetToJson },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isUploaded: false,
      loading: false,
    }
  },
  computed: {
    imageUrl() {
      return this.isUploaded ? require('@/assets/common/icon_success.png') : require('@/assets/common/file_icon.png')
    },
    outerVisible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  methods: {
    saveAs,
    // 医生风险校验
    onUpload(arr) {
      this.isUploaded = true
      this.uploadedDoctorData = arr
    },
    // 开始比对
    async onComparison() {
      try {
        this.loading = true
        const { message, data } = await doctorDataCheck(this.uploadedDoctorData)
        if (message) {
          this.loading = false
          this.$message.success(data)
          this.outerVisible = false
        }
      } catch (error) {
        this.loading = false
        this.$message.error(error.message)
      }
    },
    // 关闭外层校验弹窗
    onCloseModel() {
      this.isUploaded = false
    },
  },
}
</script>

<style scoped lang="scss">
// scss
.upload-cover {
  flex-shrink: 0;
  width: 200px;
  height: 120px;
  background: #e9ecf1;
  border: 1px dashed #f3f3f3;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  img {
    width: 50px;
    margin-bottom: 5px;
  }
  &.is-uploaded {
    background-color: rgb(231, 252, 230);
    border: 1px solid rgb(9, 187, 7);
    color: rgb(9, 187, 7);
  }
}
.label {
  margin-top: 10px;
  line-height: 20px;
}
.dialog-footer {
  text-align: center;
}
</style>
