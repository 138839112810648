<template>
  <el-dialog
    class="grading-questionnaire-popup"
    title="分级问卷"
    :visible.sync="showLevelModal"
    width="600px"
    @open="open"
  >
    <div class="header">
      <span>
        <span v-if="productIdList.length">
          请选择 <span class="product-name">{{ productIdList.map(item => item.productName).join('、') }}</span> 分级问卷
        </span>
      </span>
      <el-button
        type="text"
        @click="onCreateQuestion()"
      >
        新建分级问卷
      </el-button>
    </div>
    <el-table
      v-loading="loading"
      :data="tableData"
      height="400"
      style="width: 100%;"
    >
      <el-table-column prop="title" label="问卷名称" />
      <el-table-column align="center" width="120" label="操作">
        <template slot-scope="scoped">
          <el-button type="text" :disabled="!scoped.row.canEdit" @click="onCreateQuestion(scoped.row)">编辑</el-button>
          <el-button
            v-if="scoped.row.id === activeRow.id"
            style="color: #ff4921;"
            type="text"
            @click="onSelect({})"
          >
            取消选择
          </el-button>
          <el-button
            v-else
            type="text"
            @click="onSelect(scoped.row)"
          >
            选择
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="pagination.pageNo"
      :total="pagination.total"
      :page-sizes="pagination.sizes"
      :page-size="pagination.pageSize"
      :layout="pagination.layout"
      :hide-on-single-page="false"
      class="pagination"
      background
      @size-change="onSizeChange"
      @current-change="onCurrentChange"
    />
    <div slot="footer" class="footer">
      <el-button plain @click="showLevelModal = false">取消</el-button>
      <el-button type="primary" @click="onSubmit">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import paginationMixin from '@/mixins/pagination'
export default {
  name: 'GradingQuestionnairePopup',
  mixins: [paginationMixin],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    questionnaireId: {
      type: String,
      default: ''
    },
    productIdList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      search: {
        type: 3
      },
      tableData: [],
      loading: false,
      activeRow: {}
    }
  },
  computed: {
    showLevelModal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    open() {
      this.activeRow = {id: this.questionnaireId}
      this.getDataList()
    },
    // 获取数据
    async getDataList() {
      try {
        const params = this.getParams(this.search)
        this.loading = true
        // TODO: 接口地址需要修改
        const {data: {data, totalCount}} = await this.$axios.get(this.$API.selectQuestionnairesList, { params })
        this.pagination.total = totalCount
        this.tableData = data
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    onCreateQuestion(row) {
      this.showLevelModal = false
      setTimeout(() => {
        const params = {}
        if (row?.id) {
          params.path = `/academic/scene/questionnaire/edit/${row.id}`
          params.query = {
            projectType: 3
          }
        } else {
          params.name = 'CreateQuestionnaire'
          params.query = {
            projectType: 3
          }
        }
        const routeUrl  = this.$router.resolve(params)
        window.open(routeUrl.href, '_blank')
      }, 150)
    },
    onSelect(row) {
      this.activeRow = row
    },
    onSubmit() {
      this.$emit('submit', this.activeRow.id || '')
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.grading-questionnaire-popup {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    >span {
      font-size: 12px;
      color: #0f0f0f;
      line-height: 12px;
      >span {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
    .product-name {
      display: inline-block;
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #3d61e3;
    }
  }
  ::v-deep .el-dialog__header {
    .el-dialog__title {
      line-height: 10px;
    }
  }
  .footer {
    text-align: center;
    .el-button {
      min-width: 90px;
    }
  }
  .pagination {
    margin-top: 10px;
  }
}
</style>
