<!--
 * @Author: your name
 * @Date: 2020-11-05 18:40:15
 * @LastEditTime: 2021-08-23 20:45:46
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory-vue/src/components/ImageUpload.vue
-->
<template>
  <div>
    <el-upload :action="uploadApi" :headers="headers" name="image" :before-upload="beforeImageUpload" :on-change="onChange" :file-list="fileList" :disabled="disabled" :on-error="onError" :on-success="onSuccess" :list-type="listType" :show-file-list="showFileList" accept="image/gif,image/jpeg,image/jpg,image/png,image/svg">
      <div slot="trigger" class="wrapper">
        <div><img src="../assets/common/comment_add_icon.png" alt="" class="image-w" /></div>
        <div>{{ desc }}</div>
      </div>
      <div slot="file" slot-scope="{ file }" v-loading="file.status === 'loading'" v-if="showFileList">
        <img class="el-upload-list__item-thumbnail" :src="file.normal" alt="" />
        <span class="el-upload-list__item-actions">
          <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
            <i class="el-icon-zoom-in"></i>
          </span>
          <span v-if="!disabled" class="el-upload-list__item-delete" @click="onRemove(file)">
            <i class="el-icon-delete"></i>
          </span>
        </span>
      </div>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible" append-to-body >
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import API from '@/apis/api-types'
import { getToken } from '@/utils/auth'
export default {
  name: 'ImageUpload',
  data() {
    return {
      fileSizeLimit: 5, //5M
      dialogVisible: false,
      dialogImageUrl: '',
      headers: {
        token: getToken(),
      },
      fileList: [],
    }
  },
  props: {
    desc: {
      type: String,
      default: '上传封面',
    },
    limit: {
      type: Number,
      default: 5,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    listType: {
      type: String,
      default: 'picture-card', //text/picture/picture-card
    },
    bucketType: {
      type: Number,
      default: 116,
    },
    showFileList: {
      type: Boolean,
      default: true,
    },
    imageList: {
      type: Array,
      default() {
        return []
      },
    },
  },
  computed: {
    uploadApi() {
      return `${API.uploadImage}?bucketType=${this.bucketType}`
    },
  },
  methods: {
    ...mapActions(['onUploadImage']),
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.normal
      this.dialogVisible = true
    },
    beforeImageUpload(file) {
      if(this.fileList.length >= this.limit) {
        this.$message.error(`上传图片数量不能超过 ${this.limit}张!`)
        return false
      }
      if (file.size > this.fileSizeLimit * 1024 * 1024) {
        this.$message.error(`上传图片大小不能超过 ${this.fileSizeLimit}MB!`)
        return false
      }
      return true
    },
    onChange(e) {
      // console.log(e)
    },
    onSuccess(res, file, fileList) {
      // this.fileList.splice(index,1)
      this.fileList = fileList
      console.log(res,file,fileList)
      if (res.code !== 0) {
        this.fileList = [...this.fileList].filter((v) => v.uid !== file.uid)
      }
      let output = [...this.fileList].map((v) => {
        if(v.response && v.response.data) {
          return {...v.response.data, uid:v.uid}
        }
        return {...v, uid:v.uid}
      })
      this.$emit('onSuccess', output)
    },
    onError(e, file, fileList) {
      this.$message.error(e.message)
      console.log('error')
    },
    onRemove(file) {
      this.fileList = [...this.fileList].filter((v) => v.uid !== file.uid)
      this.$emit('onSuccess', this.fileList)
    },
  },
  watch: {
    imageList(newVal) {
      console.log(newVal, 'newVal')
      if (newVal.length === 0) {
        this.fileList = []
      } else {
        this.fileList = newVal
      }
    },
  },
  mounted() {},
}
</script>
<style lang="scss" scoped>
.wrapper {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  line-height: 1;
  height: 100%;
  color: #3d61e3;
  font-size: 12px;
  position: relative;
}
.image-w {
  width: 36px;
  margin-bottom: 15px;
}
.upload-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
