<template>
  <div>
    <page-main class="card-margin pdg-tb20" title back>
      <span v-if="isSpeaker" class="fix-lan button color-red" @click="onExTool">解除播客身份</span>
      <div class="flex-between">
        <el-row type="flex" style="flex: 4;">
          <div class="align-center pdg-lr30 mgn-t20">
            <el-avatar :src="doctorInfo.avatar" :size="60" />
            <div class="pdg-t5">{{ doctorName }}</div>
            <div v-if="doctorInfo.isCancel" style="color: #ff4921;">(已注销)</div>
            <div v-if="doctorInfo.isFrozen" style="color: #fb9c35;">(已冻结)</div>
          </div>
          <div>
            <div class="font-weight mgn-b15">客户基础信息:</div>
            <div class="flex-between">
              <el-row class="item-space">
                <div>当前职称：{{ jobTitle || '/' }}</div>
                <div>所在科室：{{ department || '/' }}</div>
                <div class="text-ellipsis hint pointer" :title="hospital">所属医院：{{ hospital || '/' }}</div>
                <div>手机号码：{{ mobile || '/' }}</div>
              </el-row>
              <el-row class="mgn-l80 item-space">
                <div>医师认证：<img :src="certStatusFilter(certifyStatus)" alt=""></div>
                <div>实名认证：<img :src="realStatusFilter(realNameAuthStatus)" alt=""></div>
                <div>播客认证：<img :src="speakStatusFilter(speakerStatus)" alt=""></div>
                <div>手机号认证：<img :src="phoneStatusFilter(mobileValidResult)" alt=""></div>
              </el-row>
            </div>
          </div>
        </el-row>
        <el-divider direction="vertical" />
        <div style="flex: 1;" />
        <el-row style="flex: 4;">
          <div class="font-weight mgn-b15">服务代表记录:</div>
          <div v-if="bidRepList.length" class="rep-list item-space">
            <div v-for="v in bidRepList" :key="v.representId + v.factoryId">
              <el-row type="flex" class="level-between vertical-start item">
                <div>{{ v.factoryName }} - {{ v.representName }}</div>
                <div class="pdg-lr50">{{ v.bindTime }} - {{ v.unbindTime || '至今' }}</div>
                <div v-if="!v.unbindTime" class="text-primary-link button" @click="onJumpUnbind(v)">取消绑定</div>
                <div v-else />
              </el-row>
            </div>
          </div>
          <div v-else>暂无</div>
        </el-row>
      </div>
    </page-main>
    <page-main v-loading="loading" class="card-margin" :body-style="cardBodyStyle">
      <el-tabs v-model="activeTab">
        <el-tab-pane
          v-for="item in tabList"
          :key="item.key"
          :label="item.name"
          :name="item.key"
        />
      </el-tabs>
      <el-row
        v-if="activeTab === 'study'"
        type="flex"
        align="middle"
        class="fix-lan"
      />
      <div v-if="activeTab === 'study'">
        <el-row class="lay-box">
          <check-tag
            v-for="item in sceneTypeList"
            :key="item.key"
            :value="item.key"
            :text="item.name"
            :selected="selectedSceneType"
            @onSelect="onSelectScene"
          />
        </el-row>
        <div v-show="selectedSceneType === 'meeting'">
          <el-table stripe :data="doctorSceneData">
            <el-table-column prop="title" show-overflow-tooltip label="科会名称" />
            <el-table-column prop="viewTime" align="center" label="观看时长(min)">
              <template slot-scope="scope">
                <span>{{ parseFloat(((scope.row.viewTime || 0) / 60).toFixed(1)) }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="viewCount" align="center" label="观看次数" />
            <!-- <el-table-column align="center" label="答题详情">
              <template slot-scope="scope">
                <span class="text-primary-link" @click="onJumpMeetingExamDetail(scope.row.codeId)">查看详情</span>
              </template>
            </el-table-column> -->
            <el-table-column prop="representName" align="center" label="负责代表">
              <template slot-scope="scope">
                <span>{{ scope.row.representName || '-' }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="orgName" align="center" label="学术任务来源" />
          </el-table>
        </div>
        <div v-show="selectedSceneType === 'questionnaire'">
          <el-table stripe :data="doctorSceneData">
            <el-table-column prop="title" show-overflow-tooltip label="调研名称" />
            <!-- <el-table-column align="center" label="答题详情">
              <template slot-scope="scope">
                <span class="text-primary-link" @click="onJumpQuestionnaireExamDetail(scope.row.codeId)">查看详情</span>
              </template>
            </el-table-column> -->
            <el-table-column prop="representName" align="center" label="负责代表">
              <template slot-scope="scope">
                <span>{{ scope.row.representName || '-' }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="doctorGetTime" align="center" label="代表分配时间">
              <template slot-scope="scope">
                <span>{{ $moment(scope.row.doctorGetTime).format('YYYY-MM-DD') }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="orgName" align="center" label="学术任务来源" />
          </el-table>
        </div>
        <div v-show="selectedSceneType === 'live'">
          <el-table stripe :data="doctorSceneData">
            <el-table-column prop="title" show-overflow-tooltip label="直播名称" />
            <el-table-column prop="viewTime" align="center" label="观看时长(min)">
              <template slot-scope="scope">
                <span>{{ parseFloat(((scope.row.viewTime || 0) / 60).toFixed(1)) }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="viewCount" align="center" label="观看次数">
              <template slot-scope="scope">
                <span>{{ scope.row.viewCount || 0 }}</span>
              </template>
            </el-table-column>
            <!-- <el-table-column align="center" label="答题详情">
              <template slot-scope="scope">
                <span class="text-primary-link" @click="onJumpLiveExamDetail(scope.row.codeId, scope.row.sceneId)">查看详情</span>
              </template>
            </el-table-column> -->
            <el-table-column prop="representName" align="center" label="负责代表">
              <template slot-scope="scope">
                <span>{{ scope.row.representName || '-' }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="orgName" align="center" label="学术任务来源" />
          </el-table>
        </div>
        <div v-show="selectedSceneType === 'webinar'">
          <el-table stripe :data="doctorSceneData">
            <el-table-column prop="title" show-overflow-tooltip label="会议名称" />
            <el-table-column prop="viewTime" align="center" label="观看时长(min)">
              <template slot-scope="scope">
                <span>{{ parseFloat(((scope.row.viewTime || 0) / 60).toFixed(1)) }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="viewCount" align="center" label="观看次数">
              <template slot-scope="scope">
                <span>{{ scope.row.viewCount || 0 }}</span>
              </template>
            </el-table-column>
            <!-- <el-table-column align="center" label="答题详情">
              <template slot-scope="scope">
                <span class="text-primary-link" @click="onJumpWebinarExamDetail(scope.row.codeId, scope.row.sceneId)">查看详情</span>
              </template>
            </el-table-column> -->
            <el-table-column prop="representName" align="center" label="负责代表">
              <template slot-scope="scope">
                <span>{{ scope.row.representName || '-' }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="orgName" align="center" label="学术任务来源" />
          </el-table>
        </div>
        <el-row type="flex" justify="end" class="t-p">
          <el-pagination
            background
            hide-on-single-page
            layout="prev, pager, next"
            :page-size="pageSize"
            :current-page="pageNo"
            :total="totalCount"
            @current-change="onChangePage"
          />
        </el-row>
      </div>
      <div v-if="activeTab === 'knowledge'">
        <el-row class="lay-box">
          <check-tag
            v-for="item in knowledgeTypeList"
            :key="item.key"
            :value="item.key"
            :text="item.name"
            :selected="selectedKnowledgeType"
            @onSelect="onSelectKnowledge"
          />
        </el-row>
        <div v-show="selectedKnowledgeType === '5'">
          <el-table v-loading="load" stripe :data="doctorLiveVideoList">
            <el-table-column prop="liveTitle" show-overflow-tooltip label="直播课程" />
            <el-table-column prop="duration" align="center" label="直播时长">
              <template slot-scope="scope">
                <span>{{ scope.row.duration | handleTime }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="size" align="center" label="视频大小">
              <template slot-scope="scope">
                <span>{{ ((scope.row.size || 0) / 1024 / 1024).toFixed(1) }}M</span>
              </template>
            </el-table-column>
            <el-table-column prop="liveStartAt" align="center" label="直播时间">
              <template slot-scope="scope">
                <span>{{ scope.row.liveStartAt ? $moment(scope.row.liveStartAt).format('YYYY-MM-DD HH:mm:ss') : '-' }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" label="操作">
              <template slot-scope="scope">
                <span class="text-primary-link" @click="onSaveUrl(scope.row)">下载视频</span>
                <span class="text-primary-link margin-l" @click="onLook(scope.row)">观看视频</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div v-show="selectedKnowledgeType === '6'">
          <el-table v-loading="load" stripe :data="doctorLiveVideoList">
            <el-table-column prop="liveTitle" show-overflow-tooltip label="会议名称" />
            <el-table-column prop="duration" align="center" label="会议时长">
              <template slot-scope="scope">
                <span>{{ scope.row.duration | handleTime }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="liveStartAt" align="center" label="会议开始时间">
              <template slot-scope="scope">
                <span>{{ scope.row.liveStartAt ? $moment(scope.row.liveStartAt).format('YYYY-MM-DD HH:mm:ss') : '-' }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" label="操作">
              <template slot-scope="scope">
                <span class="text-primary-link" @click="onSaveUrl(scope.row)">下载视频</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-row type="flex" justify="end" class="t-p">
          <el-pagination
            background
            hide-on-single-page
            layout="prev, pager, next"
            :page-size="pageSize"
            :current-page="livePageNo"
            :total="liveTotalCount"
            @current-change="onChange"
          />
        </el-row>
      </div>
      <VideoPlayModal :visible="showVideoModal" :video-url="videoUrl" @onClose="onCloseToast" />
    </page-main>
    <UnbindDoctor
      :dialog-form-visible="showUnbind"
      :doctor-ids="doctorIds"
      :represent-id="representId"
      :factory-id="factoryId"
      :rep-count="1"
      @update="initBindRepList"
      @onClose="showUnbind = false"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import CheckTag from '@/baseComponents/CheckTag'
import VideoPlayModal from '@/bizComponents/VideoPlayModal'
import { saveAs } from 'file-saver'
import UnbindDoctor from '@/views/doctor/UnbindDoctor'

export default {
  name: 'DoctorStat',
  filters: {
    handleTime(text) {
      if (!text) return '—'
      let hour = Math.floor(text / 60 / 60)
      let min = Math.floor((text / 60) % 60)
      let sec = Math.floor(text % 60)
      hour = hour < 10 ? `0${  hour}` : hour
      min = min < 10 ? `0${  min}` : min
      sec = sec < 10 ? `0${  sec}` : sec
      return `${hour  }:${  min  }:${  sec}`
    }
  },
  components: {
    VideoPlayModal,
    CheckTag,
    UnbindDoctor
  },
  data() {
    return {
      sceneTypeList: [
        { name: '科会', key: 'meeting' },
        { name: '调研', key: 'questionnaire' },
        { name: '直播', key: 'live' },
        { name: '会议', key: 'webinar' }
      ],
      knowledgeTypeList: [
        { name: '直播课程', key: '5' },
        { name: '多人会议', key: '6' }
      ],
      selectedSceneType: 'meeting',
      selectedKnowledgeType: '5',
      tabList: [
        { name: '学习数据', key: 'study' },
        { name: '知识店铺', key: 'knowledge' }
      ],
      activeTab: 'study',
      loading: false,
      load: false,
      showDownToast: false,
      showVideoModal: false,
      videoUrl: '',
      pageNo: 1,
      totalCount: 0,
      livePageNo: 1,
      liveTotalCount: 0,
      doctorId: '',
      pageSize: 20,
      exportMsg: '',
      showMask: false,
      doctorInfo: {},
      doctorName: '',
      mobile: '',
      representName: '',
      certifyStatus: '',
      realNameAuthStatus: '',
      speakerStatus: '',
      mobileValidResult: '',
      certifyImage: '',
      department: '',
      jobTitle: '',
      hospital: '',
      cardBodyStyle: {
        paddingTop: '0',
        paddingLeft: '30px',
        paddingRight: '30px',
        paddingBottom: '40px',
        minHeight: '600px',
        position: 'relative'
      },
      initStartDate: '',
      startDate: '',
      isSpeaker: false,
      endDate: `${this.$moment().format('YYYY-MM-DD')} 23:59:59`,
      isExport: false,
      pickerOptions: {
        onPick: ({ maxDate, minDate }) => {
          this.startDate = `${this.$moment(minDate).format('YYYY-MM-DD')} 00:00:00`
          this.endDate = `${this.$moment(maxDate).format('YYYY-MM-DD')} 23:59:59`
        }
      },
      timer: null,
      bidRepList: [],
      showUnbind: false,
      doctorIds: [],
      representId: '',
      factoryId: ''
    }
  },
  computed: {
    ...mapGetters(['doctorSceneCodeSummary', 'doctorSceneData', 'doctorLiveVideoList'])
  },

  watch: {
    activeTab(newVal) {
      if (newVal === 'study') {
        this.init()
      } else {
        this.initLiveVideo()
      }
    }
  },
  mounted() {
    this.doctorId = this.$route.params.id
    this.initDoctor()
    this.initBindRepList()
    this.getDoctorSceneSummary(this.doctorId)
  },
  methods: {
    ...mapActions(['onExportDoctorSceneStat', 'getDoctorSceneStatList', 'getDoctorAuthCert', 'getDoctorSceneSummary', 'getDoctorLiveVideoList', 'unSignUpDoctor']),
    certStatusFilter(status) {
      switch (status) {
        case 'has_certify':
          return require('../../assets/doctor/certify_success.png')
        case 'reject_certify':
          return require('../../assets/doctor/certify_failed.png')
        case 'examining':
          return require('../../assets/doctor/certify_wait.png')
        default:
          return require('../../assets/doctor/un_status.png')
      }
    },
    realStatusFilter(status) {
      switch (status) {
        case 1:
          return require('../../assets/doctor/realname_success.png')
        case 2:
          return require('../../assets/doctor/realname_failed.png')
        case 3:
          return require('../../assets/doctor/realname_wait.png')
        default:
          return require('../../assets/doctor/un_status.png')
      }
    },
    speakStatusFilter(status) {
      switch (status) {
        case 1:
          return require('../../assets/doctor/speak_success.png')
        case 0:
          return require('../../assets/doctor/speak_wait.png')
        case 2:
          return require('../../assets/doctor/speak_failed.png')
        default:
          return require('../../assets/doctor/un_status.png')
      }
    },
    phoneStatusFilter(status) {
      switch (status) {
        case 1:
          return require('../../assets/doctor/phone_success.png')
        case 0:
          return require('../../assets/doctor/phone_failed.png')
        default:
          return require('../../assets/doctor/un_status.png')
      }
    },
    onSelectScene(t) {
      this.selectedSceneType = t
      this.pageNo = 1
      this.init()
    },
    onSelectKnowledge(t) {
      this.selectedKnowledgeType = t
      this.pageNo = 1
      this.timer && clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.initLiveVideo()
      }, 300)
    },
    onJumpDown() {
      this.$router.push('/download/center')
    },
    goBack() {
      this.$router.go(-1)
    },
    onDown(id) {
      if (!this.$moment(this.startDate).isValid()) return this.$message.error('请选择开始日期')
      if (!this.$moment(this.endDate).isValid()) return this.$message.error('请选择结束日期')
      if (this.openObj[id] === 'loading') return
      this.openObj[id] = 'loading'
      const req = {
        startAt: this.startDate,
        endAt: this.endDate,
        targetOrgId: id,
        flowType: this.flowType
      }
      this.onExportDoctorSceneStat(req).then(
        res => {
          this.openObj[id] = false
          this.$message.success('数据下载成功，请前往下载中心查看')
          this.exportMsg = res.data
        },
        rea => {
          this.openObj[id] = false
          this.$message.error(rea.message)
        }
      )
    },
    initDoctor() {
      this.getDoctorAuthCert(this.doctorId).then(
        res => {
          this.doctorInfo = res
          this.doctorName = res.doctorName
          this.mobile = res.mobile
          this.representName = res.representNames
          this.certifyStatus = res.certifyStatus
          this.realNameAuthStatus = res.realNameAuthStatus
          this.mobileValidResult = res.mobileValidResult
          this.speakerStatus = res.speakerStatus
          this.certifyImage = res.certifyImage || require('@/assets/common/doctor_auth_empty.png')
          this.department = res.department
          this.jobTitle = res.jobTitle
          this.hospital = res.hospital
          this.isSpeaker = res.speakerSignStatus === 1
          this.startDate = this.initStartDate = `${this.$moment(res.registerAt || '2018 08-10').format('YYYY-MM-DD')} 00:00:00`
          this.init()
        },
        rea => {
          this.$message.error(rea.message)
        }
      )
    },
    initBindRepList() {
      this.$axios.get(this.$API.getBindRepList, {params: {doctorId: this.doctorId}}).then(
        res => {
          console.log(res)
          this.bidRepList = res.data
        }, rea => {
          this.$message.error(rea.message)
        })
    },
    init() {
      // 组织管理版本 -- 去掉医生详情的时间筛选
      //   if (!this.$moment(this.startDate).isValid()) return this.$message.error('请选择开始日期')
      //   if (!this.$moment(this.endDate).isValid()) return this.$message.error('请选择结束日期')
      this.loading = true
      const req = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        // startDate: this.startDate,
        // endDate: this.endDate,
        doctorId: this.doctorId,
        sceneType: this.selectedSceneType
      }
      this.getDoctorSceneStatList(req).then(
        res => {
          this.loading = false
          this.totalCount = res.totalCount
        },
        rea => {
          this.loading = false
          this.$message.error(rea.message)
        }
      )
    },
    initLiveVideo() {
      this.load = true
      this.getDoctorLiveVideoList({
        pageNo: this.livePageNo,
        doctorId: this.doctorId,
        pageSize: this.pageSize,
        sceneType: this.selectedKnowledgeType
      }).then(
        res => {
          this.load = false
          this.liveTotalCount = res.count
        },
        rea => {
          this.$message.error(rea.message)
          this.load = false
        }
      )
    },
    onSelectOrg({ orgId }) {
      this.selectedOrgId = orgId
      this.init()
    },
    onChangeDate(val) {
      if (!val) {
        this.startDate = this.initStartDate
        this.endDate = `${this.$moment().format('YYYY-MM-DD')} 23:59:59`
      }
      this.init()
    },
    onChangePage(pageNo) {
      this.pageNo = pageNo
      this.init()
    },
    onChange(page) {
      this.livePageNo = page
      this.initLiveVideo()
    },
    onCloseToast() {
      this.exportMsg = ''
      this.showMask = false
      this.openObj = {}
      this.videoUrl = ''
      this.showVideoModal = false
    },
    onShowDownToast(openKey = '', status) {
      this.exportMsg = ''
      this.showMask = true
      this.openObj = { ...this.openObj, [openKey]: status }
    },
    onJumpQuestionnaireExamDetail(id) {
      this.$router.push(`/doctor/stat/questionnaire/${id}?doctorId=${this.doctorId}`)
    },
    onJumpLiveExamDetail(id, liveId) {
      this.$router.push(`/doctor/stat/live/${id}?doctorId=${this.doctorId}&liveId=${liveId}`)
    },
    onJumpWebinarExamDetail(id, liveId) {
      this.$router.push(`/doctor/stat/webinar/${id}?doctorId=${this.doctorId}&liveId=${liveId}`)
    },
    onJumpMeetingExamDetail(id) {
      this.$router.push(`/doctor/stat/meeting/${id}?doctorId=${this.doctorId}`)
    },
    onSaveUrl(v) {
      if (!v.downloadUrl) return this.$message.error('操作失败，暂无视频下载地址')
      saveAs(v.downloadUrl, `${v.liveTitle}`)
      this.$message.success(`操作成功，视频资源(${(v.size / 1024 / 1024).toFixed(1)}MB)下载中，请耐心等待`)
    },
    onLook(item) {
      if (!item.downloadUrl) return this.$message.error('暂未生成视频地址')
      this.showVideoModal = true
      this.videoUrl = item.downloadUrl
    },
    onExTool() {
      this.unSignUpDoctor(this.doctorId).then(
        () => {
          this.$message.success('解除播客身份成功')
          this.isSpeaker = false
        },
        rea => {
          this.$message.error(rea.message)
        }
      )
    },
    onJumpUnbind(v) {
      this.doctorIds = [this.doctorId]
      this.representId = v.representId
      this.factoryId = v.factoryId
      this.showUnbind = true
    }
  }
}
</script>
<style lang="scss" scoped>
.card-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  padding-top: 15px;
  padding-right: 20px;
}
.font-title {
  font-size: 36px;
  padding-top: 10px;
  padding-bottom: 15px;
}
.certify-image {
  width: 240px;
  height: 160px;
  background-color: #f6f7fb;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #c0c4cc;
}
.text-ellipsis {
  max-width: 250px;
}
.speaker-label {
  width: 72px;
  height: 20px;
  margin-top: 5px;
  margin-left: 15px;
}
.font-s {
  font-size: 20px;
}
.fix-lan {
  position: absolute;
  right: 30px;
  top: 15px;
  z-index: 800;
}
.margin-l {
  margin-left: 14px;
}
.nav-box {
  padding-top: 30px;
  padding-bottom: 30px;
}
.card-margin {
  margin-bottom: $g-main-wrap-margin;
}
.lay-box {
  padding-top: 12px;
  padding-bottom: 12px;
}
.table-header-row {
  padding-left: 20px;
}
.t-p {
  padding-top: 20px;
}
.certifyStatus {
  color: #3d61e3;
}
.el-divider {
  font-size: 47px;
}
.item-space {
  > div {
    color: #5a5a5a;
    margin-bottom: 10px;
    img {
      vertical-align: middle;
    }
  }
}
.rep-list {
  width: 600px;
  padding-right: 50px;
  max-height: 130px;
  overflow: scroll;
}
.item > div {
  flex: 1;
  &:nth-last-child(1) {
    width: 80px;
    flex: inherit;
  }
}
.button {
  text-decoration: underline;
  cursor: pointer;
}
</style>
