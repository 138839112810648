import { render, staticRenderFns } from "./DoctorWebinarExam.vue?vue&type=template&id=65d48d24&scoped=true"
import script from "./DoctorWebinarExam.vue?vue&type=script&lang=js"
export * from "./DoctorWebinarExam.vue?vue&type=script&lang=js"
import style0 from "./DoctorWebinarExam.vue?vue&type=style&index=0&id=65d48d24&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65d48d24",
  null
  
)

export default component.exports