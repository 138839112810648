<template>
  <div>
    <el-dialog
      :title="`${title}`"
      :visible.sync="dialogVisible"
      width="60%"
      :close-on-click-modal="false"
    >
      <div class="content-box">
        <div class="search-box">
          <el-input
            v-model="searchObj.doctorName"
            clearable
            prefix-icon="el-icon-search"
            placeholder="搜索客户"
            class="search-input mgr10"
          />
          <el-cascader
            v-model="professionTitle"
            class="mgr10"
            :options="newJobList"
            :props="jobListMap"
            collapse-tags
            collapse-tags-tooltip
            clearable
            placeholder="请选择职业/职称"
          />
          <el-select
            v-model="searchObj.representDoctorStatus"
            placeholder="请选择绑定状态"
            class="mgr10"
          >
            <el-option
              v-for="item in bindStatusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
          <el-button type="primary" plain @click="onSearch">确认搜索</el-button>
        </div>
        <div class="mgt10">
          <el-table
            v-loading="loading"
            :data="tableData"
            border
            stripe
            style="width: 100%;"
          >
            <el-table-column
              prop="name"
              label="姓名"
            >
              <template slot-scope="{row}">
                <el-radio v-model="selectDoctor" :label="row.id">
                  <span class="name-text">{{ row.name }}</span>
                </el-radio>
              </template>
            </el-table-column>
            <el-table-column
              prop="professionName"
              label="职业"
              align="center"
            />
            <el-table-column
              prop="titleName"
              label="职称"
              align="center"
            />
            <el-table-column
              prop="department"
              label="科室"
              align="center"
            />
            <el-table-column
              prop="hospital"
              label="机构"
              align="center"
              show-overflow-tooltip
            />
          </el-table>
          <el-pagination
            class="mgt10"
            :current-page.sync="searchObj.pageNo"
            :page-size="searchObj.pageSize"
            background
            layout="total, prev, pager, next, jumper"
            :total="totalCount"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
      <div slot="footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="onConfirm">下一步</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'DoctorSelect',
  components: {

  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    isReset: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: '新建播客'
    },
    jobList: {
      type: Array,
      default: () => []
    },
    jobListMap: {
      type: Object,
      default: () => {
        return {checkStrictly: true, value: 'id', children: 'childrenList'}
      }
    }
  },
  data() {
    return {
      loading: false,
      professionTitle: [],
      searchObj: {
        doctorName: '',
        titleId: '',
        professionType: '',
        pageNo: 1,
        pageSize: 10,
        querySpeaker: false,
        representDoctorStatus: 1
      },
      bindStatusList: [
        {label: '暂无绑定', value: 0},
        {label: '有绑定', value: 1},
        {label: '暂未满足绑定条件', value: 2}
        // {label: '黑名单', value: 3},
        // {label: '外部导入', value: 4}
      ],
      totalCount: 0,
      newJobList: [],
      newIdentList: [],
      selectDoctor: '',
      tableData: []
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.onResetSearchObj()
        this.onGetJobList()
      }
    }
  },
  methods: {
    async onGetJobList() {
      if (this.jobList.length > 0) {
        this.newJobList = this.jobList
      } else {
        try {
          const res = await this.$axios.get(this.$API.jobTitles)
          this.newJobList = res?.data || []
        } catch (error) {
          console.log('error: ', error)
        }
      }
    },
    onResetSearchObj() {
      if (this.isReset) {
        Object.assign(this.$data, this.$options.data())
      }
      this.onGetDoctorList()

    },
    onGetDoctorList() {
      this.loading = true
      const data = {
        ...this.searchObj,
        professionType: this.professionTitle[0],
        titleId: this.professionTitle[1]
      }
      this.$axios.post(this.$API.getDoctorListV3, data).then(
        res => {
          this.loading = false
          this.tableData = res?.data || []
          this.totalCount = res?.count || 0
        },
        ear => {
          this.loading = false
          this.tableData = []
          this.$message.error(ear.message)
        }
      )
    },
    onSearch() {
      this.searchObj.pageNo = 1
      this.onGetDoctorList()
    },
    handleCurrentChange() {
      this.onGetDoctorList()
    },
    onConfirm() {
      this.$emit('onConfirm', this.selectDoctor)
    }
  }
}
</script>

<style lang="scss" scoped>
.content-box {
  color: #0f0f0f;
}
.search-box {
  display: flex;
}
.search-input {
  width: 200px;
  ::v-deep .el-input__inner {
    border-radius: 30px;
  }
}
.name-text {
  color: #606266;
  font-weight: 400;
  font-size: 12px;
}
.mgr10 {
  margin-right: 10px;
}
.mgt10 {
  margin-top: 10px;
}
</style>
