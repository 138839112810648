<!--
 * @Author: your name
 * @Date: 2020-11-30 17:40:07
 * @LastEditTime: 2020-12-22 17:06:52
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory-vue/src/views/doctor/DoctorTagCoverList.vue
-->

<template>
  <page-main back title>
    <div style="position: relative">
      <el-tabs v-model="activeTab" @tab-click='onChangeTab'>
        <el-tab-pane v-for = 'item in tabList' v-bind:key='item.key' v-bind:label='item.name' v-bind:name='item.key'>
        </el-tab-pane>
      </el-tabs>
      <el-row type='flex' class="d-search-box" align="middle">
        <el-input placeholder="搜索客户名称" class="d-search-input" v-model="searchText">
          <i slot="suffix" class="el-icon-search el-input__icon" @click="onSearch"></i>
        </el-input>
      </el-row>
    </div>
    <div class="lay-box" v-loading='loading'>
      <el-table
        :data = 'tagCoverDoctorList'
        stripe
      >
      <el-table-column
        prop='doctorName'
        label='客户姓名'
        width="100"
        fixed
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="title"
        label="职称"
        show-overflow-tooltip
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="department"
        label="科室"
        show-overflow-tooltip
        align="center"
      >
        <template slot-scope="scope">
          <span>{{scope.row.department || '—'}}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop='wardMonth'
        align='center'
        label='病房月数'
      >
        <template slot-scope="scope">
          <span>{{scope.row.wardMonth | emptyTextFilter}}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="outpatientMonth"
        align="center"
        label="门诊月数"
      >
        <template slot-scope="scope">
          <span>{{scope.row.outpatientMonth | emptyTextFilter}}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="monthOutpatientCount"
        align="center"
        width="100"
        label="月门诊患者"
      >
        <template slot-scope="scope">
          <span>{{scope.row.monthOutpatientCount | emptyTextFilter}}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="monthOutpatientIndicationPatientCount"
        align="center"
        width="140"
        label="月门诊适应症患者"
      >
        <template slot-scope="scope">
          <span>{{scope.row.monthOutpatientIndicationPatientCount | emptyTextFilter}}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="monthOutpatientPrescriptionPatientCount"
        align="center"
        width="150"
        label="月门诊治疗方案患者"
      >
        <template slot-scope="scope">
          <span>{{scope.row.monthOutpatientPrescriptionPatientCount | emptyTextFilter}}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="bedCount"
        align="center"
        label="床位数"
      >
        <template slot-scope="scope">
          <span>{{scope.row.bedCount | emptyTextFilter}}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="bedTurnoverRate"
        align="center"
        width="100"
        label="床位周转率"
      >
        <template slot-scope="scope">
          <span>{{scope.row.bedTurnoverRate | emptyTextFilter}}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="monthWardIndicationPatientCount"
        align="center"
        width="140"
        label="月病房适应症患者"
      >
        <template slot-scope="scope">
          <span>{{scope.row.monthWardIndicationPatientCount | emptyTextFilter}}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="monthWardPrescriptionPatientCount"
        align="center"
        width="150"
        label="月病房治疗方案患者"
      >
        <template slot-scope="scope">
          <span>{{scope.row.monthWardPrescriptionPatientCount | emptyTextFilter}}</span>
        </template>
      </el-table-column>
      <el-table-column
        align='center'
        fixed="right"
        width="110"
        label='客户分级'
      >
        <template slot-scope="scope">
          <span>{{scope.row.doctorLevelType | doctorLevelTypeFilter}}</span>
        </template>
      </el-table-column>
      <el-table-column
        align='center'
        fixed="right"
        label='操作'
      >
        <template slot-scope="scope">
          <el-link type='primary' :underline='false' @click="onShowCertModal(scope.row)" v-if='scope.row.status === 0'>分级</el-link>
          <el-link type='primary' :underline='false' @click="onShowCertModal(scope.row)" v-else-if='scope.row.status === 1'>编辑</el-link>
        </template>
      </el-table-column>
      </el-table>
      <el-row type='flex' justify="end" class="t-p">
      <el-pagination
        background
        hide-on-single-page
        layout="prev, pager, next"
        :page-size='pageSize'
        :current-page="pageNo"
        @current-change='onChange'
        :total='totalCount'
      ></el-pagination>
      </el-row>
    </div>
    <DoctorLevelGradeModal :visible='showEditModal' @onClose='onClose' :item='selectedDoctorItem' />
    <DoctorOperationHistoryModal :visible='showHistoryModal' @onClose='onClose' :item='selectedDoctorItem' />
  </page-main>
</template>
<script>
import { mapActions, mapGetters} from 'vuex'
import { saveAs } from 'file-saver';
import DoctorLevelGradeModal from '@/bizComponents/DoctorLevelGradeModal'
import DoctorOperationHistoryModal from '@/bizComponents/DoctorOperationHistoryModal'
export default {
  name: 'DoctorChildList',
  data () {
    return {
      tabList: [{name:'已分级医生',key: 'already'},{name:'审核中医生',key: 'verify'}],
      selectedDoctorItem: "", // 选择的医生
      showHistoryModal:false,
      tagName: '',
      tagId:'',
      activeTab: '',
	    showEditModal: false,
	    searchText: '',
	    pageNo: 1,
	    pageSize: 20,
	    totalCount: 0,
	    loading: false,
    }
  },
  computed: {
    ...mapGetters(['tagCoverDoctorList']),
    doctorStatus () {
      switch (this.activeTab) {
        case 'all':
          return ''
        case  'verify':
          return 0
        case 'already':
          return 1
        case 'unLevel':
          return 2
        default :
          return ''
      }
    },
  },
  components: {
    DoctorLevelGradeModal,
    DoctorOperationHistoryModal
  },
  filters: {
    emptyTextFilter (val) {
      switch (val) {
        case null:
        case '':
          return '-'
        default:
            return val
      }
    },
    doctorLevelTypeFilter (status) {
      switch (status) {
        case 1:
          return '低支持高潜力'
        case 2:
          return '中支持高潜力'
        case 3:
          return '高支持高潜力'
        case 4:
          return '低支持中潜力'
        case 5:
          return '中支持中潜力'
        case 6:
          return '高支持中潜力'
        case 7:
          return '低支持低潜力'
        case 8:
          return '中支持低潜力'
        case 9:
          return '高支持低潜力'
        default:
          return '—'
      }
    }
  },
  methods: {
    ...mapActions(['getTagCoverDoctorList']),
    onShowCertModal (item = '') {
      this.showEditModal = true
      this.selectedDoctorItem = item
    },
    onClose () {
      this.selectedDoctorItem = {}
      this.showHistoryModal = false
      this.showEditModal = false
    },
    onChange (pageNo) {
      this.pageNo = pageNo
      this.init()
    },
    onSearch () {
      this.pageNo = 1
      this.init()
    },
    onChangeTab (e) {
      this.init()
    },
    onShowHistory (item) {
      this.showHistoryModal = true
      this.selectedDoctorItem = item
    },
    init () {
      this.loading = true
      let req = {
        tagId: this.tagId,
        doctorName: this.searchText,
        status: this.doctorStatus
      }
      this.getTagCoverDoctorList(req).then(res=> {
        this.loading = false
        this.totalCount = res.count
      }, rea => {
        this.loading = false
      })
    },
  },
  mounted () {
    this.activeTab = this.$route.query.state
    this.tagName = this.$route.query.name || ''
    this.$route.meta.title = `${this.tagName}医生分级详情`
    this.tagId = this.$route.params.id
    this.init()
  }
}
</script>
<style lang="scss" scoped>
  .container {
    padding: 0;
  }
  .lay-box {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .t-p {
    padding-top: 15px;
  }
  .l-m {
    margin-left: 20px;
  }
  .picker-rd {
    width: 100%;
  }
  .d-search-box {
    position: absolute;
    right: 5px;
    top: 5px;
    z-index: 1000;
  }
  .d-search-input {
    width: 280px;
    flex-shrink: 0;
    .el-icon-search {
      color: #909399;
      font-size: 20px;
      cursor: pointer;
    }
  }
</style>
