<template>
  <el-dialog
    class="speaker-invite-modal"
    title="邀请医生成为播客"
    width="60%"
    :visible.sync="visible"
    :show-close="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-row
      v-if="visible"
      class="lay-box"
      type="flex"
      justify="space-between"
      align="middle"
    >
      <DoctorSearch
        inputholder="医生姓名"
        :job-title-list="jobTitleList"
        :show-org-list="false"
        :certify-status-list="speakerCertifyStatusList"
        @hospital="searchChange"
      />
    </el-row>
    <div v-loading="loading" class="lay-box">
      <el-table :data="doctorList" stripe :height="400">
        <el-table-column
          prop="name"
          label="姓名"
          width="150"
          fixed
          align="center"
          show-overflow-tooltip
        />
        <el-table-column
          prop="selectedJobTitleId"
          width="150"
          align="center"
          label="所属职称"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.titleName || '/' }}</span>
          </template>
        </el-table-column>

        <el-table-column
          prop="hospital"
          align="center"
          show-overflow-tooltip
          label="医院"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.hospital || '/' }}</span>
          </template>
        </el-table-column>
        <el-table-column width="130" align="center" label="所在科室">
          <template slot-scope="scope">
            <span>{{ scope.row.department || '/' }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="representName"
          align="center"
          width="150"
          label="服务代表"
        >
          <template slot-scope="scope">
            <div v-for="v in scope.row.representItemList" :key="v.id">
              {{ v.name }}
            </div>
          </template>
        </el-table-column>
        <el-table-column width="140" align="center" label="状态">
          <template slot-scope="scope">
            <span>{{ scope.row.speakerStatus | statusFilter }}</span>
          </template>
        </el-table-column>
        <el-table-column
          width="100"
          align="center"
          fixed="right"
          label="操作"
        >
          <template slot-scope="scope">
            <el-link
              v-if="scope.row.speakerStatus === 2 || scope.row.speakerStatus === -1"
              type="primary"
              :underline="false"
              @click="onInvite(scope.row)"
            >
              邀请认证
            </el-link>
            <el-link
              v-else-if="scope.row.speakerStatus === 1 && (scope.row.speakerSignStatus === 0 || scope.row.speakerSignStatus === -1)"
              type="primary"
              :underline="false"
              @click="onOk(scope.row)"
            >
              签约
            </el-link>
            <span v-else-if="scope.row.speakerStatus === 1 && scope.row.speakerSignStatus === 1">已签约</span>
            <span v-else-if="scope.row.speakerStatus === 0">(等待认证)</span>
          </template>
        </el-table-column>
      </el-table>
      <el-row type="flex" justify="end" class="t-p">
        <el-pagination
          background
          hide-on-single-page
          layout="prev, pager, next"
          :page-size="pageSize"
          :current-page="pageNo"
          :total="totalCount"
          @current-change="onChange"
        />
      </el-row>
    </div>
    <template slot="footer">
      <el-row type="flex" justify="center">
        <el-button type="primary" style="width: 140px;" @click="onClose">关闭</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import DoctorSearch from '@/bizComponents/DoctorSearch'
export default {
  name: 'SpeakerInviteModal',
  components: {
    DoctorSearch
  },
  filters: {
    statusFilter(status) {
      switch (status) {
        case 0:
          return '认证中'
        case 1:
          return '认证成功'
        case 2:
          return '认证失败'
        case -1:
          return '未申请认证'
        default:
          return '/'
      }
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedDoctorId: '', // 选择的医生id
      selectedJobTitleId: '',
      selectedJobTitle: '',
      selectedDepartmentId: '',
      selectedSpeakerStatus: '',
      searchText: '',
      pageNo: 1,
      pageSize: 20,
      totalCount: 0,
      loading: false,
      speakerCertifyStatusList: [
        { title: '所有状态', value: '' },
        { title: '认证中', value: 0 },
        { title: '认证成功未签约', value: 3 },
        { title: '认证成功已签约', value: 1 },
        { title: '认证失败', value: 2 },
        { title: '未申请认证', value: -1 }
      ],
      baseInfo: {}
    }
  },
  computed: {
    ...mapGetters(['doctorList', 'jobTitleList', 'hospitalDepartmentList'])
  },
  watch: {
    visible(show) {
      if (show) {
        this.baseInfo = {}
        this.init()
      }
    }
  },
  methods: {
    ...mapActions(['getDoctorList', 'getJobTitleList', 'getHospitalDepartmentList', 'onSignUpDoctor', 'inviteDoctorCertify']),
    searchChange(options) {
      console.log(options, '筛选条件----------------------')
      this.pageNo = 1
      this.baseInfo = options
      this.init()
    },
    onClose() {
      this.baseInfo = {}
      this.$emit('onClose')
    },
    onInvite(item) {
      this.inviteDoctorCertify(item.id).then(() => {
        this.$message.success('邀请认证发送成功')
      }, rea => {
        this.$message.error(rea.message)
      })
    },
    onOk(item) {
      this.onSignUpDoctor(item).then(() => {
        this.$message.success('签约成功')
      }, rea => {
        this.$message.error(rea.message)
      })
    },
    // onSelectJobTitle(v) {
    //   this.selectedJobTitleId = v
    //   if (id === '') {
    //     this.selectedJobTitle = ''
    //   } else {
    //     this.selectedJobTitle = [...this.jobTitleList].filter(v => v.id === id)[0].label
    //   }
    //   this.pageNo = 1
    //   this.init()
    // },
    onSelectDepartment(v) {
      this.pageNo = 1
      this.selectedDepartmentId = v
      this.init()
    },
    onSelectCertify(v) {
      this.pageNo = 1
      this.selectedSpeakerStatus = v
      this.init()
    },
    onJumpDetail() {
      window.open()
    },
    onChange(pageNo) {
      this.pageNo = pageNo
      this.init()
    },
    onSearch() {
      this.pageNo = 1
      this.selectedJobTitleId = ''
      this.selectedJobTitle = ''
      this.selectedSpeakerStatus = ''
      this.selectedDepartmentId = ''
      this.init()
    },
    init() {
      this.loading = true
      // let req = {
      //   pageNo: this.pageNo,
      //   titleName: this.selectedJobTitle,
      //   querySpeaker:true,
      //   searchKey:this.searchText,
      //   speakerStatus:this.selectedSpeakerStatus,
      //   departmentId: this.selectedDepartmentId
      // }
      const data = Object.assign({}, this.baseInfo, {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        querySpeaker: true,
        speakerStatus: this.baseInfo.certifyStatus,
        certifyStatus: '',
        status: 'all'
      })
      this.getDoctorList(data).then(res => {
        this.loading = false
        this.totalCount = res.count
      }, () => {
        this.loading = false
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.lay-box {
  padding-top: 12px;
  padding-bottom: 12px;
}
.t-p {
  padding-top: 15px;
}
.l-m {
  margin-left: 20px;
}
.picker-rd {
  width: 100%;
}
.search-input {
  width: 280px;
}
</style>
