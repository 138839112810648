<template>
  <div>
    <el-dialog
      :title="`${title}`"
      :visible.sync="dialogVisible"
      width="600px"
      :close-on-click-modal="false"
    >
      <div class="content-box">
        <div>
          <div class="base-info-box">
            <div class="item-info w40">
              <span class="label">客户姓名</span>
              <span>：</span>
              <span>{{ detailObj.doctorName }}</span>
            </div>
            <div class="item-info">
              <span class="label">科室</span>
              <span>：</span>
              <span>{{ detailObj.department }}</span>
            </div>
          </div>
          <div class="base-info-box">
            <div class="item-info w40">
              <span class="label">职称</span>
              <span>：</span>
              <span>{{ detailObj.titleName }}</span>
            </div>
            <div class="item-info">
              <span class="label">所在医院</span>
              <span>：</span>
              <span>{{ detailObj.hospital }}</span>
            </div>
          </div>
        </div>
        <div class="check-info-box">
          <div class="item-info">
            <span class="label">产品</span>
            <span>：</span>
            <span>{{ detailObj.productTagName }}</span>
          </div>
          <div class="item-info">
            <span class="label label-width">播客企业等级</span>
            <span>：</span>
            <div>
              <span>{{ detailObj.orgGrade }}</span>
              <span v-if="orgResultText" :class="orgClassName">（{{ orgResultText }}）</span>
            </div>
          </div>
          <div class="item-info">
            <span class="label label-width">播客平台等级</span>
            <span>：</span>
            <div>
              <span>{{ detailObj.platformGrade }}</span>
              <span v-if="platResultText" :class="platClassName">（{{ platResultText }}）</span>
            </div>
          </div>
          <div class="item-info">
            <span class="label">申请人</span>
            <span>：</span>
            <span>{{ detailObj.applyUserName }} ({{ detailObj.orgName }})</span>
          </div>
          <div class="item-info">
            <span class="label">申请时间</span>
            <span>：</span>
            <span>{{ detailObj.submitTime }}</span>
          </div>
          <div class="item-info flex-align-start">
            <span class="label">证明材料</span>
            <span>：</span>
            <template v-for="item of detailObj.evidenceImgs">
              <el-image
                :key="item.normal"
                style="width: 100px; height: 100px; margin-right: 10px;"
                :src="item.normal"
                :preview-src-list="detailObj.evidenceImgs.map((it) => it.normal)"
              />
            </template>
          </div>
        </div>
        <div v-if="detailObj.orgGradeAuditStatus === 0 && !isLook" class="mgtb10">
          <div class="item-info flex-align-start">
            <span class="label">审核结果</span>
            <span>：</span>
            <div>
              <el-select
                v-model="statusObj.status"
                clearable
                placeholder="请选择审核结果"
              >
                <el-option
                  v-for="item in [{id: 1, label: '通过'}, {id: 2, label: '不通过'}]"
                  :key="item.id"
                  :label="item.label"
                  :value="item.id"
                />
              </el-select>
              <el-input
                v-if="statusObj.status === 2"
                v-model="statusObj.failReason"
                class="mgtb10"
                placeholder="请输入审核失败原因"
                type="textarea"
                :rows="3"
              />
            </div>
          </div>
        </div>
      </div>
      <div slot="footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="onConfirm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'DoctorLvCheck',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '播客晋升审核/查看'
    },
    id: {
      type: String,
      default: ''
    },
    isLook: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      statusObj: {
        status: '',
        failReason: ''
      },
      detailObj: {}
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    },
    orgResultText() {
      // 0：审核中，1：审核通过，2：审核失败，3：已取消申请
      if (this.detailObj.orgGradeAuditStatus === 0) return '待审核'
      if (this.detailObj.orgGradeAuditStatus === 1) return '审核通过'
      if (this.detailObj.orgGradeAuditStatus === 2) return `审核失败：${this.detailObj.orgGradeFailReason}`
      if (this.detailObj.orgGradeAuditStatus === 3) return '已取消申请'
      return ''
    },
    platResultText() {
      // 0：审核中，1：审核通过，2：审核失败，3：已取消申请
      if (this.detailObj.platformGradeAuditStatus === 0) return '待审核'
      if (this.detailObj.platformGradeAuditStatus === 1) return '审核通过'
      if (this.detailObj.platformGradeAuditStatus === 2) return `审核失败：${this.detailObj.platformGradeFailReason}`
      if (this.detailObj.platformGradeAuditStatus === 3) return '已取消申请'
      return ''
    },
    orgClassName() {
      if (this.detailObj.orgGradeAuditStatus === 0) return 'waring'
      if (this.detailObj.orgGradeAuditStatus === 1) return 'success'
      if (this.detailObj.orgGradeAuditStatus === 2) return 'danger'
      if (this.detailObj.orgGradeAuditStatus === 3) return 'info'
      return ''
    },
    platClassName() {
      if (this.detailObj.platformGradeAuditStatus === 0) return 'waring'
      if (this.detailObj.platformGradeAuditStatus === 1) return 'success'
      if (this.detailObj.platformGradeAuditStatus === 2) return 'danger'
      if (this.detailObj.platformGradeAuditStatus === 3) return 'info'
      return ''
    }
  },
  watch: {
    visible(val) {
      this.statusObj = {
        status: '',
        failReason: ''
      }
      if (val) {
        this.onGetDetail()
      }
    }
  },
  methods: {
    onGetDetail() {
      this.$axios.get(this.$API.promotionDetail, {params: {id: this.id}}).then(res => {
        const {data = {}} = res
        this.detailObj = data
      }, ear => {
        this.$message.error(ear.message)
      })
    },
    onCancel() {
      this.dialogVisible = false
    },
    onSubmit() {
      if (!this.statusObj.status) {
        return this.$message.error('请选择审核结果')
      }
      // if (this.statusObj.status == 2 && !this.statusObj.failReason) {
      //   return this.$message.error('请填写拒绝原因')
      // }
      this.$axios.post(this.$API.batchProcessGradeSpeaker, [this.id], {params: this.statusObj}).then(() => {
        this.$message.success('操作成功')
        this.onCancel()
        this.$emit('onConfirm')
      }, ear => {
        this.$message.error(ear.message)
      })
    },
    onConfirm() {
      console.log(this.statusObj)
      if (this.detailObj.orgGradeAuditStatus !== 0 || this.isLook) {
        this.onCancel()
      } else {
        this.onSubmit()
      }

    }
  }
}
</script>

<style lang="scss" scoped>
.content-box {
  color: #0f0f0f;
}
.label {
  color: #5a5a5a;
  width: 60px;
  text-align: justify;
  text-align-last: justify;
  flex-shrink: 0;
}
.label-width {
  width: 85px;
}
.base-info-box {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: baseline;
}
.item-info {
  padding: 0 30px 10px 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: baseline;
}
.check-info-box {
  border-top: 1px solid #ebeef5;
  border-bottom: 1px solid #ebeef5;
  padding: 10px 0;
}
.mgtb10 {
  margin: 10px 0;
}
.mgr10 {
  margin-right: 10px;
}
.primary {
  color: #3d61e3;
}
.flex-align-start {
  align-items: flex-start;
}
.w40 {
  width: 40%;
}
.waring {
  color: #ff8500;
}
.success {
  color: #30c691;
}
.danger {
  color: #e8312f;
}
.info {
  color: #5a5a5a;
}
</style>
