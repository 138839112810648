<template>
  <el-dialog
    class="product-list-popup"
    title="选择产品标签"
    :visible.sync="showLevelModal"
    width="600px"
    @open="open"
  >
    <div v-loading="loading" style="max-height: 340px; overflow-y: auto;">
      <el-checkbox-group v-if="dataList.length" v-model="checkedIds" class="group">
        <el-checkbox
          v-for="item in dataList"
          :key="item.productId"
          :disabled="item.isSelect"
          :title="item.productName"
          :label="item.productId"
        >
          {{ item.productName }}
        </el-checkbox>
      </el-checkbox-group>
      <el-empty v-else style="height: 215px;" />
    </div>
    <div slot="footer" class="footer">
      <el-button plain @click="showLevelModal = false">取消</el-button>
      <el-button type="primary" @click="onSubmit">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'ProductListPopup',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    ids: {
      type: Array,
      default: () => ([])
    }
  },
  data() {
    return {
      search: {
        clientGradeId: '',
        tagGroupId: 'e887acc95f1ed1747f3158cd090cbdb6'
      },
      dataList: [],
      checkedIds: [],
      loading: false
    }
  },
  computed: {
    showLevelModal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    onSubmit() {
      if (this.checkedIds.length === 0) {
        return this.$message.error('请选择产品标签')
      }
      const arr = this.dataList.filter(item => this.checkedIds.includes(item.productId))
      this.$emit('submit', arr)
    },
    open() {
      if (this.ids.length) {
        this.checkedIds = this.ids.map(item => item.productId)
      }
      this.getDataList()
    },
    // 获取数据
    async getDataList() {
      try {
        const params = {...this.search}
        this.loading = true
        // TODO: 接口地址需要修改
        const {data} = await this.$axios.get(this.$API.productTagList, { params })
        this.dataList = data
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.product-list-popup {
  ::v-deep .el-dialog__body {
    >div {
      min-height: 100px;
      .group {
        width: 90%;
        margin: 0 auto;
        display: grid;
        gap: 10px;
        grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
        .el-checkbox {
          width: 100%;
          display: flex;
          align-items: center;
          flex-shrink: 0;
          margin-right: 0;
          .el-checkbox__label {
            width: 70%;
            flex-shrink: 0;
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-right: 0;
          }
        }
      }
    }
  }
  ::v-deep .el-dialog__header {
    .el-dialog__title {
      line-height: 10px;
    }
  }
  .footer {
    text-align: center;
    .el-button {
      min-width: 90px;
    }
  }
}
</style>
