<template>
  <el-dialog
    class="customize-el-dialog edit-gating"
    :title="`编辑 ${copyEditForm.doctorName} 分级`"
    :visible.sync="showEditDialog"
    width="450px"
    @open="open"
  >
    <div>
      <el-form
        :model="copyEditForm"
        class="edit-form"
        label-suffix="："
      >
        <div class="base-info">
          <el-form-item label-position="left" label-width="50px" label="姓名">{{ copyEditForm.doctorName }}</el-form-item>
          <el-form-item label-width="50px" label="职称">{{ copyEditForm.doctorTitle }}</el-form-item>
          <el-form-item label-width="50px" label="科室">{{ copyEditForm.doctorDepartment }}</el-form-item>
          <el-form-item label-width="50px" label="医院">{{ copyEditForm.doctorHospitalName }}</el-form-item>
          <el-form-item label="历史评级">
            <span v-if="copyEditForm.historicalGrading">{{ copyEditForm.historicalGrading }}</span>
            <span v-else style="color: #ff4921;">(暂无评级)</span>
          </el-form-item>
        </div>
        <el-divider />
        <div class="form-box">
          <el-form-item label-width="80px" label="潜力">
            <el-radio-group v-model="copyEditForm.potential">
              <el-radio :label="1">低潜力</el-radio>
              <el-radio :label="2">中潜力</el-radio>
              <el-radio :label="3">高潜力</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label-width="80px" label="支持度">
            <el-radio-group v-model="copyEditForm.support">
              <el-radio :label="1">低支持度</el-radio>
              <el-radio :label="2">中支持度</el-radio>
              <el-radio :label="3">高支持度</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label-width="80px" label="观念">
            <el-radio-group v-model="copyEditForm.idea">
              <el-radio :label="1">低观念</el-radio>
              <el-radio :label="2">中观念</el-radio>
              <el-radio :label="3">高观念</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label-width="80px" label="备注原因">
            <el-input
              v-model="copyEditForm.remarks"
              placeholder="请输入备注原因"
              :rows="3"
              :maxlength="300"
              show-word-limit
              type="textarea"
            />
          </el-form-item>
        </div>
      </el-form>
    </div>
    <div slot="footer" class="footer">
      <el-button style="min-width: 90px;" @click="showEditDialog = false">取 消</el-button>
      <el-button style="min-width: 90px;" type="primary" @click="submitEdit">确 认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {cloneDeep} from 'lodash-es'
export default {
  name: 'EditRating',
  props: {
    editForm: {
      type: Object,
      default: () => ({})
    },
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      copyEditForm: {}
    }
  },
  computed: {
    showEditDialog: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  mounted() {

  },
  methods: {
    open(e) {
      this.copyEditForm = cloneDeep(this.editForm)
      this.$emit('open', e)
    },
    // 提交编辑
    async submitEdit() {
      try {
        const params = {
          clientGradeRecordId: this.copyEditForm.clientGradeRecordId,
          potential: this.copyEditForm.potential,
          support: this.copyEditForm.support,
          idea: this.copyEditForm.idea,
          remarks: this.copyEditForm.remarks
        }
        const res = await this.$axios.post(this.$API.editClientGradeRecord, params)
        if (res.data) {
          this.$message({
            type: 'success',
            message: '编辑成功!'
          })
          this.$emit('on-success')
          this.showEditDialog = false
        }
      } catch (error) {
        this.$message.error(error.message)
      }
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.edit-gating {
  ::v-deep .edit-form {
    .base-info {
      padding: 5px;
      .el-form-item {
        margin-bottom: 3px;
      }
    }
    .form-box {
      .el-form-item {
        margin-bottom: 10px;
        .el-radio__label {
          font-size: 12px;
          color: #0f0f0f;
          line-height: 18px;
        }
      }
    }
    .el-form-item__label {
      font-size: 12px;
      color: #0f0f0f;
      line-height: 18px;
    }
    .el-form-item__content {
      font-size: 12px;
      color: #0f0f0f;
      line-height: 18px;
    }
    .el-divider--horizontal {
      margin: 10px 0;
    }
  }
}
</style>
