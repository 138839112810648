<template>
  <el-dialog
    title="医师认证信息"
    width="30%"
    class="customize-el-dialog"
    :visible.sync="visible"
    :show-close="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    destroy-on-close
  >
    <el-row type="flex" justify="space-between" class="font-weight color-main font-size-large">
      <span>{{ doctorName }}·{{ jobTitle }}</span>
      <span :class="{ 'active-color': certifyStatus !== 'has_certify' }" class="align-right">{{ certifyStatus | certFilter }}</span>
    </el-row>
    <el-row type="flex" justify="space-between" class="desc-text">
      <span>医院科室：</span>
      <span class="flex-box align-right">{{ hospital }}·{{ department }}</span>
    </el-row>
    <el-row type="flex" justify="space-between" class="desc-text">
      <span>手机号：</span>
      <span class="flex-box align-right">{{ mobile | hideMobile }}</span>
    </el-row>
    <el-row slot="footer" type="flex" justify="center">
      <el-button type="primary" @click="onClose">关闭</el-button>
    </el-row>
  </el-dialog>
</template>
<script>
import { mapActions } from 'vuex'

export default {
  name: 'DoctorAuthModal',
  filters: {
    certFilter(status) {
      switch (status) {
        case 'has_certify':
          return '已认证'
        case 'un_certify':
          return '未认证'
        case 'reject_certify':
          return '已驳回'
        case 'wait_update_certify':
          return '待更新'
        default:
          return '审核中'
      }
    },
    hideMobile(mobile) {
      return mobile?.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2')
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    doctorId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      mobile: '',
      department: '',
      doctorName: '',
      representName: '',
      certifyStatus: '',
      hospital: '',
      jobTitle: '',
      loading: false,
      certifyImage: '',
      certifyImageExtra: ''
    }
  },

  watch: {
    visible(show) {
      if (show) {
        this.initData()
        this.init()
      }
    }
  },
  methods: {
    ...mapActions(['getDoctorAuthCert']),
    initData() {
      this.certifyStatus = ''
      this.certifyImage = ''
      this.certifyImageExtra = ''
      this.department = ''
      this.jobTitle = ''
      this.hospital = ''
      this.representName = ''
      this.mobile = ''
      this.doctorName = ''
    },
    init() {
      this.loading = true
      this.getDoctorAuthCert(this.doctorId).then(
        res => {
          this.doctorName = res.doctorName
          this.mobile = res.mobile
          this.representName = res.representNames
          this.certifyStatus = res.certifyStatus
          this.certifyImage = res.certifyImage || require('@/assets/common/doctor_auth_empty.png')
          this.certifyImageExtra = res.certifyImageExtra || ''
          this.department = res.department
          this.jobTitle = res.jobTitle
          this.hospital = res.hospital
          this.loading = false
        },
        rea => {
          this.loading = false
          this.$message.error(rea.message)
        }
      )
    },
    clear() {},
    onClose() {
      this.$emit('onClose')
    }
  }
}
</script>
<style lang="scss" scoped>
.certify-image {
  height: 160px;
  width: 160px;
  background-color: #f6f7fb;
  margin-top: 10px;
  margin-right: 10px;
  color: #c0c4cc;
  &.active-color {
    color: #d838a4;
  }
}
.desc-text {
  padding-top: 8px;
  padding-bottom: 8px;
  color: #272e40;
}
</style>
