<template>
  <div>
    <el-dialog
      :title="`编辑播客-${title || detailObj.doctorName}`"
      :visible.sync="dialogVisible"
      width="60%"
      :close-on-click-modal="false"
    >
      <div class="content-box">
        <div>
          <div class="mgtb10 title">基础信息</div>
          <div class="base-info-box">
            <div class="item-info">
              <span class="label">姓名：</span>
              <span>{{ detailObj.doctorName }}</span>
            </div>
            <div class="item-info">
              <span class="label">职称：</span>
              <span>{{ detailObj.titleName }}</span>
            </div>
            <div class="item-info">
              <span class="label">科室：</span>
              <span>{{ detailObj.department }}</span>
            </div>
            <div class="item-info">
              <span class="label">机构：</span>
              <span>{{ detailObj.hospital }}</span>
            </div>
          </div>
        </div>
        <div>
          <div class="mgtb10 title">更新播客级别</div>
          <div>选择产品:</div>
          <div class="check-box">
            <el-radio-group
              v-model="selectTagGrade.productTagId"
              :disabled="isDisabledSelectTag"
              @change="onTagChange"
            >
              <template v-for="item of productList">
                <el-radio :key="item.tagId" :label="item.tagId" class="mgt10 tag-name">{{ item.tagName }}</el-radio>
              </template>
            </el-radio-group>
          </div>
        </div>
        <template v-if="selectTagGrade.productTagId && productMap[selectTagGrade.productTagId]">
          <div class="mgtb10">
            <span>播客企业级别：</span>
            <el-select
              v-model="selectTagGrade.orgGradeId"
              :disabled="detailObj.orgGradeAuditStatus === 0"
              class="mgr10"
              placeholder="请选择播客企业级别"
            >
              <template v-for="it of factoryList">
                <el-option :key="it.id" :label="it.content" :value="it.id" />
              </template>
            </el-select>
            <el-button
              v-if="!isDisabledSelectTag"
              type="text"
              style="color: #e8312f;"
              @click="onClickTagDel"
            >
              删除
            </el-button>
          </div>
        </template>
        <div v-if="isShowPlatformGrade" class="item-info mgt10">
          <span>播客平台级别：</span>
          <el-select
            v-model="platformGradeId"
            :disabled="detailObj.platformGradeAuditStatus === 0"
            class="mgr10"
            clearable
            placeholder="请选择播客平台级别"
          >
            <template v-for="it of platformList">
              <el-option :key="it.id" :label="it.content" :value="it.id" />
            </template>
          </el-select>
        </div>
        <div>
          <div class="mgtb10 title">证明材料：(最多3张)</div>
          <ImageUpload
            desc="上传材料"
            :image-list="evidenceImgs"
            :limit="3"
            @onSuccess="onUploadSuccess"
          />
        </div>
        <div class="label mgtb10">备注：如需提升该播客平台级别，需上传相关证明材料及文件，<el-button plain type="text" @click="onJumpHelp">点击此处</el-button>了解平台播客晋升要求</div>
      </div>
      <div slot="footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="onConfirm">提 交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ImageUpload from '@/bizComponents/ImageUpload'
export default {
  name: 'DoctorLvEdit',
  components: {
    ImageUpload
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    isCreate: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      cacheObj: {
        orgGradeId: '',
        platformGradeId: ''
      },
      isSaving: false,
      detailObj: {},
      selectTagGrade: {
        productTagId: '',
        orgGradeId: ''
      },
      platformGradeId: '',
      isShowPlatformGrade: false, // 是否显示平台级别
      evidenceImgs: [],
      factoryList: [],
      platformList: [],
      productList: []
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    },
    productMap() {
      const obj = {}
      this.productList.forEach(item => {
        obj[item.tagId] = item
      })
      return obj
    },
    isDisabledSelectTag() {
      return !this.isCreate
    }
  },
  watch: {
    visible(val) {
      this.onSetData()
      if (val) {
        this.onGetProductList()
        this.onGetOrgGradeList()
        this.onGetPlatformGradeList()
        if (this.isCreate) {
          this.onGetDoctorInfo()
        } else {
          this.onGetEditInfo()
        }
      }
    }
  },
  methods: {
    onJumpHelp() {
      const url = this.$router.resolve('/help/detail/353174153071432095')
      window.open(url.href, '_blank')
    },
    onSetData() {
      this.detailObj = {doctorId: this.isCreate ? this.id : ''}
      this.selectTagGrade = {
        productTagId: '',
        orgGradeId: ''
      }
      this.platformGradeId = ''
      this.isShowPlatformGrade = false
      this.evidenceImgs = []
    },
    onUploadSuccess(image) {
      this.evidenceImgs = image.map(v => {
        return {
          imageName: v.imageName,
          normal: v.normal,
          uid: v.uid
        }
      })
    },
    onGetProductList() {
      this.$axios.get(this.$API.tagList).then(res => {
        this.productList = res?.data || []
      }, ear => {
        this.$message.error(ear.message)
      })
    },
    onGetOrgGradeList() {
      this.$axios.get(this.$API.orgGradeDicts).then(res => {
        this.factoryList = res?.data || []
      }, ear => {
        this.$message.error(ear.message)
      })

    },
    onGetPlatformGradeList() {
      this.$axios.get(this.$API.platformGradeDicts).then(res => {
        this.platformList = res?.data?.slice(-3) || []
      }, ear => {
        this.$message.error(ear.message)
      })

    },
    onGetDoctorInfo() {
      this.$axios.get(this.$API.doctorBaseInfo, {params: {doctorId: this.id}}).then(res => {
        const {data = {}} = res || {}
        this.detailObj = data
        this.selectTagGrade = {
          productTagId: '',
          orgGradeId: ''
        }
        this.platformGradeId = data.platformGradeId || ''
        this.isShowPlatformGrade = data.isShowPlatformGrade
        this.evidenceImgs = []
        this.cacheObj.orgGradeId = data.productTagAndGradeInfoVO?.orgGradeId
        this.cacheObj.platformGradeId = data.platformGradeId
      }, ear => {
        this.$message.error(ear.message)
      })
    },
    onGetEditInfo() {
      this.$axios.get(this.$API.editSpeakerInfo, {params: {id: this.id}}).then(res => {
        const {data = {}} = res || {}
        this.detailObj = data
        this.selectTagGrade = {
          productTagId: data.productTagAndGradeInfoVO?.productTagId,
          orgGradeId: data.productTagAndGradeInfoVO?.orgGradeId
        }
        this.platformGradeId = data.platformGradeId || ''
        this.isShowPlatformGrade = data.isShowPlatformGrade
        this.cacheObj.orgGradeId = data.productTagAndGradeInfoVO?.orgGradeId
        this.cacheObj.platformGradeId = data.platformGradeId
        this.onUploadSuccess(data.evidenceImgs || [])
      }, ear => {
        this.$message.error(ear.message)
      })
    },
    onClickTagDel() {
      this.selectTagGrade.productTagId = ''
      this.onTagChange()
    },
    onTagChange() {
      this.selectTagGrade.orgGradeId = ''
    },
    onCheckParams() {
      if (this.selectTagGrade.productTagId && !this.selectTagGrade.orgGradeId) {
        this.$message.error('请选择播客企业级别')
        return false
      }
      if (!this.isShowPlatformGrade) {
        if (!this.selectTagGrade.productTagId) {
          this.$message.error('请选择产品标签')
          return false
        }
        if (this.selectTagGrade.orgGradeId && this.cacheObj.orgGradeId === this.selectTagGrade.orgGradeId) {
          this.$message.error('本次提交的企业等级未变化，提交无效')
          return false
        }
      }
      if (this.isShowPlatformGrade) {
        if (!this.platformGradeId && !this.selectTagGrade.orgGradeId) {
          this.$message.error('请选择产品标签或平台等级')
          return false
        }
        if (!this.selectTagGrade.productTagId && this.platformGradeId && this.cacheObj.platformGradeId === this.platformGradeId) {
          this.$message.error('本次提交的平台等级未变化，提交无效')
          return false
        }
        if (this.selectTagGrade.orgGradeId && this.cacheObj.orgGradeId === this.selectTagGrade.orgGradeId && this.platformGradeId && this.cacheObj.platformGradeId === this.platformGradeId) {
          this.$message.error('本次提交的企业等级或平台等级未变化，提交无效')
          return false
        }
      }

      return true
    },
    onSaveEdit() {
      this.isSaving = true
      const data = {
        id: this.isCreate ? '' : this.id,
        doctorId: this.detailObj.doctorId,
        productTagAndGradeInfoBO: this.selectTagGrade,
        platformGradeId: this.platformGradeId,
        evidenceImgs: this.evidenceImgs?.map(item => item.imageName) || []
      }
      this.$axios.post(this.$API.batchSaveOrUpdate, [data]).then(() => {
        this.$message.success('保存成功')
        this.dialogVisible = false
        this.isSaving = false
        this.$emit('onConfirm')
      }, ear => {
        this.$message.error(ear.message)
        this.isSaving = false
      })
    },
    onConfirm() {
      if (!this.onCheckParams() || this.isSaving) return
      this.onSaveEdit()
    }
  }
}
</script>

<style lang="scss" scoped>
.content-box {
  color: #0f0f0f;
  font-size: 12px;
}
.tag-name {
  font-size: 12px;
  ::v-deep .el-radio__label {
    font-size: 12px;
  }
}
.label {
  color: #5a5a5a;
}
.base-info-box {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
}
.item-info {
  padding: 0 30px 10px 0;
}
.check-box {
  margin-top: 10px;
  border: 1px solid #dcdfe6;
  padding: 0 10px 10px;
  max-height: 85px;
  overflow-y: scroll;
}
.mgtb10 {
  margin: 10px 0;
}
.mgt10 {
  margin-top: 10px;
}
.mgr10 {
  margin-right: 10px;
}
.primary {
  color: #3d61e3;
}
.title {
  font-weight: bold;
}
</style>
