import { render, staticRenderFns } from "./DoctorGradeQuestionnaireModal.vue?vue&type=template&id=5917fb3a&scoped=true"
import script from "./DoctorGradeQuestionnaireModal.vue?vue&type=script&lang=js"
export * from "./DoctorGradeQuestionnaireModal.vue?vue&type=script&lang=js"
import style0 from "./DoctorGradeQuestionnaireModal.vue?vue&type=style&index=0&id=5917fb3a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5917fb3a",
  null
  
)

export default component.exports