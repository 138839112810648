<template>
  <div>
    <div v-if="showMask" class="mask" @click.stop="onCloseMask" />
    <el-row
      class="lay-box"
      type="flex"
      justify="space-between"
      align="middle"
    >
      <span class="font-size-large color-main">医院（共{{ total }}所）</span>
      <el-row type="flex" justify="end">
        <el-input v-model.trim="searchText" placeholder="搜索医院名称" class="search-input">
          <el-button
            slot="append"
            size="mini"
            icon="el-icon-search"
            type="primary"
            round
            @click="onSearch"
          />
        </el-input>
        <el-popover
          v-model="showExportPop"
          width="200"
          trigger="manual"
          placement="left"
        >
          <div class="lay-box">是否导出数据?</div>
          <el-row type="flex" justify="end">
            <el-button size="mini" type="text" @click="showExportPop = false">取消</el-button>
            <el-button type="primary" size="mini" @click="onExportConfirm">确定</el-button>
          </el-row>
          <span
            slot="reference"
            plain
            class="text-primary-link l-m dao-data"
            @click="onExport"
          >导出所有数据</span>
        </el-popover>
      </el-row>
    </el-row>
    <div v-loading="loading" class="lay-box">
      <el-table
        :data="hospitalList"
        stripe
      >
        <el-table-column
          prop="name"
          show-overflow-tooltip
          label="医院"
          min-width="240"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="factoryHospitalCode"
          label="编码"
          width="150"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.factoryHospitalCode || '—' }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="creditCode"
          show-overflow-tooltip
          label="机构信用代码"
          min-width="240"
        />
        <el-table-column
          width="150"
          prop="selectedProvinceId"
          align="center"
        >
          <!-- eslint-disable-next-line vue/no-unused-vars -->
          <template slot="header" slot-scope="scope">
            <el-select :value="selectedProvinceId" @change="onSelectProvince">
              <el-option
                v-for="item in provinceList"
                :key="item.provinceId"
                :label="item.provinceName"
                :value="item.provinceId"
              />
            </el-select>
          </template>
          <template slot-scope="scope">
            <span>{{ scope.row.provinceName || '—' }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="cityName"
          align="center"
          width="150"
          label="城市"
        />
        <el-table-column
          prop="gradeName"
          align="center"
          width="150"
        >
          <!-- eslint-disable-next-line vue/no-unused-vars -->
          <template slot="header" slot-scope="scope">
            <el-select :value="selectedHospitalLevel" @change="onSelectHospitalLevel">
              <el-option
                v-for="item in hospitalLevelList"
                :key="item.id"
                :label="item.content"
                :value="item.id"
              />
            </el-select>
          </template>
          <template slot-scope="scope">
            <span>{{ scope.row.gradeName }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="count"
          width="150"
          show-overflow-tooltip
          align="center"
          label="医生人数"
        />
        <el-table-column
          align="center"
          width="150"
          fixed="right"
          label="操作"
        >
          <template slot-scope="scope">
            <el-link type="primary" :underline="false" @click="onJumpDetail(scope.row.id, scope.row.name, scope.row.factoryHospitalCode)">编辑编码</el-link>
          </template>
        </el-table-column>
      </el-table>
      <el-row type="flex" justify="end" class="t-p">
        <el-pagination
          background
          layout="total,sizes, prev, pager, next, jumper"
          :page-size="pageSize"
          :page-sizes="[10, 20, 50, 100]"
          :current-page="pageNo"
          :total="total"
          @current-change="onChange"
          @size-change="handleSizeChange"
        />
      </el-row>
    </div>
    <el-dialog
      title="编辑编码"
      width="30%"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      destroy-on-close
    >
      <div>
        <el-form :model="form">
          <el-form-item label="医院名称：" :label-width="formLabelWidth">
            <el-input v-model="form.hospital" autocomplete="off" :disabled="true" />
          </el-form-item>
          <el-form-item label="医院编码：" :label-width="formLabelWidth">
            <el-input v-model="form.code" autocomplete="off" />
          </el-form-item>
          <div class="remarks" @click="onJumpHospitalName">备注：如您发现该医院名称有误，请<span>点击此处</span>进行反馈。</div>
          <el-divider />
        </el-form>
        <el-row slot="footer" type="flex" justify="center">
          <el-button class="button cancel-button" @click="cancelDialog">取消</el-button>
          <el-button class="button" type="primary" @click="changeHospitalEdit">确认</el-button>
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'DoctorChildHospital',
  data() {
    return {
      selectedProvinceId: '',
      selectedProvince: '',
      searchText: '',
      loading: false,
      selectedHospitalLevel: '',
      dialogFormVisible: false,
      form: {
        hospital: '',
        code: ''
      },
      formLabelWidth: '100px',
      showMask: false,
      showPayModal: false,
      exportMsg: '',
      showExportPop: false,
      hospitalName: '',
      hospitalId: '',
      pageNo: 1,
      pageSize: 20,
      total: 0
    }
  },
  computed: {
    ...mapGetters(['hospitalList', 'provinceList', 'hospitalLevelList'])
  },
  mounted() {
    this.init()
    if (this.hospitalLevelList.length < 2) {
      this.getHospitalLevelList()
    }
    if (this.provinceList.length < 2) {
      this.getProvinceList()
    }
  },
  methods: {
    ...mapActions(['getHospitalList', 'getProvinceList', 'getHospitalLevelList', 'getHospitalDepartmentList', 'updateHospitalEdit']),
    onShowExportModal() {
      this.showMask = true
      this.showExportPop = true
    },
    onCloseMask() {
      this.showMask = false
      this.showPreviewData = {}
      this.showExportPop = false
    },
    onJumpDown() {
      setTimeout(() => {
        this.$router.push('/download/center')
      }, 10)
    },
    onCurrentChange() {

    },
    onExport() {
      this.showExportPop = true
    },
    onExportConfirm() {
      if (!this.hospitalList.length) return this.$message.error('暂无需要导出的数据')
      const params = {
        hospitalName: this.hospitalName,
        provinceId: this.selectedProvinceId,
        grade: this.selectedHospitalLevel
      }
      this.$axios.get(this.$API.getHospitalListDown, {params}).then(res => {
        this.$message.success(res.data)
        this.showExportPop = false
      }, rea => {
        this.$message.error(rea.message)
        this.showExportPop = false
      })
    },
    onSelectProvince(provinceId) {
      this.selectedProvinceId = provinceId
      if (provinceId === '') {
        this.selectedProvince = ''
      } else {
        this.selectedProvince = [...this.provinceList].filter(v => v.provinceId === provinceId)[0].provinceName
      }
      this.init()
    },
    onSelectHospitalLevel(id) {
      this.selectedHospitalLevel = id
      this.init()
    },
    onChange(pageNo) {
      this.pageNo = pageNo
      this.init()
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize
      this.init()
    },
    onJumpDetail(id = '', name = '', factoryHospitalCode = '') {
      this.form.hospital = name
      this.form.code = factoryHospitalCode
      this.hospitalId = id
      this.dialogFormVisible = true
    },
    onJumpHospitalName() {
      const url = this.$router.resolve('/help/feedback')
      window.open(url.href, '_blank')
    },
    cancelDialog() {
      this.dialogFormVisible = false
      this.form.code = ''
    },
    changeHospitalEdit() {
      // if (!this.isPositiveInteger) {
      //   return this.$message.error('医院编码须为正整数')
      // }
      if (!this.form.code) {
        return this.$message.error('请输入医院编码')
      }
      const req = {
        factoryHospitalCode: this.form.code,
        hospitalId: this.hospitalId
      }
      this.updateHospitalEdit(req).then(() => {
        this.dialogFormVisible = false
        this.$message.success('编辑成功')
        this.form.code = ''
        this.init()
      }, rea => {
        this.$message.error(rea)
      })
    },
    onSearch() {
      this.hospitalName = this.searchText
      this.init()
    },
    init() {
      this.loading = true
      const req = {
        hospitalName: this.hospitalName,
        provinceId: this.selectedProvinceId,
        grade: this.selectedHospitalLevel,
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }
      this.getHospitalList(req).then(res => {
        this.loading = false
        this.total = res.data?.totalCount
      }, () => {
        this.loading = false
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.lay-box {
  padding-top: 12px;
  padding-bottom: 12px;
}
.t-p {
  padding-top: 15px;
}
.picker-rd {
  width: 100%;
}
.search-input {
  margin-right: 20px;
  width: 250px;
}
.export {
  margin-right: 100px;
}
.remarks {
  margin-left: 20px;
  color: #909399;
  cursor: pointer;
}
.remarks span {
  color: #3d61e3;
  font-weight: 700;
}
.button {
  width: 140px;
  margin: 10px;
}
.cancel-button {
  border-color: #3d61e3;
  color: #3d61e3;
}
.l-m {
  margin-left: 30px;
}
.dao-data {
  display: block;
  line-height: 28px;
}
</style>
