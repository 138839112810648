<!--
 * @Author: your name
 * @Date: 2020-12-08 12:12:03
 * @LastEditTime: 2020-12-08 12:28:43
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory-vue/src/bizComponents/DoctorOperationHistoryModal.vue
-->
<template>
  <el-dialog
    title='操作历史'
    width="30%"
    :visible.sync="visible"
    :show-close='false'
    :close-on-click-modal='false'
    :close-on-press-escape='false'
    destroy-on-close
  >
    <el-table
      v-loading='loading'
      :data = 'list'
      stripe
      size='small'
      max-height="250"
    >
      <el-table-column
        prop='doctorName'
        align='center'
        label='操作日期'
      >
        <template slot-scope="scope">
          <span>{{scope.row.doctorName || '/'}}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop='doctorName'
        align='center'
        label='操作内容'
      >
        <template slot-scope="scope">
          <span>{{scope.row.doctorName || '/'}}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop='doctorName'
        align='center'
        label='操作人'
      >
        <template slot-scope="scope">
          <span>{{scope.row.doctorName || '/'}}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop='doctorName'
        align='center'
        label='提交代表'
      >
        <template slot-scope="scope">
          <span>{{scope.row.doctorName || '/'}}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop='doctorName'
        align='center'
        label='备注'
      >
        <template slot-scope="scope">
          <el-popover
            v-if='scope.row.remark'
            placement="right"
            width="160"
            trigger='hover'
            >
            <p>{{scope.row.remark}}</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text">关闭</el-button>
            </div>
            <el-link type='primary' :underline='false' slot="reference">详情</el-link>
          </el-popover>
          <span v-else>-</span>
        </template>
      </el-table-column>      
  </el-table>
  </el-dialog>
</template>
<script>
import {mapActions} from  'vuex'
export default {
  name: "DoctorOperationHistoryModal",
  data() {
    return {
      list:[],
      loading:false,
    }
  },
  props: ['visible', 'doctorId'],
  filters: {
    certFilter (status) {
      switch (status) {
        case 'has_certify':
          return '已认证'
        case 'un_certify':
          return '未认证'
        case 'reject_certify':
          return '已驳回'
          default:
          return '审核中'
      }
    }
  },
  methods: {
    ...mapActions(['getTagDoctorHistory']),
    init () {
      this.loading = true
      this.getTagDoctorHistory(this.doctorId).then(res => {
        this.list = res
        this.loading = false
      },rea=> {
        this.loading = false
        this.$message.error(rea.message)
      })
    },
    clear () {
    },
    onClose () {
      this.$emit('onClose')
    }
  },
  watch: {
    visible (show) {
      if (show) {
        this.init()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .certify-image {
    height: 160px;
    width: 160px;
    background-color: #f6f7fb;
    margin-top: 10px;
  }
  .desc-text {
    padding-top: 8px;
    padding-bottom: 8px;
    color: #272e40;
  }
  .active-color {
    color: #d838a4;
  }
</style>