<!--
 * @Author: your name
 * @Date: 2021-01-19 14:29:06
 * @LastEditTime: 2021-02-04 16:43:09
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory-vue/src/views/doctor/DoctorLiveExam.vue
-->
<template>
  <div>
    <page-main v-loading="loading" back :title="`医生管理 - ${hospital}医生详情 - ${doctorName}数据 - 答题详情`">
      <el-row type="flex">
        <el-image :src="liveCover" alt="" class="meeting-image">
          <div slot="placeholder">加载中<span class="dot">...</span></div>
        </el-image>
        <el-row type="flex" class="p-content">
          <el-row type="flex">
            <span class="font-size-large color-main font-weight text-ellipsis" :title="liveTitle">{{ liveTitle }}</span>
          </el-row>
          <el-row class="font-size-large color-info">
            <span>直播时长：{{ duration }}min</span>
            <span class="margin-l">直播时间：{{ starteDate }}</span>
          </el-row>
        </el-row>
      </el-row>
    </page-main>
    <page-main v-loading="loading">
      <div class="font-size-large color-main font-weight">
        <span>{{ doctorName }} {{ hospital }}·{{ department }}·{{ jobTitle }}</span>
        <span class="margin-ln">{{ submitExamDate }}提交</span>
      </div>
      <div class="exam-p">
        <exam-item
          v-for="(item, index) in examList"
          :key="item.examId"
          :index="index + 1"
          :item="item"
        />
      </div>
    </page-main>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import ExamItem from '@/bizComponents/ExamItem'
export default {
  name: 'DoctorLiveExam',
  filters: {},
  components: {
    ExamItem
  },
  data() {
    return {
      loading: false,
      doctorId: '',
      codeId: '',
      money: '',
      liveId: '',
      doctorName: '',
      hospital: '',
      department: '',
      jobTitle: '',
      submitExamDate: '',
      examList: [],
      liveCover: '',
      liveTitle: '',
      starteDate: '',
      duration: ''
    }
  },
  mounted() {
    this.codeId = this.$route.params.id
    this.doctorId = this.$route.query.doctorId
    this.liveId = this.$route.query.liveId
    this.initDoctor()
    this.initExam()
  },
  methods: {
    ...mapActions(['getDoctorAuthCert', 'getDoctorLiveAnswerInfo']),
    initDoctor() {
      this.getDoctorAuthCert(this.doctorId).then(
        res => {
          this.doctorName = res.doctorName
          this.hospital = res.hospital
          this.department = res.department
          this.jobTitle = res.jobTitle
        },
        rea => {
          this.$message.error(rea.message)
        }
      )
    },
    initExam() {
      this.loading = true
      const req = {
        codeId: this.codeId,
        doctorId: this.doctorId,
        liveId: this.liveId
      }
      this.getDoctorLiveAnswerInfo(req).then(
        res => {
          this.loading = false
          this.money = res.money
          this.liveTitle = res.liveTitle
          ;(this.duration = (res.liveDuration / 60).toFixed(1)), (this.starteDate = res.liveTime), (this.liveCover = res.liveCover), (this.examList = res.examAnswerList || [])
          this.submitExamDate = res.answerTime
        },
        rea => {
          this.loading = false
          this.$message.error(rea.message)
        }
      )
    }
  }
}
</script>
<style lang="scss" scoped>
.meeting-image {
  width: 160px;
  height: 90px;
  flex-shrink: 0;
  background-color: #f6f7fb;
}
.p-content {
  padding-left: 35px;
  padding-top: 10px;
  padding-bottom: 10px;
  flex-direction: column;
  justify-content: space-between;
  .text-ellipsis {
    max-width: 450px;
  }
}
.margin-l {
  margin-left: 50px;
}
.margin-ln {
  margin-left: 25px;
}
.exam-p {
  padding-top: 10px;
}
</style>
