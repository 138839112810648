<!--
 * @Author: your name
 * @Date: 2021-01-19 14:28:49
 * @LastEditTime: 2021-03-22 17:06:01
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory-vue/src/views/doctor/DoctorMeetingExam.vue
-->
<template>
  <div>
    <page-main back title v-loading="loading">
      <el-row type="flex">
        <el-image :src="meetingCoverImage" alt="" class="meeting-image">
          <div slot="placeholder">加载中<span class="dot">...</span></div>
        </el-image>
        <el-row type="flex" class="p-content">
          <el-row type="flex">
            <span class="font-size-large color-main font-weight text-ellipsis" :title="meetingTitle">{{ meetingTitle }}</span>
            <span class="font-size-large color-main font-weight margin-l">科会评分：{{ grade }}分</span>
          </el-row>
          <el-row class="font-size-large color-info">
            <span>科会视频时长：{{ duration }}min</span>
            <span class="margin-l">发布时间：{{ publishDate }}</span>
          </el-row>
        </el-row>
      </el-row>
    </page-main>
    <page-main v-loading="loading">
      <div class="font-size-large color-main font-weight">
        <span>{{ doctorName }}（{{ hospital }}·{{ department }}·{{ jobTitle }}）</span>
        <span class="margin-ln">{{ submitExamDate }}提交</span>
      </div>
      <div class="exam-p">
        <exam-item v-for="(item, index) in examList" :key="item.examId" :index="index + 1" :item="item" />
      </div>
    </page-main>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import ExamItem from '@/bizComponents/ExamItem'
export default {
  name: 'DoctorMeetingExam',
  data() {
    return {
      loading: false,
      doctorId: '',
      codeId: '',
      doctorName: '',
      money: '',
      hospital: '',
      department: '',
      jobTitle: '',
      submitExamDate: '',
      examList: [],
      meetingCoverImage: '',
      meetingTitle: '',
      grade: '',
      publishDate: '',
      duration: '',
      isPublish: false,
    }
  },
  computed: {},
  filters: {},
  components: {
    ExamItem,
  },
  methods: {
    ...mapActions(['getDoctorAuthCert', 'getDoctorMeetingAnswerInfo']),
    initDoctor() {
      this.getDoctorAuthCert(this.doctorId).then(
        (res) => {
          this.doctorName = res.doctorName
          this.hospital = res.hospital
          this.department = res.department
          this.$route.meta.title = this.hospital + '医生详情 - ' + this.doctorName + '数据 - 答题详情'
          this.jobTitle = res.jobTitle
        },
        (rea) => {
          this.$message.error(rea.message)
        }
      )
    },
    initExam() {
      this.loading = true
      let req = {
        codeId: this.codeId,
        doctorId: this.doctorId,
      }
      this.getDoctorMeetingAnswerInfo(req).then(
        (res) => {
          const { meetingVo } = res
          this.loading = false
          this.money = meetingVo.money
          this.meetingTitle = meetingVo.title
          ;(this.duration = (meetingVo.duration / 60).toFixed(1)), (this.publishDate = meetingVo.createdAt ? this.$moment(meetingVo.createdAt).format('YYYY-MM-DD') : '/')
          ;(this.grade = parseFloat((meetingVo.grade || 0).toFixed(1))), (this.isPublish = meetingVo.isPublish), (this.meetingCoverImage = meetingVo.coverUrl), (this.examList = meetingVo.examVos)
          this.submitExamDate = meetingVo.completeAt ? this.$moment(meetingVo.completeAt).format('YYYY/MM/DD - HH:mm') : ''
        },
        (rea) => {
          this.loading = false
          this.$message.error(rea.message)
        }
      )
    },
  },
  mounted() {
    console.log(this.$route)
    this.codeId = this.$route.params.id
    this.doctorId = this.$route.query.doctorId
    this.initDoctor()
    this.initExam()
  },
}
</script>
<style lang="scss" scoped>
.page-nav-box {
  width: 100%;
  margin-right: -60px;
  margin-bottom: 10px;
}
.meeting-image {
  width: 160px;
  height: 90px;
  flex-shrink: 0;
  background-color: #f6f7fb;
}
.p-content {
  padding-left: 35px;
  padding-top: 10px;
  padding-bottom: 10px;
  flex-direction: column;
  justify-content: space-between;
  .text-ellipsis {
    max-width: 250px;
  }
}
.margin-l {
  margin-left: 50px;
}
.margin-ln {
  margin-left: 25px;
}
.exam-p {
  padding-top: 10px;
}
</style>
