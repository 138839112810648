<template>
  <div class="grading-list">
    <page-main title back>
      <div class="search">
        <div class="left">
          <el-form inline :model="search">
            <el-form-item>
              <el-select
                v-model="search.provinceName"
                placeholder="所有省"
                class="select"
                clearable
                @change="onSearch"
              >
                <el-option
                  v-for="item in provinceList"
                  :key="item.provinceId"
                  :label="item.provinceName"
                  :value="item.provinceName"
                />
              </el-select>
            </el-form-item>
            <el-form-item v-if="search.provinceName">
              <el-select
                v-model="search.cityName"
                placeholder="所有市"
                class="select"
                clearable
                @change="onSearch"
              >
                <el-option
                  v-for="item in provinceList.find(v => v.provinceName === search.provinceName).cities"
                  :key="item.cityId"
                  :label="item.cityName"
                  :value="item.cityId"
                />
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-select
                v-model="search.doctorHospitalGrade"
                placeholder="机构等级"
                class="select"
                clearable
                @change="onSearch"
              >
                <el-option
                  v-for="item in hospitalLevelList"
                  :key="item.id"
                  :label="item.content"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-select
                v-model="search.doctorTitle"
                placeholder="所有职称"
                class="select"
                clearable
                @change="onSearch"
              >
                <el-option
                  v-for="item in jobTitleList"
                  :key="item.id"
                  :label="item.label"
                  :value="item.label"
                />
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-select
                v-model="search.potential"
                placeholder="潜力筛选"
                class="select"
                clearable
                @change="onSearch"
              >
                <el-option label="低" :value="1" />
                <el-option label="中" :value="2" />
                <el-option label="高" :value="3" />
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-select
                v-model="search.support"
                placeholder="支持度筛选"
                style="width: 100px;"
                clearable
                @change="onSearch"
              >
                <el-option label="低" :value="1" />
                <el-option label="中" :value="2" />
                <el-option label="高" :value="3" />
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-select
                v-model="search.idea"
                placeholder="观念筛选"
                class="select"
                clearable
                @change="onSearch"
              >
                <el-option label="低" :value="1" />
                <el-option label="中" :value="2" />
                <el-option label="高" :value="3" />
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-input
                v-model="search.doctorName"
                v-debounce="[onSearch, 'input']"
                prefix-icon="el-icon-search"
                class="input"
                clearable
                placeholder="搜索医生名称"
                @clear="onSearch"
              />
            </el-form-item>
          </el-form>
        </div>
        <div class="right">
          <template v-if="batchProcessing">
            <el-button type="primary" plain @click="onUpdate(multipleSelection.map(item => item.clientGradeRecordId))">批量更新</el-button>
            <el-button type="danger" plain @click="onDel(multipleSelection.map(item => item.clientGradeRecordId))">批量删除</el-button>
          </template>
          <el-button
            class="create"
            :type="batchProcessing?'info':'primary'"
            plain
            @click="batchProcessing = !batchProcessing"
          >
            {{ batchProcessing?'取消批量操作':'批量操作' }}
          </el-button>
        </div>
      </div>

      <el-table
        ref="multipleTable"
        v-loading="loading"
        :data="tableData"
        style="width: 100%;"
        :row-key="row => row.clientGradeRecordId"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          v-if="batchProcessing"
          width="55"
          :reserve-selection="true"
          type="selection"
        />
        <el-table-column
          prop="doctorId"
          show-overflow-tooltip
          align="center"
          label="医生ID"
        />
        <el-table-column prop="doctorName" align="center" label="姓名" />
        <el-table-column prop="doctorTitle" align="center" label="职称" />
        <el-table-column prop="doctorDepartment" align="center" label="科室" />
        <el-table-column
          prop="doctorHospitalName"
          show-overflow-tooltip
          align="center"
          label="机构名称"
        />
        <el-table-column prop="provinceNameAndCityName" align="center" label="省/市" />
        <el-table-column prop="doctorHospitalGradeName" align="center" label="机构等级" />
        <el-table-column
          prop="department"
          width="135"
          align="center"
          label="历史分级结果"
        />
        <el-table-column prop="potentialScore" align="center" label="潜力值" />
        <el-table-column prop="supportScore" align="center" label="支持度" />
        <el-table-column prop="ideaScore" align="center" label="观念值" />
        <el-table-column
          prop="updatedGrading"
          width="135"
          align="center"
          label="待更新分级结果"
        />
        <el-table-column
          prop="department"
          align="center"
          width="100"
          label="分级时间"
        >
          <template slot-scope="scoped">
            {{ $moment(scoped.row.gradingTime).format('YYYY-MM-DD') }}
          </template>
        </el-table-column>
        <el-table-column
          prop="representName"
          width="100"
          align="center"
          label="分级人"
        />
        <el-table-column
          prop="address"
          width="120"
          label="操作"
          align="center"
        >
          <template slot-scope="scoped">
            <template>
              <el-button type="text" @click="onEdit(scoped.row)">编辑</el-button>
              <el-button v-if="$store.state.user.userInfo.isTopOrg" type="text" @click="onUpdate([scoped.row.clientGradeRecordId])">更新</el-button>
              <el-button
                style="color: #ff8065;"
                :underline="false"
                type="text"
                @click="onDel([scoped.row.clientGradeRecordId])"
              >
                删除
              </el-button>
            </template>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="pagination.pageNo"
        :total="pagination.total"
        :page-sizes="pagination.sizes"
        :page-size="pagination.pageSize"
        :layout="pagination.layout"
        :hide-on-single-page="false"
        class="pagination"
        background
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      />
    </page-main>
    <EditRating v-model="showEditDialog" :edit-form="editForm" @on-success="getDataList" />
  </div>
</template>

<script>
import paginationMixin from '@/mixins/pagination'
import EditRating from './components/EditRating'
export default {
  name: 'GradingList',
  components: {EditRating},
  mixins: [paginationMixin],
  data() {
    return {
      search: {
        doctorHospitalGrade: '',
        doctorTitle: '',
        provinceName: '',
        cityName: '',
        potential: null,
        idea: null,
        support: null,
        clientGradeId: '',
        doctorName: ''
      },
      tableData: [],
      batchProcessing: false,
      multipleSelection: [],
      provinceList: [],
      jobTitleList: [],
      hospitalLevelList: [],
      showEditDialog: false,
      editForm: {
        clientGradeRecordId: '',
        potential: '',
        support: '',
        idea: ''
      }
    }
  },
  mounted() {
    this.search.clientGradeId = this.$route.query.clientGradeId
    this.getProvince()
    this.getJobTitle()
    this.getHospitalLevel()
    this.getDataList()
  },
  methods: {
    // 获取数据
    async getDataList() {
      try {
        const params = this.getParams(this.search)
        this.loading = true
        // TODO: 接口地址需要修改
        const {data: {data, totalCount}} = await this.$axios.post(this.$API.selectClientGradeRecordList, params)
        this.pagination.total = totalCount
        this.tableData = data
        this.multipleSelection.map(row => {
          this.$refs.multipleTable.toggleRowSelection(row, true)
        })
      } catch (error) {
        this.$message.error(error.message)
      } finally {
        this.loading = false
      }
    },
    // 获取省市
    async getProvince() {
      try {
        const res = await this.$axios.get(this.$API.getProvince)
        this.provinceList = res.data
      } catch (error) {
        console.log('error: ', error)
      }
    },
    // 获取职称
    async getJobTitle() {
      try {
        const res = await this.$axios.get(this.$API.getJobTitle)
        this.jobTitleList = res.data
      } catch (error) {
        console.log('error: ', error)
      }
    },
    //
    async getHospitalLevel() {
      try {
        const res = await this.$axios.get(this.$API.getHospitalLevel)
        this.hospitalLevelList = res.data
      } catch (error) {
        console.log('error: ', error)
      }
    },
    onEdit(row) {
      this.editForm = row
      this.showEditDialog = true
    },
    // 更新
    async onUpdate(clientGradeRecordIdS) {
      if (clientGradeRecordIdS.length === 0) {
        this.$message.error('请选择需要更新的数据')
        return
      }

      try {
        await this.$MessageDialog({
          message:
            '<div style="margin: 30px 0px;">请确认是否更新该分级数据，一经更新不可撤回。</div>',
          title: '操作提示',
          closeOnClickModal: true
        })
        const params = {
          clientGradeRecordIdS
        }
        await this.$axios.post(this.$API.updateClientGradeRecordBatch, params.clientGradeRecordIdS)
        this.$message.success('更新成功')
        this.multipleSelection = []
        this.$refs.multipleTable.clearSelection()
        this.getDataList()
        this.$EventBus.$emit('refresh-rating')
      } catch (error) {
        console.log(error)
        if (error.code) {
          this.$message.error(error.message)
        }
      }
    },
    // 删除
    async onDel(clientGradeRecordIds) {
      try {
        await this.$MessageDialog({
          message: '<div style="margin: 20px auto;">请确认是否删除该条分级更新信息申请。</div>',
          title: '操作提示',
          closeOnClickModal: true
        })
        // TODO:接口地址需要修改
        const res = await this.$axios.post(this.$API.deleteClientGradeRecord, clientGradeRecordIds)
        console.log('res: ', res)
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
        this.multipleSelection = []
        this.$refs.multipleTable.clearSelection()
        this.getDataList()
        this.$EventBus.$emit('refresh-rating')
      } catch (error) {
        if (error.code) {
          this.$message.error(error.message)
        }
      }
    },

    onSearch() {
      this.pagination.pageNo = 1
      this.getDataList()
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.grading-list {
  .search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .select {
      width: 90px;
    }
    .input {
      width: 160px;
      ::v-deep .el-input__inner {
        border-radius: 30px;
      }
    }
  }
  .pagination {
    margin-top: 20px;
  }
}
</style>
