<!--
 * @Author: your name
 * @Date: 2020-11-30 21:35:56
 * @LastEditTime: 2020-12-24 10:52:56
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory-vue/src/bizComponents/DoctorGradeQuestionnaire.vue
-->
<template>
  <el-dialog
    title='分级问卷'
    width="50%"
    :visible.sync="visible"
    :show-close='false'
    :close-on-click-modal='false'
    :close-on-press-escape='false'
    destroy-on-close
  >
    <div class="grade-box">
      <div class="font-weight title">关于{{tagName}}产品的分级问卷</div>
      <div>该医生最近一年属于下列哪种情况？(单选)</div>
      <div class="answer-item">A.全年出门诊</div>
      <div class="answer-item">B.全年管理病房</div>
      <div class="answer-item">C.既负责门诊又负责病房</div>
      <div class="answer-item">D.门诊病房轮转</div>
      <div class="tab-box">
        <el-tabs v-model="activeTab">
          <el-tab-pane v-for = 'item in tabList' v-bind:key='item.value' v-bind:label='item.name' v-bind:name='item.value'>
          </el-tab-pane>
          <div class="lay-box" v-if='activeTab === "A"'>
            <div>1.该医生每月在门诊看多少患者？（月门诊患者）</div>
            <div class="d-answer-item">请输入患者人数</div>
            <div>2.该医生每月看的患者中，有多少该产品适应症的患者？（月门诊适应症患者）</div>
            <div class="d-answer-item">请输入患者人数</div>
            <div>3.该医生门诊每月有多少该产品治疗方案的患者？（门诊客户问题）</div>
            <div class="d-answer-item">请输入患者人数</div>
          </div>
          <div class="lay-box" v-if='activeTab === "B"'>
            <div>1.该医生在病房负责多少张床位？（床位数）</div>
            <div class="d-answer-item">请输入床位数</div>
            <div>2.该医生单张病床每月周转几次？（床位周转率）</div>
            <div class="d-answer-item">请输入周转次数</div>
            <div>3.该医生每月管理的患者中，有多少是该产品的适应症患者？（月病房适应症患者）</div>
            <div class="d-answer-item">请输入患者人数</div>
            <div>4.该医生病房每月有多少该产品治疗方案的患者？（病房客户的问题）</div>
            <div class="d-answer-item">请输入患者人数</div>
          </div>
          <div class="lay-box" v-if='activeTab === "C"'>
            <div>1.该医生在病房负责多少张床位？（床位数）</div>
            <div class="d-answer-item">请输入床位数</div>
            <div>2.该医生单张病床每月周转几次？（床位周转率）</div>
            <div class="d-answer-item">请输入周转次数</div>
            <div>3.该医生每月管理的患者中，有多少是该产品的适应症患者？（月病房适应症患者）</div>
            <div class="d-answer-item">请输入患者人数</div>
            <div>4.该医生病房每月有多少该产品治疗方案的患者？（病房客户的问题）</div>
            <div class="d-answer-item">请输入患者人数</div>
            <div>5.该医生每月在门诊看多少患者？（月门诊患者）</div>
            <div class="d-answer-item">请输入患者人数</div>
            <div>6.该医生每月看的患者中，有多少该产品适应症的患者？（月门诊适应症患者）</div>
            <div class="d-answer-item">请输入患者人数</div>
            <div>7.该医生门诊每月有多少该产品治疗方案的患者？（门诊客户问题）</div>
            <div class="d-answer-item">请输入患者人数</div>            
          </div>
          <div class="lay-box" v-if='activeTab === "D"'>
            <div>1.该医生最近一年有多少个月在门诊？（门诊月数）</div>
            <div class="d-answer-item">请输入月份个数</div>
            <div>2.该医生最近一年有多少个月在病房？（病房月数）</div>
            <div class="d-answer-item">请输入月份个数</div>
            <div>3.该医生在病房负责多少张床位？（床位数）</div>
            <div class="d-answer-item">请输入床位数</div>
            <div>4.该医生单张病床每月周转几次？（床位周转率）</div>
            <div class="d-answer-item">请输入周转次数</div>            
            <div>5.该医生每月管理的患者中，有多少是该产品适应症的患者？（月病房适应症患者）</div>
            <div class="d-answer-item">请输入患者人数</div>            
            <div>6.该医生病房每月有多少该产品治疗方案的患者？（病房客户的问题）</div>
            <div class="d-answer-item">请输入患者人数</div>
            <div>7.该医生每月在门诊看多少患者？（月门诊患者）</div>
            <div class="d-answer-item">请输入患者人数</div>
            <div>8.该医生每月看的患者中，有多少该产品适应症的患者？（月门诊适应症患者）</div>
            <div class="d-answer-item">请输入患者人数</div>
            <div>9.该医生门诊每月有多少该产品治疗方案的患者？（门诊客户问题）</div>
            <div class="d-answer-item">请输入患者人数</div>            
          </div>          
        </el-tabs>
      </div>    
    </div>
    <template>
      <el-row type="flex" justify="center">
        <el-button @click="onClose" type="primary" class="r-btn">关闭</el-button>
      </el-row>        
    </template>
  </el-dialog>
</template>
<script>
export default {
  name: "DoctorGradeQuestionnaireModal",
  data() {
    return {
      activeTab: 'A',
      tabList: [{name:'选择A',value:'A'},{name:'选择B',value:'B'},{name:'选择C',value:'C'},{name:'选择D',value:'D'}]
    }	
  },
  props: ['visible', 'tagName'],
  filters: {
  },
  methods: {
    onClose () {
      this.$emit('onClose')
    },
  }
}
</script>
<style lang="scss" scoped>
  .grade-box {
    color: #090909;
    padding-left: 30px;
    padding-right: 30px;
    margin-left: -20px;
    margin-right: -20px;
    max-height: 670px;
    overflow-y: auto;

    .title {
      padding-bottom: 20px;
    }

    .lay-box {
      padding-top: 20px;
      padding-bottom: 20px;
    }

    .answer-item {
      height: 40px;
      line-height: 40px;
      width: 320px;
      background-color: #F7FAFB;
      border-radius: 8px;
      margin-top: 12px;
    }

    .d-answer-item {
      width: 140px;
      height: 46px;
      background-color: #F7F7F7;
      border-radius: 8px;
      line-height: 46px;
      margin-top: 15px;
      margin-bottom: 15px;
      color: #D0D2DB;
      padding-left: 15px;
    }

    .r-btn {
      width: 150px;
    }
  }
</style>