<template>
  <div v-loading="loading" class="doctor-questionnaire-exam">
    <page-main title back>
      <div>
        <div class="title">
          {{ questionnaireDetail.title }}
        </div>
        <div class="info">
          <span>{{ doctorInfo.doctorName }}（{{ doctorInfo.hospital }}·{{ doctorInfo.department }}·{{ doctorInfo.jobTitle }}{{ doctorInfo.department }}）</span>
          <span class="margin-ln">{{ questionnaireDetail.completeAt ? $moment(questionnaireDetail.completeAt).format('YYYY-MM-DD') : '' }}提交</span>
        </div>
        <div style="font-size: 18px;">{{ questionnaireDetail.desc }}</div>
        <div v-if="questionnaireDetail.pdfUrl">文献资料：<el-link :href="questionnaireDetail.pdfUrl" target="_blank">{{ questionnaireDetail.pdfTitle }}.PDF</el-link></div>
      </div>
      <el-divider />
      <div class="exam-p">
        <exam-item
          v-for="(item, index) in questionnaireDetail.questionnaireExamVoList"
          :key="item.examId"
          :index="index + 1"
          :item="item"
          :show-submit-answer="true"
        />
      </div>
    </page-main>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import ExamItem from '@/bizComponents/ExamItem'

import { doctorQuestionnaireDetail } from '@/services/doctorService'
export default {
  name: 'DoctorQuestionnaireExam',
  components: {
    ExamItem
  },
  props: {},
  data() {
    return {
      loading: false,
      questionnaireDetail: {},
      doctorInfo: {},
      doctorName: '',
      doctorId: '',
      itemId: '',
      title: '',
      pdfUrl: '',
      pdfTitle: '',
      showLoading: false,
      hospital: '',
      jobTitle: '',
      department: '',
      desc: '',
      submitExamDate: ''
    }
  },
  mounted() {
    this.codeId = this.$route.params.id
    this.doctorId = this.$route.query.doctorId
    this.initDoctor()
    this.initExam()
  },
  methods: {
    ...mapActions(['getDoctorAuthCert']),
    initDoctor() {
      this.getDoctorAuthCert(this.doctorId).then(
        res => {
          // this.doctorName = res.doctorName
          // this.hospital = res.hospital
          // this.department = res.department
          this.$route.meta.title = `${this.hospital}医生详情 - ${this.doctorName}数据 - 答题详情`
          // this.jobTitle = res.jobTitle
          this.doctorInfo = res
        },
        rea => {
          this.$message.error(rea.message)
        }
      )
    },
    initExam() {
      this.loading = true
      const params = {
        codeId: this.codeId,
        doctorId: this.doctorId
      }
      doctorQuestionnaireDetail(params).then(
        res => {
          this.loading = false
          this.questionnaireDetail = res.data
        },
        rea => {
          this.loading = false
          this.$message.error(rea.message)
        }
      )
    }
  }
}
</script>

<style scoped lang="scss">
.title {
  margin-bottom: 14px;
  font-size: 24px;
}
.info {
  font-size: 18px;
  margin-bottom: 15px;
}
.meeting-image {
  width: 160px;
  height: 90px;
  flex-shrink: 0;
  background-color: #f6f7fb;
}
.p-content {
  padding-left: 35px;
  padding-top: 10px;
  padding-bottom: 10px;
  flex-direction: column;
  justify-content: space-between;
  .text-ellipsis {
    max-width: 250px;
  }
}
.margin-l {
  margin-left: 50px;
}
.margin-ln {
  margin-left: 25px;
}
.exam-p {
  width: 70%;
}
</style>
