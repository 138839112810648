<template>
  <el-dialog
    title="编辑编码"
    width="400px"
    class="customize-el-dialog"
    :visible.sync="dialogFormVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    destroy-on-close
  >
    <div class="content">
      <div><span>医生姓名：</span>{{ form.name || '/' }}</div>
      <div><span>科室：</span>{{ form.department || '/' }}</div>
      <div><span>职称：</span>{{ form.titleName || '/' }}</div>
      <div><span>医院：</span>{{ form.hospital || '/' }}</div>
      <div>
        <span>医生编码：</span>
        <el-input v-model="code" class="code-box" placeholder="请输入医生编码" />
      </div>
      <div class="tips">备注：医生编码是每个厂家特有的客户编码</div>
    </div>
    <el-row slot="footer" type="flex" justify="center">
      <el-button plain @click="cancelDialog">取 消</el-button>
      <el-button type="primary" @click="changeHospitalEdit">确 认</el-button>
    </el-row>
  </el-dialog>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'ChangeDoctorCode',
  props: {
    dialogFormVisible: {
      type: Boolean,
      default: false
    },
    form: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      code: ''
    }
  },

  computed: {
  },
  watch: {
    dialogFormVisible(show) {
      if (show) {
        this.code = this.form.code
      }
    }

  },
  mounted() {
  },
  methods: {
    ...mapActions(['saveDoctorCode']),
    cancelDialog() {
      this.$emit('onClose')
    },
    changeHospitalEdit() {
      const req = {
        doctorId: this.form.id,
        code: this.code
      }
      this.saveDoctorCode(req).then().then(
        () => {
          this.$message.success('编辑成功')
          this.cancelDialog()
        }, rea => {
          this.$message.error(rea.message)
        }
      )
    }
  }
}
</script>

<style scoped>
.code-box {
  width: 200px;
}
.content div {
  padding: 5px;
}
.content div span {
  display: inline-block;
  color: #272e40;
  width: 80px;
}
.tips {
  color: #909399;
  margin-bottom: 30px;
}
</style>
