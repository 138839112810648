<template>
  <el-dialog
    title="提示"
    width="500px"
    class="customize-el-dialog"
    :visible.sync="dialogFormVisible"
    close-on-click-modal
    :close-on-press-escape="false"
    :show-close="false"
    destroy-on-close
  >
    <div class="content">
      <div v-if="batchType === 'unbind'" class="mgn-b30">请确认是否解除该{{ doctorIds.length > 1 ? doctorIds.length+'名' : '' }}医生的{{ repCount }}条代表绑定关系？</div>
      <div v-if="batchType === 'delete'" class="mgn-b30">请确认是否删除该{{ doctorIds.length > 1 ? doctorIds.length+'名' : '' }}绑定医生，有代表绑定医生将进入代表预绑定客户名单，无代表绑定医生将会直接删除。</div>
    </div>
    <el-row slot="footer" type="flex" justify="center">
      <el-button plain @click="cancelDialog">取 消</el-button>
      <el-button type="primary" @click="unBinging">确 认</el-button>
    </el-row>
  </el-dialog>
</template>

<script>

export default {
  name: 'UnbindDoctor',
  props: {
    dialogFormVisible: {
      type: Boolean,
      default: false
    },
    doctorIds: {
      type: Array,
      default: () => {
        return []
      }
    },
    representId: {
      type: String,
      default: ''
    },
    factoryId: {
      type: String,
      default: ''
    },
    batchType: {
      type: String,
      default: 'unbind'
    },
    repCount: {
      type: Number,
      default: 1
    },
    representDoctorStatus: {
      type: [Number, String],
      default: 1
    }
  },
  data() {
    return {
      code: ''
    }
  },

  computed: {
  },
  mounted() {
  },
  methods: {
    cancelDialog() {
      this.$emit('onClose')
    },
    unBinging() {
      if (this.batchType === 'unbind') {
        const data = {
          doctorIds: this.doctorIds,
          representId: this.representId,
          factoryId: this.factoryId
        }
        this.$axios.post(this.$API.doctorBatchUnbind, data).then().then(
          res => {
            console.log(res)
            this.$message.success('解绑成功')
            this.cancelDialog()
            this.$emit('update')
          }, rea => {
            this.$message.error(rea.message)
          }
        )
      } else {
        const data = {
          doctorIds: this.doctorIds,
          representDoctorStatus: this.representDoctorStatus
        }
        this.$axios.post(this.$API.doctorBatchDelete, data).then().then(
          res => {
            console.log(res)
            this.$message.success('删除成功')
            this.cancelDialog()
            this.$emit('update')
          }, rea => {
            this.$message.error(rea.message)
          }
        )
      }
    }
  }
}
</script>

<style scoped>
.content {
  text-align: center;
  padding-top: 30px;
}

</style>
