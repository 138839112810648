<template>
  <div>
    <el-form inline class="customize-search-form search-box">
      <el-form-item>
        <el-input
          v-model="searchParams.doctorName"
          class="input"
          clearable
          placeholder="客户姓名"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="searchParams.mobile"
          class="input"
          clearable
          placeholder="客户手机号"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="searchParams.hospitalName"
          class="input"
          clearable
          placeholder="客户所属机构"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="searchParams.orgOrRepresentName"
          class="input"
          style="width: 150px;"
          clearable
          placeholder="客户所属员工/组织"
        />
      </el-form-item>
      <el-form-item>
        <el-cascader
          v-model="searchParams.professionTitle"
          :options="jobProfessionList"
          :props="{checkStrictly: true ,value:'id', children: 'childrenList'}"
          collapse-tags
          collapse-tags-tooltip
          clearable
          placeholder="请选择职业/职称"
        />
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="searchParams.certifyStatus"
          class="select-small"
          clearable
          placeholder="认证状态"
        >
          <el-option
            v-for="item in certifyStatusList"
            :key="item.value"
            :value="item.value"
            :label="item.title"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-cascader
          v-model="searchParams.provinceCity"
          :options="provinceList"
          :props="{checkStrictly: true}"
          collapse-tags
          collapse-tags-tooltip
          clearable
          placeholder="选择省/市"
        />
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="searchParams.institutionGrade"
          clearable
          class="select-small"
          placeholder="选择机构等级"
        >
          <el-option
            v-for="item in hospitalLevel"
            :key="item.id"
            :value="item.id"
            :label="item.content"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="searchParams.representDoctorStatus"
          clearable
          class="select-small"
          placeholder="绑定状态"
          @clear="searchParams.representDoctorStatus = 1"
        >
          <el-option :value="0" label="暂无绑定" />
          <el-option :value="1" label="有绑定" />
          <el-option :value="2" label="暂未满足绑定条件" />
          <el-option :value="3" label="黑名单" />
          <!-- <el-option :value="4" label="外部导入" /> -->
        </el-select>
      </el-form-item>
      <!-- <el-form-item>
        <el-select
          v-model="searchParams.sourceType"
          clearable
          class="select-small"
          placeholder="客户来源"
        >
          <el-option :value="1" label="趣学术系统" />
          <el-option :value="2" label="数据导入" />
        </el-select>
      </el-form-item> -->
      <el-form-item>
        <el-cascader
          v-model="searchParams.oneTwoLevelDepartmentIds"
          :options="platformDepartmentList"
          :props="{checkStrictly: true, value:'id', children: 'childes'}"
          collapse-tags
          collapse-tags-tooltip
          clearable
          placeholder="请选择一级/二级科室"
        />
      </el-form-item>
      <el-form-item label="录入时间">
        <el-date-picker
          v-model="searchParams.date"
          class="create-date"
          type="daterange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']"
          value-format="yyyy-MM-dd HH:mm:ss"
          clearable
        />
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          style="min-width: 90px; margin-right: 10px;"
          @click="onSearch();resetSelection()"
        >
          查询
        </el-button>
        <el-button
          v-if="$auth('crm.customerMgmt.customer.externalManage')"
          type="primary"
          plain
          style="min-width: 90px; margin-right: 10px;"
          @click="onJumpImportDoctorList"
        >
          外部客户管理
        </el-button>
      </el-form-item>
    </el-form>
    <div class="batch-box">
      <div class="left">
        <span class="total-count"> 共 {{ pagination.total }} 条数据，已选 {{ multipleSelection.length }} 条 </span>
      </div>
      <div class="right">
        <template v-if="searchParams.representDoctorStatus !== 3">
          <el-button
            v-if="searchParams.representDoctorStatus == 1 && $auth('crm.customerMgmt.customer.batchUnbind')"
            plain
            type="info"
            @click="batchOperate('unbind')"
          >
            批量解绑
          </el-button>
          <el-button
            v-if="$auth('crm.customerMgmt.customer.batchDelete')"
            plain
            type="info"
            @click="batchOperate('delete')"
          >
            批量删除
          </el-button>
          <el-button
            v-if="$auth('crm.customerMgmt.customer.batchBind')"
            plain
            type="info"
            @click="batchOperate('bind')"
          >
            批量绑定
          </el-button>
        </template>
        <el-popover
          v-model="showExportPop"
          width="200"
          trigger="manual"
          placement="left"
        >
          <div>是否导出数据?</div>
          <div style="text-align: right; margin: 0;">
            <el-button size="mini" type="text" @click="showExportPop = false">取消</el-button>
            <el-button type="primary" size="mini" @click="onExport">确定</el-button>
          </div>
          <el-button
            v-if="$auth('crm.customerMgmt.customer.exportData')"
            slot="reference"
            :underline="false"
            type="text"
            class="btn-link"
            style="margin-left: 10px;"
            @click="onShowExportModal"
          >
            导出数据
          </el-button>
        </el-popover>
      </div>
    </div>
    <el-table
      ref="multipleTable"
      v-loading="loading"
      :data="doctorList"
      stripe
      :row-key="(row) => row.id"
      class="customize-el-table table"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        v-if="searchParams.representDoctorStatus !== 3"
        :reserve-selection="true"
        type="selection"
        :selectable="(e) => !(e.isInBindBlackList === 1 || e.isCancel === 1 || e.isFrozen === 1)"
        width="50"
      />
      <el-table-column
        prop="name"
        label="姓名"
        fixed="left"
        width="120"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.name || '/' }}</span>
          <span v-if="scope.row.isCancel" style="color: #ff4921; padding-left: 3px;">(已注销)</span>
          <span v-if="scope.row.isFrozen" style="color: #fb9c35; padding-left: 3px;">(已冻结)</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="所属职称">
        <template slot-scope="scope">
          <span>{{ scope.row.titleName || '/' }}</span>
        </template>
      </el-table-column>

      <el-table-column
        prop="hospital"
        align="center"
        show-overflow-tooltip
        label="医院"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.hospital || '/' }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="representName"
        align="center"
        show-overflow-tooltip
        label="服务代表"
      >
        <template slot-scope="scope">
          <div
            v-for="v in scope.row.representItemList"
            :key="v.id"
            @mouseenter="onMouseEnter(v.id, scope.row.id)"
          >
            <el-tooltip placement="right">
              <div slot="content">所在组织：{{ content }}</div>
              <div style="cursor: pointer;">{{ v.name }}</div>
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="selectedDepartmentId" label="所在科室" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.department || '/' }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="titleName"
        align="center"
        show-overflow-tooltip
        label="职业类型"
      >
        <template slot-scope="scope">
          <span>{{ titleNameFormat(scope.row) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="institutionArea"
        align="center"
        show-overflow-tooltip
        label="省市"
      />
      <el-table-column
        prop="mobile"
        align="center"
        show-overflow-tooltip
        label="手机号"
        width="120px"
      >
        <!-- 如果是趣学术医生，则做脱密处理 -->
        <template slot-scope="scope">
          <!-- 手机号脱敏,把手机号，中间几位替换成*号 -->
          <span v-if="scope.row.userSource === 1">{{ scope.row.mobile.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2') || '/' }}</span>
          <span v-else>{{ scope.row.mobile || '/' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="userSource" label="客户来源">
        <template slot-scope="scope">
          {{ scope.row.userSource === 1 ? '趣学术系统' : '数据导入' }}
        </template>
      </el-table-column>
      <el-table-column width="280" label="状态">
        <template slot-scope="scope">
          <el-link
            v-if="scope.row.certifyStatus === 0"
            type="primary"
            :underline="false"
            @click="onShowCertModal(scope.row.id)"
          >
            {{ '未认证' }}
          </el-link>
          <el-link v-else type="primary" :underline="false">
            <img
              :src="certStatusFilter(scope.row.certifyStatus)"
              alt=""
              class="status-img"
              @click="onShowCertModal(scope.row.id)"
            >
            <img :src="realStatusFilter(scope.row.realNameAuthStatus)" alt="" class="status-img">
            <img :src="speakStatusFilter(scope.row.speakerStatus)" alt="" class="status-img">
            <img :src="phoneStatusFilter(scope.row.mobileValidResult)" alt="">
          </el-link>
        </template>
      </el-table-column>
      <el-table-column
        prop="code"
        align="center"
        width="130px"
        label="编码"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.code || '/' }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="registerAt"
        align="center"
        width="160"
        label="录入时间"
      />
      <el-table-column
        prop="lastPurchaserDate"
        align="center"
        width="160"
        label="最近一次分配时间"
      />
      <el-table-column
        v-if="$auth(['crm.customerMgmt.customer.editCode', 'crm.customerMgmt.customer.unbind', 'crm.customerMgmt.customer.viewDetails', 'crm.customerMgmt.customer.addToBlacklist', 'crm.customerMgmt.customer.delToBlacklist'])"
        fixed="right"
        align="center"
        width="80"
        label="操作"
      >
        <template slot-scope="scope">
          <el-dropdown trigger="click" size="medium" @command="onCommand($event, scope.row)">
            <el-button type="text">操作</el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-if="$auth('crm.customerMgmt.customer.editCode')" command="onJumpChangeCode">编辑编码</el-dropdown-item>
              <el-dropdown-item v-if="$auth('crm.customerMgmt.customer.unbind')" :disabled="!(scope.row.representDoctorStatus === 1)" command="onJumpUnbind">解除绑定</el-dropdown-item>
              <el-dropdown-item v-if="$auth('crm.customerMgmt.customer.viewDetails')" command="onJumpDetail">查看详情</el-dropdown-item>
              <el-dropdown-item
                v-show="$auth('crm.customerMgmt.customer.addToBlacklist')"
                v-if="scope.row.isInBindBlackList === 0"
                command="addToBlacklist"
                :disabled="!$store.state.user.userInfo.isTopOrg"
              >
                添加到黑名单
              </el-dropdown-item>
              <el-dropdown-item
                v-else-if="$auth('crm.customerMgmt.customer.delToBlacklist')"
                command="deleteToBlacklist"
                :disabled="!$store.state.user.userInfo.isTopOrg"
              >
                从黑名单中移除
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <div class="table-footer">
      <div class="risk-check">
        <span v-if="$auth('crm.customerMgmt.customer.verifyPhoneNumber')">备注：如需查客户数据，根据客户手机号与平台数据作对比，请先<el-button type="text" class="btn-link" @click="saveAs('https://cdnfile.yaomaitong.net/excel/%E6%95%B0%E6%8D%AE%E6%A0%A1%E9%AA%8C%E6%A8%A1%E6%9D%BF.xlsx', '%E6%95%B0%E6%8D%AE%E6%A0%A1%E9%AA%8C%E6%A8%A1%E6%9D%BF.xlsx')">下载校验模板</el-button>,<el-button class="btn-link" type="text" @click="outerVisible = true">点击此处上传</el-button>。</span>
      </div>
      <el-pagination
        :current-page="pagination.pageNo"
        :total="pagination.total"
        :page-size="pagination.pageSize"
        :page-sizes="pagination.sizes"
        :layout="pagination.layout"
        :hide-on-single-page="false"
        :small="pagination.small"
        class="customize-el-pagination"
        background
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      />
    </div>
    <DoctorAuthModal :visible="showCertModal" :doctor-id="selectedDoctorId" @onClose="onClose" />
    <ChangeDoctorCode :dialog-form-visible="showChangeCode" :form="form" @onClose="onClose" />
    <UnbindDoctor
      :dialog-form-visible="showUnbind"
      :doctor-ids="doctorIds"
      :rep-count="repCount"
      :batch-type="batchType"
      :represent-doctor-status="searchParams.representDoctorStatus"
      @update="onSearch();resetSelection()"
      @onClose="onClose"
    />
    <DoctorDataCheckModel v-model="outerVisible" />
    <ChangeBing
      v-if="showBind"
      :loading="bindLoading"
      :is-show-pop="showBind"
      @onChangBang="onConfirmSelect"
      @onClose="onClose"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { saveAs } from 'file-saver'
import paginationMixin from '@/mixins/pagination'
import DoctorAuthModal from '@/bizComponents/DoctorAuthModal.vue'
import ChangeDoctorCode from '@/bizComponents/ChangeDoctorCode.vue'
// import DoctorSearch from '@/bizComponents/DoctorSearch'
import DoctorDataCheckModel from '@/bizComponents/DoctorDataCheckModel'
import UnbindDoctor from '@/views/doctor/UnbindDoctor.vue'
import ChangeBing from '@/views/orginazation/components/ChangeBing'
// import BatchAddDoctorDialog from './components/BatchAddDoctorDialog.vue'
import {cloneDeep} from 'lodash-es'

export default {
  name: 'DoctorChildList',
  components: {
    DoctorAuthModal,
    // DoctorSearch,
    ChangeDoctorCode,
    UnbindDoctor,
    DoctorDataCheckModel,
    // BatchAddDoctorDialog,
    ChangeBing
  },
  filters: {
    payTipsFilter(record) {
      return `确认支付${record.doctor.name}视频采购费${record.fee}元`
    }
  },
  mixins: [paginationMixin],
  data() {
    return {
      selectedDoctorId: '', // 选择的医生id
      selectedDepartmentId: '',
      showCertModal: false,
      showExportPop: false,
      loading: false,
      showChangeCode: false,
      showUnbind: false,
      form: {},
      content: '',
      outerVisible: false,
      doctorIds: [],
      repCount: 0,
      multipleSelection: [],
      batchType: '',
      showBind: false,
      bindLoading: false,
      searchParams: {
        date: [],
        sourceType: '',
        doctorName: '',
        mobile: '',
        orgOrRepresentName: '',
        hospitalName: '',
        professionType: '',
        titleId: '',
        realNameAuthStatus: '',
        certifyStatus: '',
        speakerStatus: '',
        institutionGrade: '',
        institutionProvinceId: '',
        institutionCityId: '',
        representDoctorStatus: 1,

        professionTitle: [],
        provinceCity: [],
        oneTwoLevelDepartmentIds: []
      },
      doctorList: [],
      jobProfessionList: [],
      hospitalLevel: [],
      provinceList: [],
      editorDoctorInfo: {},
      platformDepartmentList: []
    }
  },
  computed: {
    ...mapGetters(['certifyStatusList', 'userInfo', 'bindStatusList'])
  },
  mounted() {
    this.getDataList()
    if (this.platformDepartmentList.length < 2) {
      this.getPlatformDepartmentList()
    }
    this.getHospitalLevel()
    // 职业类型
    this.getJobProfessionList()

    this.getProvinceList()
  },
  methods: {
    saveAs,
    ...mapActions(['getRepresentOrgList']),
    titleNameFormat(row) {
      return this.jobProfessionList.find(item => item.id === row.professionType)?.label || '/'
    },
    onMouseEnter(representId, doctorId) {
      const name = []
      this.getRepresentOrgList({ representId: representId, doctorId: doctorId }).then(res => {
        res.data.map(v => {
          name.push(v.name)
        })
        this.content = name.toString()
        this.content = this.content.replace(/,/g, '/')
      })
    },
    // 获取平台科室
    getPlatformDepartmentList() {
      this.$axios.get(this.$API.platformDepartment).then(
        res => {
          this.platformDepartmentList = res.data
        },
        rea => {
          this.$message.error(rea.message)
        }
      )
    },
    onExport() {
      if (!this.doctorList.length) return this.$message.error('暂无需要导出的数据')
      const params = this.formatParams({
        pageNo: 1,
        pageSize: 99999999,
        ...this.searchParams
      })
      this.$axios.post(this.$API.uploadDoctorList, params).then(
        res => {
          this.$message.success(res.data)
          this.showExportPop = false
        },
        rea => {
          this.$message.error(rea.message)
          this.showExportPop = false
        }
      )
    },
    onShowExportModal() {
      this.showExportPop = true
    },
    onShowCertModal(id = '') {
      this.showCertModal = true
      this.selectedDoctorId = id
    },
    onClose() {
      this.showCertModal = false
      this.showChangeCode = false
      this.showUnbind = false
      this.showBind = false
    },
    certStatusFilter(status) {
      switch (status) {
        case 0:
          return '/'
        case 1:
          return require('../../assets/doctor/certify_success.png')
        case 3:
          return require('../../assets/doctor/certify_failed.png')
        case 2:
        case 4:
          return require('../../assets/doctor/certify_wait.png')
        default:
          return '/'
      }
    },
    onCommand(val, row) {
      switch (val) {
        case 'onJumpChangeCode':
          this.onJumpChangeCode(row)
          break
        case 'onJumpUnbind':
          this.onJumpUnbind(row)
          break
        case 'onJumpDetail':
          this.$router.push(`/doctor/stat/${row.id}`)
          break
        case 'addToBlacklist':
        case 'deleteToBlacklist':
          this.dealBlacklist(row, val)
          break
        default:
          break
      }
    },
    realStatusFilter(status) {
      switch (status) {
        case 0:
          return '/'
        case 1:
          return require('../../assets/doctor/realname_success.png')
        case 2:
          return require('../../assets/doctor/realname_failed.png')
        case 3:
          return require('../../assets/doctor/realname_wait.png')
        default:
          return '/'
      }
    },
    speakStatusFilter(status) {
      switch (status) {
        case 1:
          return require('../../assets/doctor/speak_success.png')
        case 0:
          return require('../../assets/doctor/speak_wait.png')
        case 2:
          return require('../../assets/doctor/speak_failed.png')
        default:
          return '/'
      }
    },
    phoneStatusFilter(status) {
      switch (status) {
        case 1:
          return require('../../assets/doctor/phone_success.png')
        case 0:
          return require('../../assets/doctor/phone_failed.png')
        default:
          return '/'
      }
    },
    // 处理医生到黑名单
    async dealBlacklist(row, type) {
      try {
        const params = {
          doctorId: row.id
        }
        const url = type === 'addToBlacklist' ? this.$API.addBlackList : this.$API.deleteBlackList
        const res = await this.$axios.get(url, { params })
        if (res.data) {
          const msg = type === 'addToBlacklist' ? '添加' : '移除'
          this.$message.success(`${msg}成功`)
          this.getDataList()
        }
      } catch (error) {
        this.$message.error(error.message)
      }
    },
    onJumpChangeCode(form) {
      this.form = form
      this.showChangeCode = true
    },
    onJumpUnbind(form) {
      this.doctorIds = [form.id]
      this.repCount = form.representItemList.length
      this.batchType = 'unbind'
      this.showUnbind = true
    },
    onSearch() {
      this.pagination.pageNo = 1
      this.getDataList()
    },
    onJumpImportDoctorList() {
      this.$router.push('/import/doctor')
    },
    formatParams(arg) {
      const params = cloneDeep(arg)
      if (params.date) {
        params.date[0] && (params.startAt = params.date[0])
        params.date[1] && (params.endAt = params.date[1])
      }
      delete params.date
      params.professionTitle[0] && (params.professionType = params.professionTitle[0])
      params.professionTitle[1] && (params.titleId = params.professionTitle[1])
      params.provinceCity[0] && (params.institutionProvinceId = params.provinceCity[0])
      params.provinceCity[1] && (params.institutionCityId = params.provinceCity[1])
      params.oneTwoLevelDepartmentIds[0] && (params.oneLevelDepartmentId = params.oneTwoLevelDepartmentIds[0])
      params.oneTwoLevelDepartmentIds[1] && (params.twoLevelDepartmentId = params.oneTwoLevelDepartmentIds[1])

      delete params.professionTitle
      delete params.provinceCity
      delete params.oneTwoLevelDepartmentIds
      return params
    },
    async getDataList() {
      this.loading = true
      const params = this.formatParams(this.getParams(this.searchParams))
      try {
        const res = await this.$axios.post(this.$API.getDoctorListV3, params)
        this.doctorList = res.data || []
        this.multipleSelection.map(row => {
          this.$refs.multipleTable.toggleRowSelection(row, true)
        })
        this.pagination.total = res.count
        this.loading = false
      } catch (error) {
        this.doctorList = []
        this.loading = false
        this.$message.error(error.message)
      }
    },
    // 获取医生职业接口
    getJobProfessionList() {
      this.$axios.get(this.$API.jobTitles).then(
        res => {
          this.jobProfessionList = res.data
        },
        rea => {
          this.$message.error(rea.message)
        }
      )
    },
    // 获取医院等级接口
    getHospitalLevel() {
      this.$axios.get(this.$API.hospitalLevelList).then(
        res => {
          this.hospitalLevel = res.data
        },
        rea => {
          this.$message.error(rea.message)
        }
      )
    },
    // 获取省市区接口
    async getProvinceList() {
      try {
        const {data} = await this.$axios.get(this.$API.getProvince)
        this.provinceList = data?.map(item => {
          item.value = item.provinceId
          item.label = item.provinceName
          if (item.cities.length) {
            item.children = item.cities.map(city => {
              city.value = city.cityId
              city.label = city.cityName
              return city
            })
          }
          return item
        })
      } catch (error) {
        console.log('error: ', error)
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    batchOperate(type) {
      this.batchType = type
      if (!this.multipleSelection.length) {
        return this.$message.error('请选择要操作的医生')
      } else {
        this.doctorIds = []
        this.repCount = 0
        this.multipleSelection.forEach(v => {
          this.doctorIds.push(v.id)
          if (type === 'unbind') {
            this.repCount += v.representItemList.length
          }
        })
        if (type === 'bind') {
          this.showBind = true
          return
        }
        this.showUnbind = true
      }
    },
    onConfirmSelect(list) {
      this.bindLoading = true
      const data = {
        doctorIds: this.doctorIds,
        representId: list.representId,
        factoryId: list.orgId
      }
      this.$axios.post(this.$API.doctorBatchBind, data).then(
        () => {
          this.bindLoading = false
          this.resetSelection()
          this.$message.success('绑定成功')
          this.onClose()
          this.getDataList()
        },
        rea => {
          this.bindLoading = false
          this.$message.error(rea.message)
        }
      )
    },
    resetSelection() {
      this.multipleSelection = []
      this.$refs.multipleTable.clearSelection()
    }
  }
}
</script>
<style lang="scss" scoped>
.search-box {
  .input {
    width: 130px;
    ::v-deep .el-input__inner {
      border-radius: 30px;
      background: #fafafa;
    }
  }
  .select-small {
    width: 140px;
  }
  .create-date {
    background-color: #fbfbfb;
    ::v-deep .el-range-input {
      background-color: #fbfbfb;
    }
  }
  .el-date-editor {
    .el-icon-date {
      order: -10;
    }
  }
}
.batch-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  padding-top: 5px;
  .total-count {
    color: #969696;
  }
}
.status-img {
  margin-right: 5px;
}
.table-footer {
  display: flex;
  align-items: center;
  margin-top: 20px;
  justify-content: space-between;
  flex-wrap: wrap;
  .risk-check {
    color: #969696;
  }
}
::v-deep .btn-link {
  span {
    // 添加下划线
    text-decoration: underline;
  }
}
</style>
