<template>
  <div class="content-box">
    <div class="chart-box-p">
      <transition-group class="chart-box" name="chart-anim" tag="el-row">
        <el-col
          v-for="item in dealChartData"
          :key="item.index"
          :span="8"
          :class="`chart-item ${
            activeRowIndex === item.index ? 'is-active' : ''
          }`"
          :style="{
            backgroundColor: itemBgFilter(item.index),
            color: textColorFilter(item.index),
          }"
        >
          <div class="info-box" @click="changeSelect(item)">
            <span
              v-if="activeRowIndex === item.index"
              class="tag"
            >当前列表显示</span>
            <span class="current">
              <span style="font-size: 14px;">{{ item.count }}</span>
              人</span>
            <div v-show="showCompared" class="compared">
              <span v-if="item.upCount" class="up">
                {{ formatLabel(item) }}<span>↑</span> <span>{{ item.upCount }}</span>
              </span>
              <span v-if="item.downCount" class="down">
                {{ formatLabel(item) }}<span>↓</span>
                <span>{{ item.downCount }}</span>
              </span>
            </div>
          </div>
        </el-col>
      </transition-group>
      <div class="v-line-box">
        <div class="v-line-label-top">{{ options.yAxis.name }}</div>
        <div class="v-line-label">高</div>
        <div class="v-line-label">中</div>
        <div class="v-line-label">低</div>
        <!-- <div>0</div> -->
      </div>
      <div class="w-line-box">
        <div class="w-line-label">低</div>
        <div class="w-line-label">中</div>
        <div class="w-line-label">高</div>
        <div class="w-line-label-top">潜力</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'DoctorGradeChart',
  props: {
    chartData: {
      type: Array,
      default() {
        return []
      }
    },
    options: {
      type: Object,
      default: () => ({})
    },
    showCompared: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      activeRowIndex: null
    }
  },
  computed: {
    dealChartData() {
      return this.chartData.map(item => {
        // 添加潜力支持度
        switch (item.index) {
          case 1:
            // 观念
            item.idea = 3
            // 潜力
            item.potential = 1
            // 支持度
            item.support = 3
            break
          case 2:
            // 观念
            item.idea = 3
            // 潜力
            item.potential = 2
            // 支持度
            item.support = 3
            break
          case 3:
            // 观念
            item.idea = 3
            // 潜力
            item.potential = 3
            // 支持度
            item.support = 3
            break
          case 4:
            // 观念
            item.idea = 2
            // 潜力
            item.potential = 1
            // 支持度
            item.support = 2
            break
          case 5:
            // 观念
            item.idea = 2
            // 潜力
            item.potential = 2
            // 支持度
            item.support = 2
            break
          case 6:
            // 观念
            item.idea = 2
            // 潜力
            item.potential = 3
            // 支持度
            item.support = 2
            break
          case 7:
            // 观念
            item.idea = 1
            // 潜力
            item.potential = 1
            // 支持度
            item.support = 1
            break
          case 8:
            // 观念
            item.idea = 1
            // 潜力
            item.potential = 2
            // 支持度
            item.support = 1
            break
          case 9:
            // 观念
            item.idea = 1
            // 潜力
            item.potential = 3
            // 支持度
            item.support = 1
            break
          default:
            break
        }
        return item
      })
    }
  },
  methods: {
    textColorFilter(index) {
      switch (index) {
        case 1:
          return '#3D61E3'
        case 2:
          return '#fff'
        case 3:
          return '#fff'
        case 4:
          return '#3D61E3'
        case 5:
          return '#3D61E3'
        case 6:
          return '#fff'
        case 7:
          return '#3D61E3'
        case 8:
          return '#3D61E3'
        case 9:
          return '#3D61E3'
        default:
          return '#fff'
      }
    },
    itemBgFilter(index) {
      switch (index) {
        case 1:
          return '#C5CFF4'
        case 2:
          return '#8298E8'
        case 3:
          return '#5271DF'
        case 4:
          return '#E7EBFB'
        case 5:
          return '#C5CFF4'
        case 6:
          return '#8298E8'
        case 7:
          return '#FBFBFB'
        case 8:
          return '#E7EBFB'
        case 9:
          return '#C5CFF4'
        default:
          break
      }
    },
    changeSelect(row) {
      if (this.activeRowIndex === row.index) {
        this.activeRowIndex = null
        this.$emit('change', {})
      } else {
        this.activeRowIndex = row.index
        this.$emit('change', row)
      }
    },
    formatLabel(row) {
      const { index } = row
      if (index <= 3) {
        // 低潜力
        return `高${this.options.yAxis.name}`
      } else if (index <= 6) {
        // 中潜力
        return `中${this.options.yAxis.name}`
      } else {
        // 高潜力
        return `低${this.options.yAxis.name}`
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.chart-box,
.chart-box-p,
.content-box {
  height: 100%;
  width: 100%;
  position: relative;
}
.content-box {
  padding-left: 20px;
}
.chart-box-p {
  padding: 20px;
}
.chart-anim-enter-active,
.chart-anim-leave-active {
  transition: all 1s;
}
.chart-anim-enter,
.chart-anim-leave-to {
  opacity: 0;
  transform: scale(0);
}
.chart-item {
  height: 33.3333%;
  cursor: pointer;
  padding: 6px;
  position: relative;
  &.is-active {
    box-shadow: 0 0 2px 4px #274dd7;
    border-radius: 3px;
    z-index: 1;
    .tag {
      background-color: #274dd7;
      color: #fff;
      position: absolute;
      top: 0;
      left: 0;
      padding: 0 4px;
      line-height: 20px;
      border-bottom-right-radius: 4px;
    }
  }
  .info-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 25px;
    padding-right: 15px;
    height: 100%;
    .current {
      font-size: 12px;
    }
    .compared {
      font-size: 14px;
      display: flex;
      flex-direction: column;
      line-height: 18px;
      >span {
        display: flex;
        align-items: center;
        gap: 3px;
        font-size: 14px;
      }
      .up {
        color: #30c691;
      }
      .down {
        color: #ff4921;
      }
    }
  }
  .cover-t {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    z-index: 999;
    transition: all 100ms;
    &:hover {
      opacity: 1;
    }
  }
}
.v-line-box {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  color: #7784a1;
  text-align: right;
  white-space: nowrap;
  padding-top: 20px;
  padding-bottom: 20px;
  .v-line-label-top {
    position: absolute;
    top: 20px;
    right: 0;
  }
  .v-line-label {
    height: 33.333%;
    display: flex;
    align-items: center;
  }
}
.w-line-box {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  color: #7784a1;
  text-align: right;
  white-space: nowrap;
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  .w-line-label-top {
    position: absolute;
    left: calc(100% - 40px);
  }
  .w-line-label {
    width: 33.333%;
    display: flex;
    justify-content: center;
  }
}
</style>
