<template>
  <div class="rating-edit-or-check">
    <page-main title back class="rating-info">
      <el-form
        ref="baseForm"
        :rules="ratingFormRules"
        label-width="120px"
        label-suffix="："
        :model="ratingForm"
        :disabled="disabledFun()"
        style="margin-bottom: 0;"
        @submit.native.prevent
      >
        <el-form-item label="客户分级名称" prop="gradeTitle">
          <el-input
            v-model.trim="ratingForm.gradeTitle"
            placeholder="请输入分级名称"
            :maxlength="20"
            style="width: 392px;"
            show-word-limit
          />
        </el-form-item>
        <el-form-item label="客户分级简介" prop="gradeIntro">
          <el-input
            v-model.trim="ratingForm.gradeIntro"
            placeholder="请输入分级简介，指导代表信息录入"
            :maxlength="300"
            style="width: 392px;"
            :rows="3"
            show-word-limit
            type="textarea"
          />
        </el-form-item>
        <el-form-item label="选择产品" prop="productIdList">
          <div v-if="ratingForm.productIdList.length" class="product-list">
            <el-tag
              v-for="item in ratingForm.productIdList"
              :key="item.productId"
              :closable="$route.query.type === ''"
              @close="removeProduct(item)"
            >
              {{ item.productName }}
            </el-tag>
          </div>
          <el-button
            type="info"
            :disabled="!!$route.query.type"
            plain
            @click="showPropductListPopup = true"
          >
            添加产品 <i class="el-icon-plus" />
          </el-button>
        </el-form-item>
        <el-form-item label="选择问卷" prop="questionnaireId">
          <div v-if="ratingForm.questionnaireId && !questionnaireLoading" class="questionnaire">
            <span class="name">{{ ratingForm.questionnaireTitle }}</span> <span class="wire" /> <svg-icon v-if="$route.query.type !== 'check'" name="del" @click=";(ratingForm.questionnaireId = ''), (ratingForm.questionnaireTitle = '')" />
          </div>
          <el-button
            :loading="questionnaireLoading"
            type="info"
            plain
            @click="showLevelModal = true"
          >
            <span v-if="!ratingForm.questionnaireId">添加问卷 <svg-icon name="el-icon-plus" /></span>
            <span v-else>更换问卷 <svg-icon name="el-icon-sort" style="transform: rotate(90deg);" /></span>
          </el-button>
        </el-form-item>
        <el-form-item label="使用的分层" prop="levelTypes">
          <el-checkbox-group :value="ratingForm.levelTypes.split(',')" @input="handleSelectedLevelTypes">
            <el-checkbox
              v-for="item in hierarchical"
              :key="item.levelType"
              :label="item.levelType.toString()"
              :disabled="item.levelType === 1"
            >
              {{ item.levelText }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <div class="rule-content">
        <div class="left">
          <el-form
            ref="ruleForm"
            :rules="ratingFormRules"
            label-width="120px"
            label-suffix="："
            :model="ratingForm"
            style="margin-bottom: 0;"
            @submit.native.prevent
          >
            <template v-for="(item, index) in ratingForm.calculateClientGradeList">
              <template v-if="activeLevelType === item.levelType">
                <el-form-item
                  :key="`分级分层-${item.levelType}-${index}`"
                  label="分级分层"
                  :prop="`calculateClientGradeList`"
                  :rules="ratingFormRules['calculateClientGradeList']"
                >
                  <div class="layered">
                    <el-radio-group v-model="activeLevelType" :disabled="false">
                      <el-radio-button
                        v-for="calculateClientGrade in ratingForm.calculateClientGradeList"
                        :key="calculateClientGrade.levelType"
                        :label="calculateClientGrade.levelType"
                        :disabled="false"
                      >
                        {{ calculateClientGrade.levelText }}
                      </el-radio-button>
                    </el-radio-group>
                  </div>
                </el-form-item>
                <el-form-item
                  :key="`${item.levelText}-${item.levelType}-${index}`"
                  :label="item.levelText"
                  :prop="`calculateClientGradeList.${index}.calculationRuleList`"
                  :rules="ratingFormRules['calculateClientGradeList.calculationRuleList']"
                >
                  <draggable
                    v-model="item.calculationRuleList"
                    class="list-group"
                    tag="ul"
                    handle=".darg-icon"
                    v-bind="dragOptions"
                    :disabled="disabledFun()"
                    @start="drag = true"
                    @end="drag = false"
                  >
                    <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                      <li v-for="(calculationRuleItem, calculationRuleIndex) in item.calculationRuleList" :key="calculationRuleItem.type + calculationRuleIndex.toString()" class="calculation list-group-item">
                        <span class="darg-icon">
                          <svg-icon name="el-icon-menu" />
                        </span>
                        <el-form-item
                          v-if="calculationRuleItem.type === 3"
                          :prop="`calculateClientGradeList.${index}.calculationRuleList.${calculationRuleIndex}.operation`"
                          :rules="ratingFormRules['calculateClientGradeList.calculationRuleList.operation']"
                        >
                          <el-select v-model="calculationRuleItem.operation" :disabled="disabledFun()" style="width: 90px;">
                            <el-option label="+ （加）" value="+" />
                            <el-option label="- （减）" value="-" />
                            <el-option label="x （乘）" value="*" />
                            <el-option label="÷ （除）" value="/" />
                            <el-option label="( （左括号）" value="(" />
                            <el-option label=") （右括号）" value=")" />
                          </el-select>
                        </el-form-item>
                        <el-form-item
                          v-if="calculationRuleItem.type === 1"
                          :prop="`calculateClientGradeList.${index}.calculationRuleList.${calculationRuleIndex}.examId`"
                          :rules="ratingFormRules['calculateClientGradeList.calculationRuleList.examId']"
                        >
                          <el-select
                            :key="calculationRuleItem.examId"
                            :disabled="disabledFun()"
                            :value="calculationRuleItem"
                            value-key="examId"
                            style="width: 90px;"
                            @input="changeCalculation($event, index, calculationRuleIndex)"
                          >
                            <el-option
                              v-for="examItem in calculateClientGradeRow.exam"
                              :key="examItem.examId"
                              :label="`第 ${examItem.index} 题：${examItem.title}`"
                              :value="examItem"
                            />
                          </el-select>
                        </el-form-item>
                        <el-form-item
                          v-if="calculationRuleItem.type === 2"
                          :prop="`calculateClientGradeList.${index}.calculationRuleList.${calculationRuleIndex}.valueType`"
                          :rules="ratingFormRules['calculateClientGradeList.calculationRuleList.valueType']"
                        >
                          <el-input v-model.number.trim="calculationRuleItem.valueType" :disabled="disabledFun()" style="width: 90px;" />
                        </el-form-item>
                        <span v-if="$route.query.type !== 'check'" @click="ratingForm.calculateClientGradeList[index].calculationRuleList.splice(calculationRuleIndex, 1)">
                          <svg-icon name="el-icon-close" />
                        </span>
                      </li>
                    </transition-group>
                  </draggable>
                  <div>
                    <el-button type="text" :disabled="disabledFun()" @click="ratingForm.calculateClientGradeList[index].calculationRuleList.push(baseCalculationRule(1))">
                      添加题目
                    </el-button>
                    <el-button type="text" :disabled="disabledFun()" @click="ratingForm.calculateClientGradeList[index].calculationRuleList.push(baseCalculationRule(3))">
                      添加运算符
                    </el-button>
                    <el-button type="text" :disabled="disabledFun()" @click="ratingForm.calculateClientGradeList[index].calculationRuleList.push(baseCalculationRule(2))">
                      添加数字
                    </el-button>
                  </div>
                  <div class="formula">
                    <template v-for="(calculationRuleItem, calculationRuleIndex) in item.calculationRuleList">
                      <span :key="calculationRuleItem.type + calculationRuleIndex.toString()">
                        <span v-if="calculationRuleItem.type === 1" class="exam-tag"> 第{{ findExamIndex(calculationRuleItem.examId) }}题 </span>
                        <span v-if="calculationRuleItem.type === 2">
                          {{ calculationRuleItem.valueType }}
                        </span>
                        <span v-if="calculationRuleItem.type === 3" class="operator">
                          {{ calculationRuleItem.operation }}
                        </span>
                      </span>
                    </template>
                  </div>
                  <p style="color: #f56c6c; font-size: 12px;">{{ validateRule(item.calculationRuleList) }}</p>
                </el-form-item>
                <el-form-item :key="`分层-${item.levelType}-${index}`" label="分层">
                  按照数值区间划分
                  <div class="layer-wrap">
                    <template v-for="(laminationListItem, laminationListIndex) in ratingForm.calculateClientGradeList[index].laminationList">
                      <div v-if="laminationListItem.laminationCode === '1'" :key="index + laminationListIndex">
                        <span>低{{ item.levelText }}</span>
                        <el-form-item :prop="`calculateClientGradeList.${index}.laminationList.${laminationListIndex}.minData`" :rules="ratingFormRules['calculateClientGradeList.laminationList.minData']">
                          <el-input
                            v-model.number.trim="ratingForm.calculateClientGradeList[index].laminationList[laminationListIndex].minData"
                            style="width: 80px;"
                            placeholder="最小值"
                          />
                        </el-form-item>
                        <span>/</span>
                        <el-form-item :prop="`calculateClientGradeList.${index}.laminationList.${laminationListIndex}.maxData`" :rules="ratingFormRules['calculateClientGradeList.laminationList.maxData']">
                          <el-input
                            v-model.number.trim="ratingForm.calculateClientGradeList[index].laminationList[laminationListIndex].maxData"
                            style="width: 80px;"
                            placeholder="最大值"
                            @input="ratingForm.calculateClientGradeList[index].laminationList[laminationListIndex+1].minData = ratingForm.calculateClientGradeList[index].laminationList[laminationListIndex].maxData + 1"
                          />
                        </el-form-item>
                      </div>
                      <div v-if="laminationListItem.laminationCode === '2'" :key="index + laminationListIndex">
                        <span>中{{ item.levelText }}</span>
                        <el-form-item :prop="`calculateClientGradeList.${index}.laminationList.${laminationListIndex}.minData`" :rules="ratingFormRules['calculateClientGradeList.laminationList.minData']">
                          <el-input
                            v-model.number.trim="ratingForm.calculateClientGradeList[index].laminationList[laminationListIndex].minData"
                            disabled
                            style="width: 80px;"
                            placeholder="最小值"
                          />
                        </el-form-item>
                        <span>/</span>
                        <el-form-item :prop="`calculateClientGradeList.${index}.laminationList.${laminationListIndex}.maxData`" :rules="ratingFormRules['calculateClientGradeList.laminationList.maxData']">
                          <el-input
                            v-model.number.trim="ratingForm.calculateClientGradeList[index].laminationList[laminationListIndex].maxData"
                            style="width: 80px;"
                            placeholder="最大值"
                            @input="ratingForm.calculateClientGradeList[index].laminationList[laminationListIndex+1].minData = ratingForm.calculateClientGradeList[index].laminationList[laminationListIndex].maxData + 1"
                          />
                        </el-form-item>
                      </div>
                      <div v-if="laminationListItem.laminationCode === '3'" :key="index + laminationListIndex">
                        <span>高{{ item.levelText }}</span>
                        <el-form-item :prop="`calculateClientGradeList.${index}.laminationList.${laminationListIndex}.minData`" :rules="ratingFormRules['calculateClientGradeList.laminationList.minData']">
                          <el-input
                            v-model.number.trim="ratingForm.calculateClientGradeList[index].laminationList[laminationListIndex].minData"
                            style="width: 80px;"
                            placeholder="最小值"
                            disabled
                          />
                        </el-form-item>
                        <span>/</span>
                        <el-form-item :prop="`calculateClientGradeList.${index}.laminationList.${laminationListIndex}.maxData`" :rules="ratingFormRules['calculateClientGradeList.laminationList.maxData']">
                          <el-input
                            v-model.number.trim="ratingForm.calculateClientGradeList[index].laminationList[laminationListIndex].maxData"
                            style="width: 80px;"
                            placeholder="最大值"
                          />
                        </el-form-item>
                      </div>
                    </template>
                  </div>
                </el-form-item>
              </template>
            </template>
          </el-form>
        </div>

        <div class="right">
          <div class="header">
            <span>问卷评分规则</span>
            <span class="remark">备注：填空题是数字的计算，选择题是得分制。</span>
          </div>
          <el-form
            ref="examForm"
            :rules="ratingFormRules"
            label-width="120px"
            label-suffix="："
            :model="calculateClientGradeRow"
            style="margin-bottom: 0;"
            :disabled="disabledFun()"
            @submit.native.prevent
          >
            <el-table
              stripe
              default-expand-all
              class="table"
              max-height="400px"
              row-key="examId"
              :tree-props="{ children: 'clientGradeAnswerList', hasChildren: 'hasChildren' }"
              :data="calculateClientGradeRow.exam"
            >
              <el-table-column
                label="序号"
                prop="index"
                align="center"
                width="50"
              />
              <el-table-column prop="title" label="题目">
                <template slot-scope="scoped">
                  <span>{{ scoped.row.title | dealTitle }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="examTypeEnum"
                align="center"
                width="150px"
                label="题目类型"
              >
                <template slot-scope="scoped">
                  <span>{{ examTypeEnum().get('examType', scoped.row.examTypeEnum).label }}</span>
                </template>
              </el-table-column>
              <el-table-column label="选项得分" align="center" width="100">
                <template slot-scope="scoped">
                  <template v-if="scoped.row.isAnswer">
                    <el-form-item label-width="0px" :prop="`exam.${scoped.row.parentIndex - 1}.clientGradeAnswerList.${scoped.row.answerIndex}.score`" :rules="ratingFormRules['calculateClientGradeList.exam.score']">
                      <el-input v-model.number.trim="scoped.row.score" placeholder="请输入分数" />
                    </el-form-item>
                  </template>
                </template>
              </el-table-column>
            </el-table>
          </el-form>
        </div>
      </div>
    </page-main>
    <fixed-action-bar v-if="!($route.query.type === 'check')">
      <el-button style="min-width: 90px;" @click="$router.back()">取消</el-button>
      <el-button
        :loading="submitLoading"
        type="primary"
        style="min-width: 90px;"
        @click="onSave"
      >
        保存
      </el-button>
    </fixed-action-bar>

    <!-- 产品标签弹窗 -->
    <ProductListPopup
      v-model="showPropductListPopup"
      :ids="ratingForm.productIdList"
      @submit="
        (e) => {
          ratingForm.productIdList = e
          showPropductListPopup = false
        }
      "
    />
    <!-- 分级问卷弹窗 -->
    <GradingQuestionnairePopup v-model="showLevelModal" :questionnaire-id="ratingForm.questionnaireId" @submit="changeQuestionnaireId" />
  </div>
</template>

<script>
import { cloneDeep } from 'lodash-es'
import GradingQuestionnairePopup from './components/GradingQuestionnairePopup.vue'
import ProductListPopup from './components/ProductListPopup.vue'
import draggable from 'vuedraggable'

export default {
  name: 'RatingEditOrCheck',
  components: {
    GradingQuestionnairePopup,
    ProductListPopup,
    draggable
  },
  filters: {
    dealTitle(title) {
      return title.replace(/<filter><\/filter>/g, '_____')
    }
  },
  data() {
    const validateCalculationRuleList = (rule, value, callback) => {
      value.forEach(item => {
        // 潜力
        if (this.ratingForm.levelTypes.includes(item.levelType.toString()))
          if (this.validateCalculationRule(item)) {
            callback(Error('请完善分级分层信息'))
          }
      })
      callback()
    }
    const validateMaxData = (rule, value, callback) => {
      const fieldIndex = rule.fullField.split('.')
      if (Number(this.ratingForm.calculateClientGradeList[fieldIndex[1]].laminationList[fieldIndex[3]].minData) < Number(value)) {
        callback()
      } else {
        callback(Error('最大值>最小值'))
      }
    }
    return {
      activeLevelType: 1,
      calculateClientGradeRow: {
        exam: []
      },
      ratingForm: {
        clientGradeId: '',
        levelTypes: '1',
        gradeTitle: '',
        gradeIntro: '',
        questionnaireId: '',
        questionnaireTitle: '',
        productIdList: [],
        calculateClientGradeList: []
      },
      hierarchical: [
        {
          levelType: 1,
          levelText: '潜力',
          calculationRuleList: [],
          laminationList: [
            {
              laminationCode: '1',
              minData: null,
              maxData: null
            },
            {
              laminationCode: '2',
              minData: null,
              maxData: null
            },
            {
              laminationCode: '3',
              minData: null,
              maxData: null
            }
          ]
        },
        {
          levelType: 2,
          levelText: '支持度',
          calculationRuleList: [],
          laminationList: [
            {
              laminationCode: '1',
              minData: null,
              maxData: null
            },
            {
              laminationCode: '2',
              minData: null,
              maxData: null
            },
            {
              laminationCode: '3',
              minData: null,
              maxData: null
            }
          ]
        },
        {
          levelType: 3,
          levelText: '观念',
          calculationRuleList: [],
          laminationList: [
            {
              laminationCode: '1',
              minData: null,
              maxData: null
            },
            {
              laminationCode: '2',
              minData: null,
              maxData: null
            },
            {
              laminationCode: '3',
              minData: null,
              maxData: null
            }
          ]
        }
      ],
      ratingFormRules: {
        gradeTitle: [{ required: true, message: '请输入分级名称', trigger: 'blur' }],
        gradeIntro: [{ required: true, message: '请输入分级简介', trigger: 'blur' }],
        productIdList: [{ required: true, type: 'array', message: '请选择产品', trigger: 'change' }],
        questionnaireId: [{ required: true, message: '请选择问卷', trigger: 'blur' }],
        levelTypes: [{ required: true, type: 'string', min: 3, message: '最少选一个分级分层', trigger: 'blur' }],
        calculateClientGradeList: [{ required: true, type: 'array', validator: validateCalculationRuleList, trigger: 'change' }],
        'calculateClientGradeList.calculationRuleList': [{ required: true, type: 'array', message: '请添加规则', trigger: 'change' }],
        'calculateClientGradeList.calculationRuleList.operation': [{ required: true, message: '请选择运算符', trigger: 'change' }],
        'calculateClientGradeList.calculationRuleList.examId': [{ required: true, message: '请选择题目', trigger: 'change' }],
        'calculateClientGradeList.calculationRuleList.valueType': [
          { required: true, message: '请输入数值', trigger: 'blur' },
          { type: 'number', message: '请输入数字', trigger: 'blur' }
        ],
        'calculateClientGradeList.laminationList.minData': [
          { required: true, message: '请输入最小值', trigger: 'change' },
          { type: 'number', message: '请输入数字', trigger: 'blur' }
        ],
        'calculateClientGradeList.laminationList.maxData': [
          { required: true, message: '请输入最大值', trigger: 'change' },
          { type: 'number', message: '请输入数字', trigger: 'blur' },
          {validator: validateMaxData, trigger: 'blur'}
        ]
        // 'calculateClientGradeList.exam.score': [
        //   {required: true,  message: '请输入得分', trigger: 'blur'},
        //   { type: 'number', message: '请输入数字', trigger: 'blur' }
        // ]
      },
      tableData: [],
      showLevelModal: false,
      showPropductListPopup: false,
      questionnaireLoading: false,
      letterArr: [...Array(26).keys()].map(i => String.fromCharCode(i + 65)),
      submitLoading: false,
      dragOptions: {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      },
      drag: false
    }
  },
  mounted() {
    if (this.$route.query.clientGradeId) {
      this.getDetail()
    } else {
      this.updateCalculateClientGradeList()
    }
  },
  methods: {
    async getDetail() {
      const res = await this.$axios.get(this.$API.selectClientGradeDetail, { params: { clientGradeId: this.$route.query.clientGradeId } })
      res.data.productIdList = res.data.productList
      delete res.data.productList
      res.data.calculateClientGradeList[0].exam = this.formatExam(res.data.calculateClientGradeList[0].exam)
      this.calculateClientGradeRow.exam = res.data.calculateClientGradeList[0].exam

      res.data.calculateClientGradeList = res.data.calculateClientGradeList.map(item => {
        item.levelText = this.hierarchical.find(item1 => item1.levelType === item.levelType).levelText
        delete item.exam
        return item
      })
      this.ratingForm = res.data
    },
    async onSave() {
      try {
        const baseComplete = await this.$refs.baseForm.validate()
        if (!baseComplete) {
          return
        }
        const ruleComplete = await this.$refs.ruleForm.validate()
        if (!ruleComplete) {
          return
        }
        const examComplete = await this.$refs.examForm.validate()
        if (!examComplete) {
          return
        }
        this.ratingForm.calculateClientGradeList = this.ratingForm.calculateClientGradeList.map(item => {
          item.exam = this.calculateClientGradeRow.exam
          return item
        })
        const params = cloneDeep(this.ratingForm)
        // 格式化参数
        params.productIdList = params.productIdList.map(item => item.productId)
        params.calculateClientGradeList = params.calculateClientGradeList
          .map(item => {
            if (params.levelTypes.includes(item.levelType.toString())) {
              item.exam = item.exam?.map(questionnaireExam => {
                const obj = {
                  examId: questionnaireExam.examId,
                  clientGradeAnswerList:
                    questionnaireExam.clientGradeAnswerList?.map(clientGradeAnswer => {
                      return {
                        examAnswerId: clientGradeAnswer.examAnswerId,
                        score: clientGradeAnswer.score
                      }
                    }) || []
                }
                return obj
              })
              return item
            }
          })
          .filter(item => item)

        this.submitLoading = true
        if (params.clientGradeId) {
          await this.$MessageDialog({
            message: '<div style="margin: 10px auto;width: 300px;">请确认是否保存该分级的分级规则。一经更新，待更新分级数据将会清除，并重新开始分级。</div>',
            title: '操作提示'
          })
        }
        const { data } = await this.$axios.post(this.$API.saveClientGrade, params)
        if (data) {
          this.$message.success('保存成功')
        }
        this.$EventBus.$emit('refresh-rating')
        this.$router.back()
      } catch (error) {
        if (error?.code) {
          this.$message.error(error.message)
        }
      } finally {
        this.submitLoading = false
      }
    },
    // 整理 exam
    formatExam(examList) {
      const arr = examList
        .filter(item => {
          if (item.examTypeEnum === 'blank_fill' && item.valueType !== 1) {
            // 判断 valueType 是数字类型的填空题时才显示
            return false
          }
          return true
        })
        .map((examItem, examIndex) => {
          examItem.index = examIndex + 1
          examItem.clientGradeAnswerList = examItem.clientGradeAnswerList?.map((clientGradeAnswer, answerIndex) => {
            clientGradeAnswer.examId = `${examItem.examId}${answerIndex}`
            clientGradeAnswer.title = `${this.letterArr[answerIndex]}：${clientGradeAnswer.answerTitle}`
            clientGradeAnswer.parentIndex = examItem.index
            clientGradeAnswer.isAnswer = true
            clientGradeAnswer.answerIndex = answerIndex
            return clientGradeAnswer
          })
          return examItem
        })
      return arr
    },
    // 修改 questionnaireId
    async changeQuestionnaireId(e) {
      this.ratingForm.questionnaireId = e
      this.showLevelModal = false

      if (e) {
        try {
          this.questionnaireLoading = true
          const { data } = await this.$axios.get(this.$API.getQuestionnaireDraftDetail, { params: { questionnaireId: e } })

          this.calculateClientGradeRow.exam = data.questionnaireExamVoList.filter(item => {
            if (item.examTypeEnum === 'blank_fill' && item.valueType !== 1) {
              // 判断 valueType 是数字类型的填空题时才显示
              return false
            }
            return true
          }).map((questionnaireExam, index) => {
            questionnaireExam.index = index + 1
            if (['multiple', 'single'].includes(questionnaireExam.examTypeEnum)) {
              questionnaireExam.clientGradeAnswerList = questionnaireExam.answers.map((answer, answerIndex) => {
                return {
                  answerIndex: answerIndex,
                  title: `${this.letterArr[answerIndex]}：${answer.answer}`,
                  isAnswer: true,
                  examId: answer.examAnswerId,
                  parentIndex: questionnaireExam.index,
                  score: null,
                  ...answer
                }
              })
            }
            return questionnaireExam
          })
          this.updateCalculateClientGradeList()
          this.ratingForm.questionnaireTitle = data.title
        } catch (error) {
          this.ratingForm.questionnaireTitle = ''
        } finally {
          this.questionnaireLoading = false
        }
      } else {
        this.ratingForm.questionnaireTitle = ''
        this.hierarchical = this.$options.data().hierarchical
        this.updateCalculateClientGradeList()
      }
    },
    // 基础规则字段
    baseCalculationRule(type) {
      return { type, examId: null, examType: null, valueType: null, operation: null }
    },
    // 更新规则数据
    updateCalculateClientGradeList() {
      this.ratingForm.calculateClientGradeList = []
      this.hierarchical.forEach(item => {
        if (this.ratingForm.levelTypes.includes(item.levelType.toString())) {
          this.ratingForm.calculateClientGradeList.push(item)
        }
      })
    },
    // 验证规则
    validateCalculationRule(calculationRule) {
      let isUnFinishCalculationRule = false
      if (!calculationRule.calculationRuleList.length) {
        isUnFinishCalculationRule = true
      } else {
        isUnFinishCalculationRule = calculationRule.calculationRuleList.some(calculationRule => {
          let flag = false
          if (calculationRule.type === 3) {
            flag = !calculationRule.operation
          } else if (calculationRule.type === 1) {
            flag = !calculationRule.examId || !calculationRule.examType
          } else if (calculationRule.type === 2) {
            flag = !(typeof calculationRule.valueType === 'number')
          }
          return flag
        })
      }
      // 分层
      const isUnFinishLaminationList = calculationRule.laminationList?.some(lamination => {
        // 判断 maxData、 minDate 是否有空值，可以为 0
        return !(typeof lamination.maxData === 'number') || !(typeof lamination.minData === 'number')
      })
      // 题目
      // let isUnFinishExam = false
      // if (!calculationRule.exam?.length) {
      //   isUnFinishExam = true
      // } else {
      //   isUnFinishExam = calculationRule.exam?.some(exam => {
      //     let flag = false
      //     if (exam?.clientGradeAnswerList?.length) {
      //       flag = exam.clientGradeAnswerList.some(clientGradeAnswer => {
      //         return !(typeof clientGradeAnswer.score === 'number')
      //       })
      //     }
      //     return flag
      //   })
      // }
      return isUnFinishCalculationRule || isUnFinishLaminationList
    },
    changeCalculation(row, index, calculationRuleIndex) {
      this.$set(this.ratingForm.calculateClientGradeList[index].calculationRuleList[calculationRuleIndex], 'examId', row.examId)
      this.$set(this.ratingForm.calculateClientGradeList[index].calculationRuleList[calculationRuleIndex], 'examType', row.examTypeEnum)
    },
    removeProduct(row) {
      this.ratingForm.productIdList = this.ratingForm.productIdList.filter(item => item.productId !== row.productId)
    },
    handleSelectedLevelTypes(e) {
      this.ratingForm.levelTypes = e.join(',')
      if (!e.includes(this.activeLevelType.toString())) {
        this.activeLevelType = 1
      }
      this.updateCalculateClientGradeList()
    },
    examTypeEnum() {
      const base = [
        {
          examType: 'single',
          label: '单选题',
          value: 0
        },
        {
          examType: 'multiple',
          label: '多选题',
          value: 1
        },
        {
          examType: 'blank_fill',
          label: '填空题',
          value: 2
        },
        {
          examType: 'text_fill',
          label: '问答题',
          value: 3
        },
        {
          examType: 'sort',
          label: '排序题',
          value: 5
        },
        {
          examType: 'scale',
          label: '量表题',
          value: 6
        }
      ]
      return {
        get(type, value) {
          return base.find(item => item[type] === value) || {}
        },
        base
      }
    },
    // 查找题目下标
    findExamIndex(id) {
      let index = null
      this.calculateClientGradeRow.exam.forEach(exam => {
        if (exam.examId === id) {
          index = exam.index
        }
      })
      return index
    },
    validateRule(list) {
      // 拼接字符串
      const str = list?.reduce((prev, curr) => {
        if (curr.type === 1) {
          return prev + curr.examId
        } else if (curr.type === 2) {
          return prev + curr.valueType
        } else if (curr.type === 3) {
          return prev + curr.operation
        }
      }, '')
      if (str) {
        switch (this.isValid(str)) {
          case true:
            return ''
          case false:
            return '计算公式可能错误，如果确认无误，请忽略此提示。'
        }
      }
    },
    // 校验是否禁用
    disabledFun() {
      return this.$route.query.type === 'check'
    },
    // 校验计算公式
    isValid(expr) {
      // eslint-disable-next-line no-useless-escape
      const regex = /^(?:\s*\([^()]+\)\s*|\s*[a-zA-Z0-9]+\s*)(?:[\+\-\*/]\s*(?:\s*\([^()]+\)\s*|\s*[a-zA-Z0-9]+\s*))*$/
      return regex.test(expr)
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.rating-edit-or-check {
  .rating-info {
    .el-form {
      margin-bottom: 20px;
      .product-list {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        margin-bottom: 10px;
        margin-top: 5px;
        ::v-deep .el-tag {
          border-radius: 30px;
          border: none;
          font-weight: 500;
          line-height: 20px;
          padding: 0 10px;
          .el-tag__close {
            font-weight: 700;
            transform: scale(1);
          }
        }
      }
      .questionnaire {
        width: 390px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        .name {
          flex-shrink: 0;
        }
        .wire {
          width: 100%;
          border-top: 1px dashed #ebeef5;
        }
        .svg-icon {
          color: #c8c8c8;
          flex-shrink: 0;
          cursor: pointer;
          &:hover {
            color: #b3b3b3;
          }
          &:active {
            color: #999;
          }
        }
      }
    }
    .rule-content {
      border-top: 1px solid #ebeef5;
      display: flex;
      justify-content: space-between;
      .left {
        flex: 1;
        flex-shrink: 0;
        border-right: 1px solid #ebeef5;
        padding-right: 20px;
        padding-top: 20px;
        box-sizing: border-box;
        .layered {
          display: flex;
          justify-content: space-between;
          align-items: center;
          ::v-deep .el-radio-group {
            .el-radio-button {
              margin-right: 10px;
              .el-radio-button__inner {
                border-radius: 1px;
                min-width: 70px;
                border-color: transparent;
              }
              .el-radio-button__orig-radio:not(:checked) + .el-radio-button__inner {
                background-color: #f8f8f8;
              }
            }
          }
        }
        .calculation {
          position: relative;
          margin-right: 10px;
          display: inline-flex;
          .darg-icon {
            color: #d1d1d1;
            margin: 0 3px;
            cursor: move;
          }
          .el-icon-close {
            display: none;
            position: absolute;
            top: -5px;
            right: -6px;
            background-color: #f5f5f5;
            color: #909399;
            border-radius: 50%;
            transform: all 0.3s ease-in-out;
            &:hover {
              background-color: #e6e6e6;
            }
          }
          &:hover {
            .el-icon-close {
              display: block;
            }
          }
        }
        .list-group-item {
          .el-form-item {
            margin-bottom: 0;
          }
        }
        .formula {
          display: flex;
          flex-wrap: wrap;
          gap: 5px;
          color: #adadad;
        }
        .layer-wrap {
          margin-top: 10px;
          min-width: 300px;
          > div {
            display: flex;
            gap: 10px;
            border-top: 1px solid transparent;
            > span {
              flex-shrink: 0;
              color: #5a5a5a;
            }
            ::v-deep .el-input {
              .el-input__inner {
                text-align: center;
                padding: 0 10px;
              }
            }
          }
        }
      }
      .right {
        min-width: 380px;
        flex: 1 0;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 20px;
        box-sizing: border-box;
        .header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;
          .remark {
            color: #979797;
            margin-left: 10px;
          }
        }
        ::v-deep .table {
          width: 100%;
          tbody {
            tr {
              &.el-table__row--level-1 {
                td {
                  padding: 0;
                  .el-form-item {
                    margin-bottom: 12px;
                    margin-top: 12px;
                    .el-form-item__content {
                      line-height: 23px;
                      .el-input {
                        width: 80px;
                        .el-input__inner {
                          border-top: none;
                          border-left: none;
                          border-right: none;
                          border-bottom: none;
                          background-color: transparent;
                          border-radius: 0;
                          height: 20px;
                          padding: 0;
                          text-align: center;
                          &::-webkit-input-placeholder {
                            color: #3d61e3;
                          }
                          &::-moz-placeholder {
                            color: #3d61e3;
                          }
                          &:-ms-input-placeholder {
                            color: #3d61e3;
                          }
                        }
                      }
                      .el-form-item__error {
                        width: 100%;
                        top: calc(100% - 4px);
                        left: 50%;
                        transform: translateX(-50%);
                      }
                    }
                  }
                }
              }
              td:first-child {
                .el-table__expand-icon {
                  display: none;
                }
              }
            }
          }
        }
      }
      // 屏幕小于1000 时上下排列
      @media screen and (max-width: 1000px) {
        flex-direction: column;
      }
    }
  }
}
.flip-list-move {
  transition: transform 0.5s;
}
.ghost {
  opacity: 0.5;
  background: #f1f3f4;
}
</style>
