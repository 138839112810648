<!--
 * @Author: your name
 * @Date: 2020-11-30 20:29:57
 * @LastEditTime: 2020-12-16 17:22:50
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory-vue/src/bizComponents/DoctorLevelGradeModal.vue
-->
<template>
  <el-dialog
    :title='title'
    width="40%"
    :visible.sync="visible"
    :show-close='false'
    :close-on-click-modal='false'
    :close-on-press-escape='false'
    destroy-on-close
  >
    <el-row type='flex' class="color-main">
      <el-col :span='12'>
        <div>客户姓名：{{item.doctorName}}</div>
        <div>职称：{{item.title}}</div>
        <div>科室：{{item.department}}</div>
      </el-col>
      <el-col :span='12'>
        <div>医院：{{item.hospital || '—'}}</div>
        <div>潜力度：{{item.potential}}</div>
        <div>支持度：{{item.support/100}}</div>
      </el-col>
    </el-row>
    <el-row type='flex' class="color-main ep-p">
      <el-col :span='12'>
        <div>客户类型：{{item.doctorName}}</div>
        <div>门诊月数：{{item.outpatientMonth}}</div>
        <div>月门诊患者：{{item.monthOutpatientCount}}</div>
        <div>月门诊适应症患者：{{item.monthOutpatientIndicationPatientCount}}</div>
        <div>月门诊治疗方案患者：{{item.monthOutpatientPrescriptionPatientCount}}</div>
      </el-col>
      <el-col :span='12'>
        <div>病房月数：{{item.wardMonth}}</div>
        <div>月病房适应症患者：{{item.monthWardIndicationPatientCount}}</div>
        <div>月病房治疗方案患者：{{item.monthWardPrescriptionPatientCount}}</div>
        <div>床位数：{{item.bedCount}}</div>
        <div>床位周转率：{{item.bedTurnoverRate}}</div>
      </el-col>
    </el-row>
    <el-row type="flex" align="middle" class="color-main ep-p" v-if='item.status === 0'>
      <el-row class="t-label" type="flex" justify="space-between">
        <span>评</span>
        <span>估：</span>
      </el-row>
      <el-radio v-model="submitType" label="GRADED">
        <el-select v-model="activeKey" :disabled='submitType === "REJECT"'>
          <el-option v-for='v in statusList' :key='v.value' :value='v.value' :label="v.name">{{v.name}}</el-option>
        </el-select>
      </el-radio>
      <el-radio v-model="submitType" label="REJECT">驳回</el-radio>
    </el-row>
    <el-row type="flex" align="middle" class="color-main ep-p" v-if='item.status === 1'>
      <el-row class="t-label" type="flex" justify="space-between">
        <span>评</span>
        <span>估：</span>
      </el-row>
      <el-select v-model="activeKey">
        <el-option v-for='v in statusList' :key='v.value' :value='v.value' :label="v.name">{{v.name}}</el-option>
      </el-select>
    </el-row>    
    <el-row type="flex" class="color-main d-m">
      <el-row class="t-label" type="flex" justify="space-between">
        <span>备</span>
        <span>注：</span>
      </el-row>
      <el-input type="textarea" placeholder="请输入备注信息" v-model="remark" :rows="3" maxlength="30" show-word-limit :disabled='this.item.status === 1'></el-input>
    </el-row>
    <template>
      <el-row type="flex" justify="center">
        <el-button @click="onClose" type="primary" class="btn-ca" plain>关闭</el-button>
        <el-button type="primary" @click="onSubmit" :loading='loading' class="btn-ca">{{btnText}}</el-button>
      </el-row>        
    </template>
  </el-dialog>
</template>
<script>
import {mapActions} from  'vuex'
export default {
  name: "DoctorLevelGradeModal",
  data() {
    return {
      loading: false,
      remark:'',
      submitType: 'GRADED',
      activeKey: '',
      statusList:[{
        name:'高支持高潜力',
        value: 'HighSupportHighPotential',
        doctorLevelType:3
      }, {
        name: '高支持中潜力',
        value: 'HighSupportMediumPotential',
        doctorLevelType:6
      }, {
        name: '高支持低潜力',
        value: 'HighSupportLowPotential',
        doctorLevelType:9
      }, {
        name: '中支持高潜力',
        value: 'MediumSupportHighPotential',
        doctorLevelType:2
      }, {
        name: '中支持中潜力',
        value: 'MediumSupportMediumPotential',
        doctorLevelType:5
      }, {
        name: '中支持低潜力',
        value: 'MediumSupportLowPotential',
        doctorLevelType:8
      }, {
        name: '低支持高潜力',
        value: 'LowSupportHighPotential',
        doctorLevelType:1
      },{
        name: '低支持中潜力',
        value: 'LowSupportMediumPotential',
        doctorLevelType:4
      },{
        name: '低支持低潜力',
        value: 'LowSupportLowPotential',
        doctorLevelType:7
      }]
    }
  },
  props: ['visible', 'item'],
  filters: {
  },
  computed: {
    title () {
      return this.item.status === 1 ?'编辑' :'分级'
    },
    btnText () {
      return this.submitType === 'GRADED' ? '保存' : '驳回'
    }
  },
  watch: {
    visible (show) {
      if (show) {
        if (this.item.status === 1) {
          this.submitType = 'GRADED'
          this.activeKey = this.statusList.filter(v=>v.doctorLevelType === this.item.doctorLevelType)[0].value
        }
      } else {
        this.activeKey = ''
      }
    }
  },
  methods: {
    ...mapActions(['setGradeDoctor']),
    onClose () {
      this.$emit('onClose')
    },
    onSubmit () {
      if (!this.activeKey &&this.submitType === 'GRADED') return this.$message.error('请输入选择潜力分级')
      if (this.loading) return
      let doctorLevelType = ''
      if (this.activeKey) {
        doctorLevelType = this.statusList.filter(v=>v.value === this.activeKey)[0].doctorLevelType
      }
      this.loading = true
      let req = {
        id: this.item.id,
        doctorLevelTypeEnum: this.activeKey,
        doctorLevelType:doctorLevelType,
        levelQuestionnaireStatusEnum: this.submitType,
        onlyUpdate: this.item.status === 1,
        rejectRemark: this.remark
      }
      this.setGradeDoctor(req).then(res=> {
        this.loading = false
        this.activeKey = ''
        this.remark = ''
        if (this.submitType === 'REJECT') {
          this.$message.success('客户分级申请已驳回')
        } else if (this.item.status === 1) {
          this.$message.success('客户分级更新成功')
        } else {
          this.$message.success('客户分级成功')
        }
        this.onClose()
      },rea=> {
        this.loading = false
        this.$message.error(rea.message)
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  .ep-p {
    padding-top: 20px;
  }
  .t-label {
    width: 70px;
    line-height: 40px;
    flex-shrink: 0;
  }
  .d-m {
    padding-top: 10px;
    padding-bottom: 30px;
  }
  .btn-ca {
    width: 120px;
  }
</style>